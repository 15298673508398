import { MutationObserverOptions, useMutation } from 'react-query';

import {
  queryClient,
  repayNonBnbBRToken,
  IRepayNonBnbBRTokenInput,
  RepayBnbOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { BRTokenId } from 'types';
import { useBRTokenContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  RepayBnbOutput,
  Error,
  Omit<IRepayNonBnbBRTokenInput, 'brTokenContract'>
>;

const useRepayNonBnbBRToken = (
  { brTokenId }: { brTokenId: BRTokenId },
  options?: Options,
) => {
  const brTokenContract = useBRTokenContract(brTokenId);

  return useMutation(
    FunctionKey.REPAY_NON_BNB_V_TOKEN,
    params =>
      repayNonBnbBRToken({
        brTokenContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_ASSETS_IN_ACCOUNT);
        queryClient.invalidateQueries(FunctionKey.GET_MARKETS);
        queryClient.invalidateQueries([FunctionKey.GET_V_TOKEN_BORROW_BALANCE, brTokenId]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRepayNonBnbBRToken;
