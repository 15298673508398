import BigNumber from 'bignumber.js';
import { Comptroller } from 'types/contracts';
import { BLOCKS_PER_DAY } from 'constants/bsc';

export interface IGetBrainiacBaiVaultDailyRateWeiInput {
  comptrollerContract: Comptroller;
}

export type GetBrainiacBaiVaultDailyRateWeiOutput = BigNumber;

const getBrainiacBaiVaultDailyRateWei = async ({
  comptrollerContract,
}: IGetBrainiacBaiVaultDailyRateWeiInput): Promise<GetBrainiacBaiVaultDailyRateWeiOutput> => {
  const resp =  0; //await comptrollerContract.methods.brainiacBAIVaultRate().call();

  return new BigNumber(resp).times(BLOCKS_PER_DAY);
};

export default getBrainiacBaiVaultDailyRateWei;
