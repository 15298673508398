import React from 'react';
import ReactDOM from 'react-dom';
import App from 'pages/App';
import BigNumber from 'bignumber.js';

import 'antd/dist/antd.css';
import 'assets/styles/index.scss';
BigNumber.config({
  FORMAT: {
    decimalSeparator: '.',
    groupSize: 3,
    groupSeparator: ',',
  },
});

const rootEl = document.getElementById('root');
rootEl.setAttribute('class', `theme--${process.env.REACT_APP_BRAND_NAME || 'brainiac'}`);

ReactDOM.render(<App />, rootEl);
