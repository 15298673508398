import { getToken } from 'utilities';
import { BrnVault } from 'types/contracts';

export interface IGetBrnVaultPoolsCountInput {
  brnVaultContract: BrnVault;
}

export type GetBrnVaultPoolsCountOutput = number;

const getBrnVaultPoolsCount = async ({
  brnVaultContract,
}: IGetBrnVaultPoolsCountInput): Promise<GetBrnVaultPoolsCountOutput> => {
  const brnTokenAddress = getToken('brn').address;
  const brnVaultPoolLength = await brnVaultContract.methods.poolLength(brnTokenAddress).call();
  return +brnVaultPoolLength;
};

export default getBrnVaultPoolsCount;
