import BigNumber from 'bignumber.js';
import { Comptroller } from 'types/contracts';

export interface IGetUsersNFTInput {
  brainiacNFTContract: any;
  accountAddress: string;
}
export interface INFT {
    tokenID: any;
    tokenURI: string;
}

export type GetUsersNFTOutput = INFT[];

const getUsersNFT = async ({
  brainiacNFTContract,
  accountAddress,
}: IGetUsersNFTInput): Promise<GetUsersNFTOutput> => {
  const balance = await brainiacNFTContract.methods.balanceOf(accountAddress).call();
  let usersNFT =[];
  for(let i = 0; i < balance; i++){
    const tokenID = await brainiacNFTContract.methods.tokenOfOwnerByIndex(accountAddress,i).call();
    const tokenURI = await brainiacNFTContract.methods.tokenURI(tokenID).call();
    const nft = {
        tokenID : tokenID,
        tokenURI : tokenURI.slice(7)
    }

    usersNFT.push(nft);

  }
  return usersNFT;
};

export default getUsersNFT;
