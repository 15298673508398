/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import NavButton from './Button';
import { useTranslation } from 'translation';
import { Toolbar } from '../Toolbar';
import Link from './Link';
import { Icon } from '../../Icon';
import Menu from '@mui/material/Menu';
import { menuItems } from '../constants';
import { useStyles } from './styles';
import { AppBar, Box } from '@mui/material';
import logo from '../../../../assets/homeImages/navbar-logo.png';
import Path from 'constants/path';
import {BiMenuAltRight } from "react-icons/bi"
import {IoMdClose} from 'react-icons/io'
import ConnectButton from '../ConnectButton';



export const NavbarUi: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
      <AppBar position="sticky" css={styles.appBar}>
        <Toolbar css={styles.toolbar}>
            <Link href={Path.ROOT}>
              <img src={logo} alt="logo" css={styles.logo} />
            </Link>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <List css={styles.list}>
              {menuItems.map(menuItem => (
                <ListItemButton
                  key={menuItem.i18nKey}
                  component="li"
                  css={styles.listItem}
                  disableRipple
                >
                  <Link href={menuItem.href}>
                    <ListItemIcon css={styles.listItemIcon}>
                      <Icon name={menuItem.icon} />
                    </ListItemIcon>

                    <Typography variant="body2" css={styles.listItemText}>
                      {t(menuItem.i18nKey)}
                    </Typography>

                    <div className="bottom-border" />
                  </Link>
                </ListItemButton>
              ))}
            </List>
          </Box>
          <ConnectButton/>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              disableRipple
            >
              {Boolean(anchorElNav)? <IoMdClose /> : <BiMenuAltRight />}
          </IconButton>
          <Menu
              css={styles.mobileMenu}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menuItems.map((menuItem) => (
                <ListItemButton
                key={menuItem.i18nKey}
                component="li"
                css={styles.mobileListItem}
                disableRipple
              >
                <Link href={menuItem.href}>
                  <ListItemIcon css={styles.listItemIcon}>
                    <Icon name={menuItem.icon} />
                  </ListItemIcon>

                  <Typography variant="body2" css={styles.listItemText}>
                    {t(menuItem.i18nKey)}
                  </Typography>

                  <div className="bottom-border" />
                </Link>
              </ListItemButton>
              ))}
            </Menu>

          </Box>
        </Toolbar>
      </AppBar>
  );
};

export default NavbarUi;
