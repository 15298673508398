import { ChainId } from 'config';
import type { Brands, IGlobalData } from './globalData.d';

const config: Record<Brands, IGlobalData> = {
  brainiac: {
    chainId: 71402,
    chainName: 'Godwoken Mainnet',
    currency: 'ETH',
    decimals: 18,
    socialLink: {
      MEDIUM: 'https://medium.com/@brainiacfi',
      DISCORD: 'https://discord.gg/FcUQnEyyYc',
      TWITTER: 'https://twitter.com/BrainiacFinance',
      GITHUB: 'https://github.com/brainiacfi/',
    },
  },
  unite: {
    chainId: 1666600000,
    chainName: 'Harmony Mainnet',
    currency: 'ONE',
    decimals: 18,
    socialLink: {
      MEDIUM: 'https://medium.com/@brainiacfi',
      DISCORD: 'https://discord.gg/FcUQnEyyYc',
      TWITTER: 'https://twitter.com/BrainiacFinance',
      GITHUB: 'https://github.com/brainiacfi/',
    },
  },
};

export default config[process.env.REACT_APP_BRAND_NAME || 'brainiac'] as IGlobalData;
