import { MutationObserverOptions } from 'react-query';

import { BRTokenId } from 'types';
import {
  useRepayNonBnbBRToken,
  useRepayBnb,
  RepayBnbOutput,
  RepayNonBnbBRTokenOutput,
  IRepayNonBnbBRTokenInput,
  IRepayBnbInput,
} from 'clients/api';

type Options = MutationObserverOptions<
  RepayBnbOutput | RepayNonBnbBRTokenOutput,
  Error,
  Omit<IRepayNonBnbBRTokenInput, 'brTokenContract'> | Omit<IRepayBnbInput, 'web3'>
>;

const useRepayBRToken = ({ brTokenId }: { brTokenId: BRTokenId }, options?: Options) => {
  const useRepayNonBnbBRTokenResult = useRepayNonBnbBRToken(
    { brTokenId: brTokenId as BRTokenId },
    options,
  );
  // const useRepayBnbResult = useRepayBnb(options);

  return useRepayNonBnbBRTokenResult;
};

export default useRepayBRToken;
