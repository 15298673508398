import { useQuery, QueryObserverOptions } from 'react-query';

import { BRTokenId } from 'types';
import getBRTokenInterestRateModel, {
  GetBRTokenInterestRateModelOutput,
} from 'clients/api/queries/getBRTokenInterestRateModel';
import FunctionKey from 'constants/functionKey';
import { useBRTokenContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBRTokenInterestRateModelOutput,
  Error,
  GetBRTokenInterestRateModelOutput,
  GetBRTokenInterestRateModelOutput,
  [FunctionKey.GET_V_TOKEN_INTEREST_RATE_MODEL, BRTokenId]
>;

const useGetBRTokenInterestRateModel = ({ brTokenId }: { brTokenId: BRTokenId }, options?: Options) => {
  const brTokenContract = useBRTokenContract(brTokenId);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_INTEREST_RATE_MODEL, brTokenId],
    () => getBRTokenInterestRateModel({ brTokenContract }),
    options,
  );
};

export default useGetBRTokenInterestRateModel;
