import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

export const FAQ = () => {
  const styles = useStyles();
  return (
    <Box sx={{ padding: '2.5rem', paddingY: '3rem' }}>
      <Typography variant="h1" component="h1" sx={{ color: 'white', fontSize: '3rem' }}>
        FAQ
      </Typography>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          How to get NFT ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>
          Early Participant NFTs are automatically airdropped into the wallet used for participating
          in the IDO. Connect your wallet to see the NFT you received
        </Typography>
      </Box>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          Are NFT tradable ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>
          Yes, Brainiac NFTs are fully tradable and can be listed on Yokai Dojo if you choose to.
        </Typography>
      </Box>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          What is the utility ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>
          Brainiac NFTs come with a special ability to boost returns by staking in Brainiac Farms.
          Staking of NFTs will go live in the next few weeks. Stay tuned
        </Typography>
      </Box>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          Are all NFT's same rarity wise ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>Yes, all NFTs have the same rarity.</Typography>
      </Box>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          How many NFT's can I get ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>
          Each participant is eligible to only ONE free NFT. You can trade or buy more NFTs
        </Typography>
      </Box>
      <Box sx={{marginY : "80px"}}>
        <Typography variant="h3" component="h3" css={styles.title}>
          How many NFT's can be staked ?
        </Typography>
        <Typography css={styles.text} sx={{fontSize : "1rem", paddingY : "10px"}}>Only 1 NFT can be staked at time by 1 wallet</Typography>
      </Box>
    </Box>
  );
};
