import React from 'react'

import {
    Link
} from "react-router-dom";

import '../../assets/styles/homeComponents/Borrow.scss'

// importing images 
import BackgroundWeb from '../../assets/homeImages/web.png'
import Ring from '../../assets/homeImages/Ring.png'
import LongCurl from '../../assets/homeImages/long-curl.png'
import CShape from '../../assets/homeImages/c-shape.png'
import Cone from '../../assets/homeImages/cone.png'
import FlagIcon from '../../assets/homeImages/flag.svg'
import MagicStick from '../../assets/homeImages/magic-stick.svg'
import Recycle from '../../assets/homeImages/recycle.svg'
import Calendar from '../../assets/homeImages/calendar.svg'
import Gift from '../../assets/homeImages/gift.svg'
import Lock from '../../assets/homeImages/lock.svg'


const Borrow = () => {
    return (
        <>
            <div className="borrow">
                <div className="borrow__container">
                    <div className="borrow__head">
                        <div className="borrow__heading">
                            <h1>Borrow</h1>
                            <h4>Digital assets can be borrowed against deposited funds. Quickly obtain various cryptocurrencies without trading on exchanges.</h4>
                        </div>
                    </div>

                    <div className="borrow__key-points-container">
                        <div className="borrow__key-points-inner-container">

                            {/* Key Point  */}
                            <div className="borrow__key-points-box-one each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={FlagIcon} alt="" />
                                </div>
                                <h5>Instantly obtain assets</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="borrow__key-points-box-two each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={MagicStick} alt="" />
                                </div>
                                <h5>No waiting for exchange transactions</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="borrow__key-points-box-three each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={Recycle} alt="" />
                                </div>
                                <h5>No KYC required</h5>
                            </div>

                            {/* Key Point */}
                            <div className="borrow__key-points-box-four each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={Calendar} alt="" />
                                </div>
                                <h5>Borrowing is instant and unlimited -only restricted by user's total deposits pledged as collateral assets</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="borrow__key-points-box-five each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={Gift} alt="" />
                                </div>
                                <h5>Loans require pledging more than is borrowed: lenders funds are guaranteed</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="borrow__key-points-box-six each-key-point-box">
                                <div className="borrow__key-points-icon-box">
                                    <img src={Lock} alt="" />
                                </div>
                                <h5>Repay at anytime, with no time constraints</h5>
                            </div>
                        </div>

                        <div className="borrow__key-point-image">
                            <div className="borrow__side-images">
                                <img className='borrow__web-background' src={BackgroundWeb} alt="" />
                                <img className='borrow__long-curl' src={LongCurl} alt="" />
                                <img className='borrow__side-ring' src={Ring} alt="" />
                                <img className='borrow__c-shape' src={CShape} alt="" />
                                <img className='borrow__cone' src={Cone} alt="" />
                            </div>
                        </div>

                    </div>

                    <div className="borrow__borrow-now-btn">
                        {/* <Link to="/dashboard"><button>Borrow Now</button></Link> */}
                        <Link to="/dashboard"><button>Launch App</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Borrow