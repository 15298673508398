/** @jsxImportSource @emotion/react */
import React from 'react';

import { LabeledInlineContent, Icon, SecondaryButton, TextButton } from 'components';
import { useTranslation } from 'translation';
import { IStat } from '../types';
import Card from '../Card';
import { Select } from 'antd';
import { useStyles } from './styles';
import { IoIosAddCircle } from 'react-icons/io';
import { Stack, Paper, Typography, Button, MenuItem } from '@mui/material';
import { ReactComponent as MetaMaskLogo } from 'assets/img/v2/wallets/metaMaskLogo.svg';
import { BRTokenId , TokenId} from 'types';
import { getToken, getBRErcToken } from 'utilities';
import { BRERC_TOKENS } from 'constants/tokens';
import { uid } from 'react-uid';
import { useState } from 'react';

export interface IMarketInfoProps {
  stats: IStat[];
  testId?: string;
}


const MarketInfo: React.FC<IMarketInfoProps> = ({ stats, testId }) => {
  
  const { t } = useTranslation();
  const styles = useStyles();



  // @TODO: change case in the text rendered in the Button

  return (      
      <ul css={styles.itemList}>
        {stats.map(stat => (
          <li css={styles.item} key={`market-info-stat-${stat.label}`}>
            <LabeledInlineContent label={stat.label}>
              <span css={styles.value}>{stat.value}</span>
            </LabeledInlineContent>
          </li>
        ))}
      </ul>
  );
};

export default MarketInfo;
