import { IGetBaiVaultUserInfoInput, IGetBaiVaultUserInfoOutput } from './types';
import formatToUserInfo from './formatToUserInfo';

export * from './types';

const getBaiVaultUserInfo = async ({
  baiVaultContract,
  accountAddress,
}: IGetBaiVaultUserInfoInput): Promise<IGetBaiVaultUserInfoOutput> => {
  const res = await baiVaultContract.methods.userInfo(accountAddress).call();
  return formatToUserInfo(res);
};

export default getBaiVaultUserInfo;
