import * as React from 'react';
import { SVGProps } from 'react';

const SvgBch = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
      fill="#0AC18E"
    />
    <path
      d="M15.12 8.642c-.503-1.14-1.658-1.383-3.072-1.147l-.454-1.761-1.071.276.447 1.757c-.282.07-.572.131-.858.213l-.447-1.746-1.071.276.454 1.762c-.23.066-2.162.56-2.162.56L7.18 9.98s.787-.221.78-.203c.436-.115.641.104.738.31l1.249 4.827c.015.14-.01.378-.31.46.018.01-.78.2-.78.2l.118 1.337s1.913-.49 2.165-.553l.459 1.782 1.071-.277-.46-1.794c.295-.069.582-.14.861-.214l.457 1.785 1.07-.277-.459-1.78c1.65-.4 2.815-1.44 2.577-3.032-.153-.96-1.2-1.746-2.071-1.835.535-.475.807-1.168.474-2.074Zm-.516 4.2c.213 1.577-1.977 1.77-2.7 1.96l-.63-2.358c.726-.19 2.97-.99 3.33.399Zm-1.32-3.21c.226 1.401-1.647 1.563-2.251 1.718l-.574-2.14c.607-.15 2.366-.875 2.825.422Z"
      fill="#fff"
    />
  </svg>
);

export default SvgBch;
