import { useContext } from 'react';
import { BaiContext } from '../context/BaiContext';

export const useBaiUser = () => {
  const { userBaiMinted, userBaiBalance, userBaiEnabled, mintableBai } = useContext(BaiContext);
  return {
    userBaiMinted,
    userBaiBalance,
    userBaiEnabled,
    mintableBai,
  };
};
