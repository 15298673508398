import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    cardContentGrid: css`
      .table__table-cards__card-content {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
      }
    `,
    coloredValues: ({ supplyApySign }: { supplyApySign: boolean }) => css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: ${supplyApySign ? '#1bda8a' : theme.palette.interactive.error};

      ${theme.breakpoints.down('sm')} {
        justify-content: start;
      }
    `,
    coloredValues2: ({ supplyApySign }: { supplyApySign: boolean }) => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${supplyApySign ? theme.palette.interactive.success : theme.palette.interactive.error};

      ${theme.breakpoints.down('sm')} {
        justify-content: start;
      }
    `,
    coloredValues3: ({ supplyApySign }: { supplyApySign: boolean }) => css`
      align-items: center;
      color: ${supplyApySign ? '#1bda8a' : theme.palette.interactive.error};

      ${theme.breakpoints.down('sm')} {
        justify-content: start;
      }
    `,
    coloredValues4: ({ supplyApySign }: { supplyApySign: boolean }) => css`
      align-items: center;
      color: ${supplyApySign ? theme.palette.interactive.success : theme.palette.interactive.error};

      ${theme.breakpoints.down('sm')} {
        justify-content: start;
      }
    `,
  };
};
