/** @jsxImportSource @emotion/react */
import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { formatCentsToReadableValue, formatToReadablePercentage } from 'utilities';
import { useTranslation } from 'translation';
import { IToggleProps, Toggle, Icon, Tooltip, BorrowLimitUsedAccountHealth } from 'components';
import Paper from '@mui/material/Paper';
import { useMyAccountStylesV2 as useStyles } from './styleV2';
import { IoIosAddCircle } from 'react-icons/io';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { addToken, format } from 'utilities/common';
import IconButton from '@mui/material/IconButton';
import { useMarketsUser } from 'hooks/useMarketsUser';
import { generateBscScanUrl } from 'utilities';
import { useWeb3Account } from 'clients/web3';
import { Box } from '@mui/material';
export interface IMyAccountUiProps {
  netApyPercentage: number | undefined;
  dailyEarningsCents: number | undefined;
  supplyBalanceCents: number | undefined;
  borrowBalanceCents: number | undefined;
  borrowLimitCents: number | undefined;
  safeBorrowLimitPercentage: number;
  isBrnEnabled: boolean;
  onBrnToggle: (newValue: boolean) => void;
  className?: string;
}

export const MyAccountUiV2 = ({
  netApyPercentage,
  dailyEarningsCents,
  supplyBalanceCents,
  borrowBalanceCents,
  borrowLimitCents,
  safeBorrowLimitPercentage,
  isBrnEnabled,
  onBrnToggle,
  className,
}: IMyAccountUiProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { account } = useWeb3Account();

  const handleBrnToggleChange: IToggleProps['onChange'] = (_event, checked) => onBrnToggle(checked);

  const safeBorrowLimitCents =
    typeof borrowLimitCents === 'number'
      ? Math.floor((borrowLimitCents * safeBorrowLimitPercentage) / 100)
      : undefined;

  const { userBrnBalance } = useMarketsUser();

  const readableSafeBorrowLimit = formatCentsToReadableValue({
    value: safeBorrowLimitCents,
  });

  const readableNetApyPercentage = formatToReadablePercentage(netApyPercentage);
 

  const readableBorrowBalance = formatCentsToReadableValue({
    value: borrowBalanceCents,
  });

  const readableDailyEarnings = formatCentsToReadableValue({
    value: dailyEarningsCents,
  });

  const readableSupplyBalance = formatCentsToReadableValue({
    value: supplyBalanceCents,
  });

  const handleLink = () => {
    window.open(`${generateBscScanUrl('brn', 'token')}?a=${account}`, '_blank');
  };

  return (
    <Paper css={styles.container} className={className}>
      <div css={[styles.row, styles.header]}>
        <Typography variant="h4">{t('myAccount.title')}</Typography>

        <div css={styles.apyWithBrn}>
          <Tooltip css={styles.tooltip} title={t('myAccount.apyWithBrnTooltip')}>
            <Icon css={styles.infoIcon} name="info" />
          </Tooltip>

          <Typography
            color="text.primary"
            variant="small1"
            component="span"
            css={styles.apyWithBrnLabel}
          >
            {t('myAccount.apyWithBrn')}
          </Typography>

          <Toggle css={styles.toggle} value={isBrnEnabled} onChange={handleBrnToggleChange} />
        </div>
      </div>

      <div css={styles.netApyContainer}>
        <div css={styles.netApy}>
          <Typography component="span" variant="small2" css={styles.netApyLabel}>
            {t('myAccount.netApy')}
          </Typography>

          <Tooltip css={styles.tooltip} title={t('myAccount.netApyTooltip')}>
            <Icon css={styles.infoIcon} name="info" />
          </Tooltip>
        </div>

        <Typography
          variant="h1"
          color={
            netApyPercentage !== undefined && netApyPercentage >= 0
              ? 'interactive.success'
              : 'interactive.error'
          }
          component="span"
        >
          {readableNetApyPercentage}
        </Typography>
      </div>

      <ul css={styles.list}>
        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.dailyEarnings')}
          </Typography>

          {readableDailyEarnings}
        </Typography>

        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.supplyBalance')}
          </Typography>

          {readableSupplyBalance}
        </Typography>

        <Typography component="li" variant="h4" css={styles.item}>
          <Typography component="span" variant="small2" css={styles.labelListItem}>
            {t('myAccount.borrowBalance')}
          </Typography>

          {readableBorrowBalance}
        </Typography>
      </ul>

      <BorrowLimitUsedAccountHealth
        css={styles.progressBar}
        borrowBalanceCents={borrowBalanceCents}
        safeBorrowLimitPercentage={safeBorrowLimitPercentage}
        borrowLimitCents={borrowLimitCents}
      />

      <div css={styles.bottom}>
        <Icon name="shield" css={styles.shieldIcon} />

        <Typography component="span" variant="small2" css={styles.inlineLabel}>
          {t('myAccount.safeLimit')}
        </Typography>

        <Typography component="span" variant="small1" color="text.primary" css={styles.safeLimit}>
          {readableSafeBorrowLimit}
        </Typography>

        <Tooltip
          css={styles.tooltip}
          title={t('myAccount.safeLimitTooltip', { safeBorrowLimitPercentage })}
        >
          <Icon css={styles.infoIcon} name="info" />
        </Tooltip>
      </div>

      <Box css={styles.bottomCardsContainer}>
        <Box css={styles.userBalanceContainer}>
          <Icon name="brain" css={styles.userBalanceicon} />
          <Typography variant="h3" css={styles.userBalanceLabel}>
            {format(userBrnBalance)} BRN
          </Typography>
          <IoIosAddCircle
            css={styles.addIconSmall}
            onClick={() =>
              addToken({
                asset: 'brn',
                decimal: 18,
                type: 'token',
              })
            }
          />
          <BsFillArrowUpRightCircleFill css={styles.iconSmall} onClick={() => handleLink()} />
        </Box>
      </Box>
    </Paper>
  );
};

export default MyAccountUiV2;
