import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core';
import { BrainiacNFT } from 'types/contracts';
import { checkForBaiControllerTransactionError } from 'errors';
import TOKEN_URI from "constants/NFT/tokenURI.json"


export interface IMintNFTInput {
  brainiacNFTContract: BrainiacNFT;
  fromAccountAddress: string;
}

export type MintNFTOutput = TransactionReceipt;

const mintNFT = async ({
  brainiacNFTContract,
  fromAccountAddress,
}: IMintNFTInput): Promise<MintNFTOutput> => {

  const tokenId = Number(await brainiacNFTContract.methods.totalSupply().call());
  // get pre-randomized tokenURI
  const tokenURI = TOKEN_URI[tokenId].ipfsHash;

  const resp = await brainiacNFTContract.methods
    .safeMint(fromAccountAddress,tokenURI)
    .send({ from: fromAccountAddress });

  return checkForBaiControllerTransactionError(resp);
};

export default mintNFT;
