import BigNumber from 'bignumber.js';

import { InterestModel } from 'types/contracts';

export interface IGetBRTokenBorrowRateInput {
  interestModelContract: InterestModel;
  cashAmountWei: BigNumber;
  borrowsAmountWei: BigNumber;
  reservesAmountWei: BigNumber;
}

export type IGetBRTokenBorrowRateOutput = BigNumber;

const getBRTokenBorrowRate = async ({
  interestModelContract,
  cashAmountWei,
  borrowsAmountWei,
  reservesAmountWei,
}: IGetBRTokenBorrowRateInput): Promise<IGetBRTokenBorrowRateOutput> => {
  const borrowRate = await interestModelContract.methods
    .getBorrowRate(cashAmountWei.toFixed(), borrowsAmountWei.toFixed(), reservesAmountWei.toFixed())
    .call();
  return new BigNumber(borrowRate);
};

export default getBRTokenBorrowRate;
