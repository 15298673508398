import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { TokenId, BRTokenId } from 'types';
import { getWeb3NoAccount } from 'clients/web3';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import interestModelAbi from 'constants/contracts/abis/interestModel.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import baiTokenAbi from 'constants/contracts/abis/baiToken.json';
import baiUnitrollerAbi from 'constants/contracts/abis/baiUnitroller.json';
import baiVaultAbi from 'constants/contracts/abis/baiVault.json';
import brnVaultStoreAbi from 'constants/contracts/abis/brnVaultStore.json';
import brnVaultAbi from 'constants/contracts/abis/brnVault.json';
import lockedStakingAbi from 'constants/contracts/abis/LockedStaking.json';
import liquidityPoolAbi from 'constants/contracts/abis/liquidityPool.json';
import brErc20Abi from 'constants/contracts/abis/brErc20.json';
import vBnbTokenAbi from 'constants/contracts/abis/vBnbToken.json';
import brnTokenAbi from 'constants/contracts/abis/brnToken.json';
import brainiacLensAbi from 'constants/contracts/abis/brainiacLens.json';
import governorBravoDelegateAbi from 'constants/contracts/abis/governorBravoDelegate.json';
import maximillionAbi from 'constants/contracts/abis/maximillion.json';
import brnVestingAbi from 'constants/contracts/abis/brnVesting.json';
import vrtConverterAbi from 'constants/contracts/abis/vrtConverter.json';
import vrtTokenAbi from 'constants/contracts/abis/vrtToken.json';
import vrtVaultAbi from 'constants/contracts/abis/vrtVault.json';
import faucetAbi from 'constants/contracts/abis/faucet.json';
import brainiacNFTAbi from 'constants/contracts/abis/brainiacNFT.json';
import {
  Erc20,
  Comptroller,
  InterestModel,
  Oracle,
  BaiVault,
  BaiUnitroller,
  BrnVault,
  BrnVaultStore,
  BrainiacLens,
  GovernorBravoDelegate,
  Maximillion,
  BrnVesting,
  VrtVault,
  VrtConverter,
  BrainiacNFT,
  LiquidityPool
} from 'types/contracts';
import { getContractAddress, getToken, getBRErcToken } from 'utilities';
import { TokenContract, BRTokenContract } from './types';

const getContract = <T>(abi: AbiItem | AbiItem[], address: string, web3Instance: Web3) => {
  const web3 = web3Instance ?? getWeb3NoAccount();
  return new web3.eth.Contract(abi, address) as unknown as T;
};

export const getTokenContract = <T extends TokenId>(tokenId: T, web3: Web3): TokenContract<T> => {
  const tokenAddress = getToken(tokenId).address;

  if (tokenId === 'brn') {
    return getContract<TokenContract<T>>(brnTokenAbi as AbiItem[], tokenAddress, web3);
  }

  if (tokenId === 'bai') {
    return getContract<TokenContract<T>>(baiTokenAbi as AbiItem[], tokenAddress, web3);
  }

  if (tokenId === 'vrt') {
    return getContract<TokenContract<T>>(vrtTokenAbi as AbiItem[], tokenAddress, web3);
  }

  return getContract<TokenContract<T>>(erc20Abi as AbiItem[], tokenAddress, web3);
};

export const getTokenContractByAddress = (address: string, web3: Web3): Erc20 =>
  getContract(erc20Abi as AbiItem[], address, web3) as unknown as Erc20;

export const getBRTokenContract = <T extends BRTokenId>(
  tokenId: T,
  web3: Web3,
): BRTokenContract<T> => {
  const brErcTokenAddress = getBRErcToken(tokenId).address;

  // if (tokenId === 'bnb') {
  //   return getContract(
  //     vBnbTokenAbi as AbiItem[],
  //     brErcTokenAddress,
  //     web3,
  //   ) as unknown as BRTokenContract<T>;
  // }

  return getContract(
    brErc20Abi as AbiItem[],
    brErcTokenAddress,
    web3,
  ) as unknown as BRTokenContract<T>;
};

export const getBaiUnitrollerContract = (web3: Web3) =>
  getContract(
    baiUnitrollerAbi as AbiItem[],
    getContractAddress('baiUnitroller'),
    web3,
  ) as unknown as BaiUnitroller;

export const getBaiVaultContract = (web3: Web3) =>
  getContract(
    baiVaultAbi as AbiItem[],
    getContractAddress('baiVault'),
    web3,
  ) as unknown as BaiVault;

export const getBrnVaultContract = (web3: Web3) =>
  getContract(
    brnVaultAbi as AbiItem[],
    getContractAddress('brnVault'),
    web3,
  ) as unknown as BrnVault;

export const getBrnVaultProxyContract = (web3: Web3) =>
  getContract(
    brnVaultAbi as AbiItem[],
    getContractAddress('brnVaultProxy'),
    web3,
  ) as unknown as BrnVault;

export const getBrnVaultStoreContract = (web3: Web3) =>
  getContract(
    brnVaultStoreAbi as AbiItem[],
    getContractAddress('brnVaultStore'),
    web3,
  ) as unknown as BrnVaultStore;

export const getComptrollerContract = (web3: Web3) =>
  getContract(
    comptrollerAbi as AbiItem[],
    getContractAddress('comptroller'),
    web3,
  ) as unknown as Comptroller;

export const getPriceOracleContract = (web3: Web3) =>
  getContract(oracleAbi as AbiItem[], getContractAddress('oracle'), web3) as unknown as Oracle;

export const getFaucetContract = (web3: Web3) =>
  getContract(faucetAbi as AbiItem[], getContractAddress('faucet'), web3);

export const getInterestModelContract = (address: string, web3: Web3) =>
  getContract(interestModelAbi as AbiItem[], address, web3) as unknown as InterestModel;

export const getBrainiacLensContract = (web3: Web3) =>
  getContract(
    brainiacLensAbi as AbiItem[],
    getContractAddress('brainiacLens'),
    web3,
  ) as unknown as BrainiacLens;

export const getGovernorBravoDelegateContract = (web3: Web3) =>
  getContract(
    governorBravoDelegateAbi as AbiItem[],
    getContractAddress('governorBravoDelegator'),
    web3,
  ) as unknown as GovernorBravoDelegate;

export const getMaximillionContract = (web3: Web3) =>
  getContract(
    maximillionAbi as AbiItem[],
    getContractAddress('maximillion'),
    web3,
  ) as unknown as Maximillion;

// VRT conversion
export const getBrnVestingProxyContract = (web3: Web3) =>
  getContract(
    brnVestingAbi as AbiItem[],
    getContractAddress('brnVestingProxy'),
    web3,
  ) as unknown as BrnVesting;

export const getVrtConverterProxyContract = (web3: Web3) =>
  getContract(
    vrtConverterAbi as AbiItem[],
    getContractAddress('vrtConverterProxy'),
    web3,
  ) as unknown as VrtConverter;

// VRT vault
export const getVrtVaultProxyContract = (web3: Web3) =>
  getContract(
    vrtVaultAbi as AbiItem[],
    getContractAddress('vrtVaultProxy'),
    web3,
  ) as unknown as VrtVault;

export const getBrainVaultContract = (address: string, web3: Web3) =>
  getContract(lockedStakingAbi as AbiItem[], address, web3) as unknown as BrnVault;

export const getBRNCKBPoolContract = (address: string, web3: Web3) =>
  getContract(liquidityPoolAbi as AbiItem[], address, web3) as unknown as LiquidityPool;

export const getBrainiacNFTContract = ( web3: Web3) =>
  getContract(brainiacNFTAbi as AbiItem[], getContractAddress('brainiacNFT'), web3) as unknown as BrainiacNFT;