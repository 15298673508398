import BigNumber from 'bignumber.js';

import { BaiVault } from 'types/contracts';
import { IGetBaiVaultUserInfoOutput } from './types';

const formatToUserInfo = ({
  amount,
}: Awaited<
  ReturnType<ReturnType<BaiVault['methods']['userInfo']>['call']>
>): IGetBaiVaultUserInfoOutput => ({
  stakedBaiWei: new BigNumber(amount),
});

export default formatToUserInfo;
