import * as React from 'react';
import { SVGProps } from 'react';

const SvgMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle fill="rgba(35, 52, 71, 1)" stroke="none" strokeWidth={2} strokeMiterlimit={10} cx="24" cy="24" r="24" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8892 38.0833L20.8297 39.4376V37.6667L21.233 37.25H24.0561V39.3333V40.7917H21.0314L17.3007 39.125L15.8892 38.0833Z"
      fill="#CDBDB2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0212 38.0833L27.1816 39.4376V37.6667L26.7783 37.25H23.9552V39.3333V40.7917H26.9799L30.7104 39.125L32.0212 38.0833Z"
      fill="#CDBDB2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.233 34.2293L20.8297 37.6667L21.3337 37.25H26.5766L27.1816 37.6667L26.7783 34.2293L25.9717 33.7083L21.9387 33.8126L21.233 34.2293Z"
      fill="#393939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5106 11L20.9304 16.8333L22.0396 33.8126H25.9717L27.1816 16.8333L29.3998 11H18.5106Z"
      fill="#F89C35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92403 23.8126L5.10092 32.25L12.1587 31.8333H16.6958V28.1876L16.4941 20.6876L15.4859 21.5209L7.92403 23.8126Z"
      fill="#F89D35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2677 24.75L21.5354 24.9583L20.628 29.3333L16.6958 28.2917L13.2677 24.75Z"
      fill="#D87C30"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2677 24.8543L16.6958 28.1876V31.5209L13.2677 24.8543Z"
      fill="#EA8D3A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6957 28.2917L20.7288 29.3333L22.0396 33.8126L21.1321 34.3333L16.6957 31.625V28.2917Z"
      fill="#F89D35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6958 31.625L15.8892 38.0833L21.233 34.2293L16.6958 31.625Z"
      fill="#EB8F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5354 24.9583L22.0396 33.8126L20.5271 29.2813L21.5354 24.9583Z"
      fill="#EA8E3A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0578 31.7293L16.6957 31.625L15.8891 38.0833L12.0578 31.7293Z"
      fill="#D87C30"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.31908 40.8959L15.8892 38.0833L12.0578 31.7293L5.10092 32.25L7.31908 40.8959Z"
      fill="#EB8F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9304 16.8333L16.595 20.5833L13.2677 24.75L21.5354 25.0626L20.9304 16.8333Z"
      fill="#E8821E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8892 38.0833L21.233 34.2293L20.8297 37.5626V39.4376L17.2 38.7083L15.8892 38.0833Z"
      fill="#DFCEC3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0212 38.0833L26.7783 34.2293L27.1816 37.5626V39.4376L30.8113 38.7083L32.0212 38.0833Z"
      fill="#DFCEC3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3172 26.8333L20.4264 29.2293L16.4941 28.1876L19.3172 26.8333Z"
      fill="#393939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21817 6.10425L20.9304 16.8333L18.6115 11L7.21817 6.10425Z"
      fill="#E88F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21815 6.10416L5.40329 11.8333L6.41154 18.0833L5.70577 18.5L6.71402 19.4375L5.90742 20.1667L7.0165 21.2083L6.31072 21.8333L7.92393 23.9167L15.4858 21.5208C19.1828 18.4653 20.9976 16.9028 20.9304 16.8333C20.8632 16.7639 16.2924 13.1875 7.21815 6.10416Z"
      fill="#8E5A30"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.9863 23.8126L42.8094 32.25L35.7517 31.8333H31.2146V28.1876L31.4163 20.6876L32.4245 21.5209L39.9863 23.8126Z"
      fill="#F89D35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6427 24.75L26.375 24.9583L27.2823 29.3333L31.2146 28.2917L34.6427 24.75Z"
      fill="#D87C30"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6427 24.8542L31.2146 28.1876V31.5209L34.6427 24.8542Z"
      fill="#EA8D3A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.2146 28.2917L27.1816 29.3333L25.8708 33.8126L26.7783 34.3333L31.2146 31.625V28.2917Z"
      fill="#F89D35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.2146 31.625L32.0212 38.0833L26.7783 34.3333L31.2146 31.625Z"
      fill="#EB8F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.375 24.9583L25.8708 33.8126L27.3832 29.2813L26.375 24.9583Z"
      fill="#EA8E3A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8526 31.7292L31.2146 31.625L32.0212 38.0833L35.8526 31.7292Z"
      fill="#D87C30"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5913 40.8959L32.0212 38.0833L35.8526 31.7292L42.8094 32.25L40.5913 40.8959Z"
      fill="#EB8F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9799 16.8333L31.3153 20.5833L34.6427 24.75L26.375 25.0626L26.9799 16.8333Z"
      fill="#E8821E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5931 26.8333L27.484 29.2293L31.4163 28.1876L28.5931 26.8333Z"
      fill="#393939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6922 6.10425L26.9799 16.8333L29.2988 11L40.6922 6.10425Z"
      fill="#E88F35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6922 6.10416L42.5071 11.8333L41.4988 18.0833L42.2046 18.5L41.1963 19.4375L42.003 20.1667L40.8939 21.2083L41.5997 21.8333L39.9864 23.9167L32.4245 21.5208C28.7276 18.4653 26.9127 16.9028 26.98 16.8333C27.0472 16.7639 31.6179 13.1875 40.6922 6.10416Z"
      fill="#8E5A30"
    />
  </svg>
);

export default SvgMedium;

