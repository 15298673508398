import { useQuery, QueryObserverOptions } from 'react-query';

import {
  getBrainiacBaiVaultDailyRateWei,
  GetBrainiacBaiVaultDailyRateWeiOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useComptrollerContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBrainiacBaiVaultDailyRateWeiOutput,
  Error,
  GetBrainiacBaiVaultDailyRateWeiOutput,
  GetBrainiacBaiVaultDailyRateWeiOutput,
  FunctionKey.GET_BRAINIAC_BAI_VAULT_DAILY_RATE_WEI
>;

const useGetBrainiacBaiVaultDailyRateWei = (options?: Options) => {
  const comptrollerContract = useComptrollerContract();

  return useQuery(
    FunctionKey.GET_BRAINIAC_BAI_VAULT_DAILY_RATE_WEI,
    () => getBrainiacBaiVaultDailyRateWei({ comptrollerContract }),
    options,
  );
};

export default useGetBrainiacBaiVaultDailyRateWei;
