import React from 'react'


import '../../assets/styles/homeComponents/Navbar.scss'
import Path from 'constants/path';

// importing images
import logo from '../../assets/homeImages/navbar-logo.png'
import Twitter from '../../assets/homeImages/twitter.png'
import Discord from '../../assets/homeImages/discord.png'

const Navbar = () => {
    const slideNavbar = (event) => {
        var sideNavbar = document.getElementById('sidebar');
        var hamaburgerIconOne = document.getElementById('navbar__hamburger-show-side-bar-icon');
        var hamburgerIconTwo = document.getElementById('navbar__hamburger-hide-side-bar-icon');
        var documentLink = document.getElementById('navbar__docs-link');
        if (sideNavbar.style.display = 'none') {
            sideNavbar.style.display = "flex";
            hamaburgerIconOne.style.display = 'none';
            hamburgerIconTwo.style.display = 'inline-block'
            documentLink.textContent = 'Documents'

        }
    }

    const launchApp = ()=>{
        window.location = Path.DASHBOARD;
    }

    const hideNavbar = (event) => {
        var HidesideNavbar = document.getElementById('sidebar');
        var hamaburgerIconOne = document.getElementById('navbar__hamburger-show-side-bar-icon');
        var hamburgerIconTwo = document.getElementById('navbar__hamburger-hide-side-bar-icon');
        HidesideNavbar.style.display = "none";
        hamaburgerIconOne.style.display = 'inline-block';
        hamburgerIconTwo.style.display = 'none'
    }
    return (
        <>
            <div className="navbar">
                <div className="navbar__container">
                    <div className="navbar__logo">
                        <div className="navbar__logo-background">
                            <img src={logo} alt="logo" />
                        </div>
                        <h4>Brainiac Finance</h4>
                    </div>

                    <div className="navbar__links-and-button-box" id='sidebar'>
                        <div className="navbar__links">
                            <ul>
                                {/* <Link to="/dashboard"><li>Dashboard</li></Link>
                                <a href=""><li>Lend &amp; Borrow</li></a> */}
                                <a href="https://brainiacfinance.gitbook.io/" target='_blank'><li id='navbar__docs-link'>Docs</li></a>
                                <a href="https://vault.brainiac.fi/" target=" _blank"><li>Auto Vaults</li></a>
                                <a href="https://crash.brainiac.finance/" target=" _blank"><li>Crash</li></a>
                                <a href="https://liquidate.brainiac.finance/" target=" _blank"><li>Liquidator</li></a>
                                <a href="https://discord.gg/FcUQnEyyYc" target=" _blank"><li>Discord</li></a>

                            </ul>
                        </div>

                        <div className="navbar__sidebar-logo-box">
                            <img src={logo} alt="" />
                        </div>
                        <div className="navbar__sidebar-logo-title">
                            <h5>Brainiac Finance</h5>
                        </div>

                        <div className="navbar__launch-btn-box">
                            <button className='navbar__launch-btn' onClick={launchApp}>Launch App</button>
                            {/* <button className='navbar__launch-btn' >Launch App</button> */}
                        </div>

                        <div className="navbar__sidebar-social-icons">
                            <div className="navbar__sidebar-twitter sidebar-social-icons">
                                <a href="https://twitter.com/BrainiacFinance"><img src={Twitter} alt="" /></a>
                            </div>
                            <div className="navbar__sidebar-discord sidebar-social-icons">
                                <a href="https://discord.gg/FcUQnEyyYc " target="_blank"><img src={Discord} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* creating hamburger icon  */}
                <div className="navbar__hambuger-icon-box" id='navbar__hamburger-show-side-bar-icon' onClick={slideNavbar}>
                    <div className="navbar__hamburger-icon-lines"></div>
                    <div className="navbar__hamburger-icon-lines"></div>
                    <div className="navbar__hamburger-icon-lines"></div>
                </div>

                <div className="navbar__hambuger-icon-box" id='navbar__hamburger-hide-side-bar-icon' onClick={hideNavbar}>
                    <div className="navbar__hamburger-icon-lines" id='navbar__hamburger-short-line'></div>
                    <div className="navbar__hamburger-icon-lines"></div>
                    <div className="navbar__hamburger-icon-lines" ></div>
                </div>
            </div>
        </>
    )
}

export default Navbar