import { BASE_BSC_SCAN_URL, CHAIN_ID, ChainId, RPC_URLS } from 'config';
import globalData from 'globalData';

// Prompt the user to add BSC as a network, or switch to BSC if the wallet is on
// a different network
const setUpNetwork = async () => {
  if (!window.ethereum) {
    // TODO: send error to Sentry

    console.error(
      `Can't set up the ${globalData.chainName} network on wallet because window.ethereum is undefined`,
    );
    return false;
  }

  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${CHAIN_ID.toString(16)}`,
          chainName: globalData.chainName,
          nativeCurrency: {
            name: globalData.currency,
            symbol: globalData.currency,
            decimals: globalData.decimals,
          },
          rpcUrls: RPC_URLS[CHAIN_ID],
          blockExplorerUrls: [`${BASE_BSC_SCAN_URL}/`],
        },
      ],
    });

    return true;
  } catch (error) {
    // TODO: send error to Sentry

    console.error('Failed to set up network on wallet:', error);
    return false;
  }
};

export default setUpNetwork;
