import * as React from 'react';
import { SVGProps } from 'react';

const SvgDot = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={10} fill="#E6007A" />
    <path
      d="M12.298 5a5.312 5.312 0 0 0-3.74 1.561 5.337 5.337 0 0 0-1.279 5.475.759.759 0 0 0 .93.467.755.755 0 0 0 .453-.933 3.486 3.486 0 0 1-.206-1.353 3.837 3.837 0 0 1 2.383-3.436 3.814 3.814 0 0 1 4.098.8 3.834 3.834 0 0 1 .923 4.08 3.817 3.817 0 0 1-3.353 2.493c0 .001-.74.048-1.113.094-.137.02-.272.046-.406.08a.05.05 0 0 1-.065-.004.05.05 0 0 1-.004-.066l.116-.63.698-3.168a.728.728 0 0 0-.559-.863.724.724 0 0 0-.86.56s-1.662 7.736-1.662 7.806a.708.708 0 0 0 .555.863.706.706 0 0 0 .86-.56c.024-.093.233-1.12.233-1.12a1.887 1.887 0 0 1 1.56-1.485c.159-.024.833-.07.833-.07a5.29 5.29 0 0 0 3.491-1.783 5.315 5.315 0 0 0-.272-7.292A5.288 5.288 0 0 0 12.298 5ZM13.622 17.23a.885.885 0 0 0-1.047.699.883.883 0 0 0 .374.919c.098.065.208.11.324.13a.862.862 0 0 0 1.043-.7.921.921 0 0 0-.694-1.049Z"
      fill="#fff"
    />
  </svg>
);

export default SvgDot;
