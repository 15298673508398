import React from 'react'

import {
    Link
} from "react-router-dom";

import '../../assets/styles/homeComponents/Lend.scss'

// importing images 
import LendKeyPoints from '../../assets/homeImages/lend-key-points.png'
import Circle from '../../assets/homeImages/cyan-circle.png'
import FlagIcon from '../../assets/homeImages/flag.svg'
import MagicStick from '../../assets/homeImages/magic-stick.svg'
import RecycleIcon from '../../assets/homeImages/recycle.svg'
import CalendarIcon from '../../assets/homeImages/calendar.svg'
import GiftIcon from '../../assets/homeImages/gift.svg'
import LockIcon from '../../assets/homeImages/lock.svg'

const Lend = () => {
    return (
        <>
            <div className="lend">
                <div className="lend__container">
                    <div className="lend__head">
                        <div className="lend__heading">
                            <h1>Lend</h1>
                            <h5>Deposits securely held on BRAINIAC earn you interest by providing liquidity for borrowing</h5>
                        </div>
                    </div>

                    <div className="lend__meters-container">
                        <div className="lend__meters">
                            <div className="lend__meter-values">4*</div>
                            <h6>ASSETS AVAILABLE</h6>

                        </div>
                        <div className="lend__meters">
                            <div className="lend__meter-values">0%*</div>
                            <h6>AVERAGE APY</h6>

                        </div>
                        <div className="lend__meters">
                            <div className="lend__meter-values">0*</div>
                            <h6>TOTAL DEPOSITS</h6>

                        </div>
                    </div>

                    <div className="lend__key-points-container">
                        <div className="lend__key-point-image">
                            <img src={LendKeyPoints} alt="" />
                            <img src={Circle} alt="" />
                        </div>

                        <div className="lend__key-points-inner-container">

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={FlagIcon} alt="" />
                                </div>
                                <h5>No minimum deposit required</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={MagicStick} alt="" />
                                </div>
                                <h5>Flexible interest models enable the best rates</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={RecycleIcon} alt="" />
                                </div>
                                <h5>Interest accumulates automatically and can be redeemed at any time</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={CalendarIcon} alt="" />
                                </div>
                                <h5>Withdraw deposits at any time</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={GiftIcon} alt="" />
                                </div>
                                <h5>Deposits earn interest in BRAINIAC and the corresponding asset</h5>
                            </div>

                            {/* Key Point  */}
                            <div className="lend__each-key-points-box">
                                <div className="lend__key-points-icon-box">
                                    <img src={LockIcon} alt="" />
                                </div>
                                <h5>Smart-controls guarantee
                                    minimal risk</h5>
                            </div>


                        </div>

                    </div>

                    <div className="lend__deposit-button">
                        {/* <Link to="/dashboard"><button>Deposit Now</button></Link> */}
                        <Link to='/dashboard'><button>Launch App</button></Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Lend