import { useQuery, QueryObserverOptions } from 'react-query';
import {
  getBaiVaultPendingBrnWei,
  IGetBaiVaultPendingBrnWeiInput,
  GetBaiVaultPendingBrnWeiOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useBaiVaultContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBaiVaultPendingBrnWeiOutput,
  Error,
  GetBaiVaultPendingBrnWeiOutput,
  GetBaiVaultPendingBrnWeiOutput,
  [FunctionKey.GET_BAI_VAULT_PENDING_BRN, string]
>;

const useGetBaiVaultPendingBrnWei = (
  { accountAddress }: Omit<IGetBaiVaultPendingBrnWeiInput, 'baiVaultContract'>,
  options?: Options,
) => {
  const baiVaultContract = useBaiVaultContract();

  return useQuery(
    [FunctionKey.GET_BAI_VAULT_PENDING_BRN, accountAddress],
    () => getBaiVaultPendingBrnWei({ baiVaultContract, accountAddress }),
    options,
  );
};

export default useGetBaiVaultPendingBrnWei;
