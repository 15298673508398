// @ts-nocheck
import { isOnTestnet } from 'config';
import { IMenuItem } from './types';

export const menuItems: IMenuItem[] = [
  {
    href: '/dashboard',
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: 'layout.menuItems.dashboard',
    icon: 'dashboard',
  },
  {
    href: '/market',
    // Translation key: do not remove this comment
    // t('layout.menuItems.market')
    i18nKey: 'layout.menuItems.market',
    icon: 'market',
  },
  {
    href: '/farm',
    // Translation key: do not remove this comment
    // t('layout.menuItems.farm')
    i18nKey: 'layout.menuItems.farm',
    icon: 'vote',
  },
  // {
  //   href: process.env.REACT_APP_RUN_V2 ? '/history' : '/transaction',
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.history')
  //   i18nKey: 'layout.menuItems.history',
  //   icon: 'history',
  // },
  // {
  //   href: '/nft',
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.vote')
  //   i18nKey: 'layout.menuItems.nft',
  //   icon: 'vote',
  // },
  {
    href: '/brain',
    // Translation key: do not remove this comment
    // t('layout.menuItems.brn')
    i18nKey: 'layout.menuItems.brn',
    icon: 'brainBlack',
  },

  {
    href: 'https://vault.brainiac.fi/',
    // Translation key: do not remove this comment
    // t('layout.menuItems.convertVrt')
    // t('layout.menuItems.convertVrtTitle')
    i18nKey: 'layout.menuItems.autoVaults',
    i18nTitleKey: 'layout.menuItems.autoVaultsTitle',
    icon: 'vault',
  },
    {
    href: 'https://crash.brainiac.finance/',
    // Translation key: do not remove this comment
    // t('layout.menuItems.vote')
    i18nKey: 'Crash',
    icon: 'brainBlack',
  },
  {
    href: 'https://liquidate.brainiac.finance/',
    // Translation key: do not remove this comment
    // t('layout.menuItems.vote')
    i18nKey: 'layout.menuItems.liquidator',
    icon: 'liquidator',
  },
  // {
  //   href: 'https://prdt.finance/BRN',
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.brnPrediction')
  //   i18nKey: 'layout.menuItems.brnPrediction',
  //   icon: 'brnPrediction',
  // },
];

if (isOnTestnet) {
  menuItems.splice(menuItems.length, 0, {
    href: '/faucet',
    // Translation key: do not remove this comment
    // t('layout.menuItems.faucet')
    i18nKey: 'layout.menuItems.faucet',
    icon: 'convert',
  });
}
