/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Paper, Typography } from '@mui/material';
import {
  useGetBrainiacBaiVaultDailyRateWei,
  useGetBalanceOf,
  useGetUserMarketInfo,
} from 'clients/api';
import { EllipseText, Icon, LabeledProgressBar } from 'components';
import { AuthContext } from 'context/AuthContext';
import copy from 'copy-to-clipboard';
import { getToken, generateBscScanUrl, getContractAddress } from 'utilities';
import { convertWeiToTokens, formatTokensToReadableValue } from 'utilities/common';
import { useTranslation } from 'translation';
import { useStyles } from '../styles';
import { MINTED_BRN_WEI } from '../constants';

interface IHeaderProps {
  className?: string;
}

interface IHeaderContainerProps {
  remainingDistributionWei: BigNumber;
  dailyBrainiacWei: BigNumber;
  brainiacBaiVaultDailyRateWei: BigNumber;
  totalBrnDistributedWei: BigNumber;
}

export const HeaderUi: React.FC<IHeaderProps & IHeaderContainerProps> = ({
  className,
  remainingDistributionWei,
  dailyBrainiacWei,
  brainiacBaiVaultDailyRateWei,
  totalBrnDistributedWei,
}) => {
  const styles = useStyles();
  const brnAddress = getToken('brn').address;
  const copyAddress = () => copy(brnAddress);
  const { t } = useTranslation();

  const readableDailyDistribution = useMemo(() => {
    const dailyBrainiacTokens = convertWeiToTokens({
      valueWei: dailyBrainiacWei,
      tokenId: 'brn',
    });

    const brainiacBaiVaultDailyRateTokens = convertWeiToTokens({
      valueWei: brainiacBaiVaultDailyRateWei,
      tokenId: 'brn',
    });
 
    const dailyDistribution = dailyBrainiacTokens.plus(brainiacBaiVaultDailyRateTokens);

    return formatTokensToReadableValue({
      value: dailyDistribution,
      tokenId: 'brn',
      minimizeDecimals: true,
    });
  }, [dailyBrainiacWei.toFixed(), brainiacBaiVaultDailyRateWei.toFixed()]);

  const readableRemainingDistribution = useMemo(
    () =>
      convertWeiToTokens({
        valueWei: remainingDistributionWei,
        tokenId: 'brn',
        returnInReadableFormat: true,
        minimizeDecimals: true,
      }),
    [remainingDistributionWei.toFixed()],
  );

  const percentOfBrnDistributed = useMemo(
    () => totalBrnDistributedWei.dividedBy(MINTED_BRN_WEI).multipliedBy(100).toNumber(),
    [],
  );

  return (
    <Paper className={className} css={styles.headerRoot}>
      <EllipseText css={styles.address} text={brnAddress}>
        <div css={styles.brnIconContainer}>
          <Icon name="brn" size={styles.iconSize} />
        </div>

        <Typography
          className="ellipse-text"
          href={generateBscScanUrl('brn')}
          target="_blank"
          rel="noreferrer"
          variant="small2"
          component="a"
          css={[styles.whiteText, styles.addressText]}
        />

        <div css={styles.copyIconContainer}>
          <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
        </div>
      </EllipseText>
{/* 
      <div css={styles.slider}>
        <LabeledProgressBar
          css={styles.progressBar}
          min={1}
          max={100}
          step={1}
          value={percentOfBrnDistributed}
          ariaLabel={t('brn.progressBar')}
          greyLeftText={t('brn.dailyDistribution')}
          whiteLeftText={readableDailyDistribution}
          greyRightText={t('brn.remaining')}
          whiteRightText={readableRemainingDistribution}
        />
      </div> */}
    </Paper>
  );
};

const Header: React.FC<IHeaderProps> = ({ className }) => {
  const { account } = useContext(AuthContext);
  const { data: brainiacBaiVaultDailyRateWei } = useGetBrainiacBaiVaultDailyRateWei();
  const {
    data: { dailyBrainiacWei, totalBrnDistributedWei },
  } = useGetUserMarketInfo({
    accountAddress: account?.address,
  });

  // const { data: brnRemainingDistribution } = useGetBalanceOf({
  //   tokenId: 'brn',
  //   accountAddress: getContractAddress('comptroller'),
  // });

  const brnRemainingDistribution = new BigNumber(0);
  return (
    <HeaderUi
      remainingDistributionWei={brnRemainingDistribution || new BigNumber(0)}
      brainiacBaiVaultDailyRateWei={brainiacBaiVaultDailyRateWei || new BigNumber(0)}
      className={className}
      dailyBrainiacWei={dailyBrainiacWei}
      totalBrnDistributedWei={totalBrnDistributedWei}
    />
  );
};

export default Header;
