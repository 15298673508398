import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';

import { Vault } from 'types';
import { BLOCKS_PER_DAY } from 'constants/bsc';
import { DAYS_PER_YEAR } from 'constants/daysPerYear';
import { getTokenByAddress } from 'utilities';
import { indexBy } from 'utilities/common';
import {
  useGetBrnVaultPoolsCount,
  useGetBrnVaultTotalAllocationPoints,
  useGetBrnVaultRewardWeiPerBlock,
  IGetBrnVaultPoolInfoOutput,
  GetBrnVaultPendingRewardWeiOutput,
  IGetBrnVaultUserInfoOutput,
} from 'clients/api';
import { BRN_TOKEN_ADDRESS, BRN_TOKEN_ID } from 'constants/brn';
import useGetBrnVaultPools from './useGetBrnVaultPools';
import useGetBrnVaultPoolBalances from './useGetBrnVaultPoolBalances';

export interface UseGetVestingVaultsOutput {
  isLoading: boolean;
  data: Vault[];
}

const useGetVestingVaults = ({
  accountAddress,
}: {
  accountAddress?: string;
}): UseGetVestingVaultsOutput => {
  const { data: brnVaultPoolsCount = 0, isLoading: isGetBrnVaultPoolsCountLoading } =
    useGetBrnVaultPoolsCount();

  // Fetch data generic to all BRN pools
  const { data: brnVaultRewardWeiPerBlock, isLoading: isGetBrnVaultRewardWeiPerBlockLoading } =
    useGetBrnVaultRewardWeiPerBlock({
      tokenAddress: BRN_TOKEN_ADDRESS,
    });

  const {
    data: brnVaultTotalAllocationPoints,
    isLoading: isGetBrnVaultTotalAllocationPointsLoading,
  } = useGetBrnVaultTotalAllocationPoints({
    tokenAddress: BRN_TOKEN_ADDRESS,
  });

  // Fetch pools
  const poolQueryResults = useGetBrnVaultPools({
    accountAddress,
    poolsCount: brnVaultPoolsCount,
  });
  const arePoolQueriesLoading = poolQueryResults.some(queryResult => queryResult.isLoading);

  // Index results by pool ID
  const [poolData, stakedTokenAddresses] = useMemo(() => {
    const data: {
      [poolIndex: string]: {
        poolInfos: IGetBrnVaultPoolInfoOutput;
        userPendingRewardWei?: GetBrnVaultPendingRewardWeiOutput;
        userInfos?: IGetBrnVaultUserInfoOutput;
      };
    } = {};

    const tokenAddresses: string[] = [];

    const queriesPerPoolCount =
      brnVaultPoolsCount > 0 ? poolQueryResults.length / brnVaultPoolsCount : 0;

    for (let poolIndex = 0; poolIndex < brnVaultPoolsCount; poolIndex++) {
      const poolQueryResultStartIndex = poolIndex * queriesPerPoolCount;

      const poolInfosQueryResult = poolQueryResults[
        poolQueryResultStartIndex
      ] as UseQueryResult<IGetBrnVaultPoolInfoOutput>;

      const userPendingRewardQueryResult = poolQueryResults[
        poolQueryResultStartIndex + 1
      ] as UseQueryResult<GetBrnVaultPendingRewardWeiOutput>;

      const userInfoQueryResult = poolQueryResults[
        poolQueryResultStartIndex + 2
      ] as UseQueryResult<IGetBrnVaultUserInfoOutput>;

      if (poolInfosQueryResult?.data) {
        tokenAddresses.push(poolInfosQueryResult.data.stakedTokenAddress);

        data[poolIndex] = {
          poolInfos: poolInfosQueryResult.data,
          userInfos: userInfoQueryResult.data,
          userPendingRewardWei: userPendingRewardQueryResult.data,
        };
      }
    }

    return [data, tokenAddresses];
  }, [JSON.stringify(poolQueryResults), brnVaultPoolsCount]);

  // Fetch pool balances
  const poolBalanceQueryResults = useGetBrnVaultPoolBalances({
    stakedTokenAddresses,
  });
  const arePoolBalanceQueriesLoading = poolBalanceQueryResults.some(
    queryResult => queryResult.isLoading,
  );

  // Index results by pool ID
  const poolBalances = useMemo(
    () =>
      indexBy(
        (_item, index) => `${index}`,
        poolBalanceQueryResults.map(poolBalanceQueryResult => poolBalanceQueryResult.data),
      ),
    [JSON.stringify(poolBalanceQueryResults)],
  );

  const isLoading =
    isGetBrnVaultPoolsCountLoading ||
    isGetBrnVaultRewardWeiPerBlockLoading ||
    isGetBrnVaultTotalAllocationPointsLoading ||
    arePoolQueriesLoading ||
    arePoolBalanceQueriesLoading;

  // Format query results into Vaults
  const data: Vault[] = useMemo(
    () =>
      Array.from({ length: brnVaultPoolsCount }).reduce<Vault[]>((acc, _item, poolIndex) => {
        const totalStakedWei = poolBalances[poolIndex];
        const lockingPeriodMs = poolData[poolIndex]?.poolInfos.lockingPeriodMs;
        const userStakedWei = poolData[poolIndex]?.userInfos?.stakedAmountWei;
        const userPendingRewardWei = poolData[poolIndex]?.userPendingRewardWei;

        const stakedTokenId =
          poolData[poolIndex]?.poolInfos?.stakedTokenAddress &&
          getTokenByAddress(poolData[poolIndex]?.poolInfos.stakedTokenAddress)?.id;

        const poolRewardWeiPerBlock =
          brnVaultRewardWeiPerBlock &&
          brnVaultTotalAllocationPoints &&
          poolData[poolIndex]?.poolInfos.allocationPoint &&
          brnVaultRewardWeiPerBlock
            .multipliedBy(poolData[poolIndex]?.poolInfos.allocationPoint)
            .div(brnVaultTotalAllocationPoints);

        const dailyEmissionWei =
          poolRewardWeiPerBlock && poolRewardWeiPerBlock.multipliedBy(BLOCKS_PER_DAY);

        const stakingAprPercentage =
          dailyEmissionWei &&
          totalStakedWei &&
          dailyEmissionWei
            .multipliedBy(DAYS_PER_YEAR)
            .div(totalStakedWei)
            .multipliedBy(100)
            .toNumber();

        if (
          stakedTokenId &&
          lockingPeriodMs &&
          dailyEmissionWei &&
          totalStakedWei &&
          stakingAprPercentage
        ) {
          const vault: Vault = {
            rewardTokenId: BRN_TOKEN_ID,
            stakedTokenId,
            lockingPeriodMs,
            dailyEmissionWei,
            totalStakedWei,
            stakingAprPercentage,
            userStakedWei,
            userPendingRewardWei,
          };

          return [...acc, vault];
        }

        return acc;
      }, []),
    [
      brnVaultPoolsCount,
      JSON.stringify(poolData),
      JSON.stringify(poolBalances),
      brnVaultRewardWeiPerBlock?.toFixed(),
      brnVaultTotalAllocationPoints,
    ],
  );

  return {
    data,
    isLoading,
  };
};

export default useGetVestingVaults;
