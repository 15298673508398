import { useQuery, QueryObserverOptions } from 'react-query';

import { BRTokenId } from 'types';
import getBRTokenCash, { GetBRTokenCashOutput } from 'clients/api/queries/getBRTokenCash';
import FunctionKey from 'constants/functionKey';
import { useBRTokenContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBRTokenCashOutput,
  Error,
  GetBRTokenCashOutput,
  GetBRTokenCashOutput,
  [FunctionKey.GET_V_TOKEN_CASH, BRTokenId]
>;

const useGetBRTokenCash = ({ brTokenId }: { brTokenId: BRTokenId }, options?: Options) => {
  const brTokenContract = useBRTokenContract(brTokenId);

  return useQuery(
    [FunctionKey.GET_V_TOKEN_CASH, brTokenId],
    () => getBRTokenCash({ brTokenContract }),
    options,
  );
};

export default useGetBRTokenCash;
