import React from 'react';

import '../../assets/styles/homeComponents/Footer.scss';

// importing images
import Twitter from '../../assets/homeImages/twitter.png';
import Discord from '../../assets/homeImages/discord.png';
import Medium from '../../assets/homeImages/medium.png';
import Telegram from '../../assets/homeImages/telegram.png';
import BrainaicLogo from '../../assets/homeImages/footer-brainiac-logo.png';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer__container">
          <div className="footer__head">
            <h2>Be a part of our awesome community</h2>

            <div className="footer__social-icon-box">
              <span className="footer__twitter-icon">
                <a href="https://twitter.com/BrainiacFinance" target="_blank">
                  <img src={Twitter} alt="" />
                  <p className="footer__icon-text">Twitter</p>
                </a>
              </span>
              <span className="footer__discord-icon">
                <a href="https://discord.gg/FcUQnEyyYc" target="_blank">
                  <img src={Discord} alt="" />
                  <p className="footer__icon-text">Discord</p>
                </a>
              </span>
            </div>
          </div>

          <div className="footer__bottom-box">
            <div className="footer__bottom-inner-box">
              <div className="footer__brainiac-logo-box">
                <img src={BrainaicLogo} alt="" />
                <div className="footer__logo-bottom-social-icons">
                  <a href="https://twitter.com/BrainiacFinance" target="_blank">
                    {' '}
                    <img src={Twitter} alt="" />
                  </a>
                  <a href="https://discord.gg/FcUQnEyyYc" target="_blank">
                    <img src={Discord} alt="" />
                  </a>
                  <a href="https://medium.com/@brainiacfi" target="_blank">
                    {' '}
                    <img src={Medium} alt="" />
                  </a>
                </div>
              </div>

              <div className="footer__quick-links-box">
                <div className="footer__quick-links-inner-box">
                  <div className="footer__quick-link-contact footer__quick-link-contact--each-quick-links">
                    <ul>
                      <h4>Community</h4>
                      <a href="http://discord.gg/FcUQnEyyYc" target="_blank">
                        <li>Discord</li>
                      </a>
                      <a href="https://twitter.com/BrainiacFinance" target="_blank">
                        <li>Twitter</li>
                      </a>
                    </ul>
                  </div>
                  <div className="footer__quick-link-learn footer__quick-link-contact--each-quick-links">
                    <ul>
                      <h4>Learn</h4>
                      <a href="https://medium.com/@brainiacfi" target="_blank">
                        <li>Medium</li>
                      </a>
                      <a href="https://brainiacfinance.gitbook.io/" target="_blank">
                        <li>Docs</li>
                      </a>
                      <a href="https://vault.brainiac.fi/" target=" _blank">
                        <li>Auto Vaults</li>
                      </a>
                      <a href="https://github.com/brainiacfi/" target="_blank">
                        <li>Github</li>
                      </a>
                    </ul>
                  </div>
                  {/* <div className="footer__quick-link-resource footer__quick-link-contact--each-quick-links">
                                        <ul>
                                            <h4>Resources</h4>
                                            <a href=""><li>CoinGecko</li></a>
                                            <a href=""><li>CoinMarketCap</li></a>
                                        </ul>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="footer__sign-up-container">
                        <div className="footer__sign-up-box">
                            <h3>Sign up and never miss out</h3>
                            <h4>Get the latest updates from <strong>Brainiac</strong> in your inbox</h4>
                            <span className='footer__input-form'><input className='footer__input' type="text" placeholder='Your email address' /><button className='footer__submit-button'>Submit</button></span>
                        </div>
                    </div> */}

          <div className="footer__legal-disclaimer">
            <h3>LEGAL DISCLAIMER</h3>
            <p>
              The information provided on this website does not constitute investment advice,
              financial advice, trading advice, or any other sort of advice, and you should not
              treat any of the website’s content as such. The Brainiac team provides the website as
              a service to the public and is not responsible for and expressly disclaims all
              liability for damages of any kind arising out of use, reference to or reliance on any
              information contained within this website. While the information contained within this
              website is periodically updated, no guarantee is given that the information provided
              in this website is correct, complete, and up-to-date.
            </p>

            <h3 style={{ marginTop: 8 }}>Contact</h3>
            <a href="mailto:brainiacfi@gmail.com">brainiacfi@gmail.com</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
