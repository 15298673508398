import { useContext, useMemo } from 'react';
import { BigNumber } from 'bignumber.js';
import { useGetDailyBrnWei, useGetMarkets } from 'clients/api';
import { convertWeiToTokens } from 'utilities';
import { BRN_TOKEN_ID } from 'constants/brn';
import { AuthContext } from 'context/AuthContext';

export const useDailyBrnWei = () => {
  const { account } = useContext(AuthContext);
  const { data: dailyBrnWei, isLoading: isGetDailyBrnLoading } = useGetDailyBrnWei(
    { accountAddress: account?.address || '' },
    { enabled: !!account?.address },
  );

  const { data: getMarketsData, isLoading: isGetMarketsLoading } = useGetMarkets();
  const brnPriceDollars: BigNumber | undefined = useMemo(
    () => (getMarketsData?.markets || []).find(market => market.id === BRN_TOKEN_ID)?.tokenPrice,
    [JSON.stringify(getMarketsData?.markets)],
  );

  const { dailyBrnDistributionInterestsCents } = useMemo(() => {
    const dailyBrnTokens =
      dailyBrnWei &&
      convertWeiToTokens({
        valueWei: dailyBrnWei,
        tokenId: BRN_TOKEN_ID,
      });

    return {
      dailyBrnDistributionInterestsCents:
        account?.address && brnPriceDollars
          ? dailyBrnTokens?.multipliedBy(brnPriceDollars).times(100)
          : new BigNumber(0),
    };
  }, [JSON.stringify(dailyBrnWei), JSON.stringify(getMarketsData?.markets), account?.address]);

  return {
    isLoading: isGetDailyBrnLoading || isGetMarketsLoading,
    dailyBrnDistributionInterestsCents,
  };
};
