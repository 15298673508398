import { useMemo } from 'react';

import { TokenId, BRTokenId } from 'types';
import { useWeb3 } from 'clients/web3';
import {
  getComptrollerContract,
  getInterestModelContract,
  getPriceOracleContract,
  getTokenContract,
  getBaiUnitrollerContract,
  getBaiVaultContract,
  getBRTokenContract,
  getBrainiacLensContract,
  getBrnVaultProxyContract,
  getBrnVaultContract,
  getTokenContractByAddress,
  getGovernorBravoDelegateContract,
  getVrtConverterProxyContract,
  getBrnVestingProxyContract,
  getVrtVaultProxyContract,
  getFaucetContract,
  getBrainVaultContract,
  getBrainiacNFTContract,
  getBRNCKBPoolContract
} from './getters';

export const useTokenContract = <T extends TokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract<T>(name, web3), [web3, name]);
};

export const useTokenContractByAddress = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContractByAddress(address, web3), [web3, address]);
};

export const useBRTokenContract = <T extends BRTokenId>(name: T) => {
  const web3 = useWeb3();
  return useMemo(() => getBRTokenContract<T>(name, web3), [web3, name]);
};

export const useBaiUnitrollerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBaiUnitrollerContract(web3), [web3]);
};

export const useBaiVaultContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBaiVaultContract(web3), [web3]);
};

export const useBrainVaultContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getBrainVaultContract(address,web3), [web3]);
};

export const useBRNCKBPoolContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getBRNCKBPoolContract(address,web3), [web3]);
};

export const useComptrollerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getComptrollerContract(web3), [web3]);
};

export const usePriceOracleContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getPriceOracleContract(web3), [web3]);
};

export const useFaucetContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getFaucetContract(web3), [web3]);
};

export const useInterestModelContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getInterestModelContract(address, web3), [web3]);
};

export const useBrainiacLensContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBrainiacLensContract(web3), [web3]);
};

export const useBrainiacNFTContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBrainiacNFTContract(web3), [web3]);
};

export const useBrnVaultContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBrnVaultContract(web3), [web3]);
};

export const useBrnVaultProxyContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBrnVaultProxyContract(web3), [web3]);
};

export const useGovernorBravoDelegateContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getGovernorBravoDelegateContract(web3), [web3]);
};

// VRT conversion
export const useVrtConverterProxyContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getVrtConverterProxyContract(web3), [web3]);
};

export const useBrnVestingProxyContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getBrnVestingProxyContract(web3), [web3]);
};

export const useVrtVaultProxyContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getVrtVaultProxyContract(web3), [web3]);
};
