import * as React from 'react';
import { SVGProps } from 'react';

const SvgBai = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
      fill="#85B865"
    />
    <path
      d="M17.103 12.557H6.897a.62.62 0 0 0-.617.617.62.62 0 0 0 .617.617h2.564L11.2 18.38a.62.62 0 0 0 .609.397.62.62 0 0 0 .609-.397l1.737-4.589h2.95a.62.62 0 0 0 .617-.617.62.62 0 0 0-.618-.617ZM11.807 16.5l-1.026-2.71h2.053l-1.027 2.71Zm-4.91-5.097h10.206a.619.619 0 0 0 .436-1.053.62.62 0 0 0-.436-.181h-1.578l1.273-3.36a.62.62 0 0 0-.359-.796.62.62 0 0 0-.796.358l-1.438 3.798H9.41L7.972 6.372a.62.62 0 0 0-.796-.358.62.62 0 0 0-.359.795l1.273 3.36H6.897a.62.62 0 0 0-.617.618.619.619 0 0 0 .617.617Z"
      fill="#fff"
    />
  </svg>
);

export default SvgBai;
