import React, { useContext, useEffect, useState } from 'react';

import BigNumber from 'bignumber.js';
import { getContractAddress } from 'utilities';
import {
  useComptrollerContract,
  useTokenContract,
  useBaiUnitrollerContract,
} from 'clients/contracts/hooks';
import useRefresh from 'hooks/useRefresh';
import { AuthContext } from './AuthContext';

export interface IBaiContextValue {
  userBaiMinted: BigNumber;
  userBaiBalance: BigNumber;
  userBaiEnabled: boolean;
  mintableBai: BigNumber;
}

const BaiContext = React.createContext({
  userBaiMinted: new BigNumber(0),
  userBaiBalance: new BigNumber(0),
  userBaiEnabled: false,
  mintableBai: new BigNumber(0),
});

// This context provide a way for all the components to share the market data, thus avoid
// duplicated requests

const BaiContextProvider = ({ children }: $TSFixMe) => {
  const [userBaiMinted, setMintedAmount] = useState(new BigNumber(0));
  const [userBaiBalance, setWalletAmount] = useState(new BigNumber(0));
  const [userBaiEnabled, setEnabled] = useState(false);
  const [mintableBai, setMintableAmount] = useState(new BigNumber(0));

  const { fastRefresh } = useRefresh();
  const comptrollerContract = useComptrollerContract();
  const baiControllerContract = useBaiUnitrollerContract();
  const baiContract = useTokenContract('bai');
  const { account } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;
    const update = async () => {
      if (!account) {
        return;
      }

      const [userBaiBalanceTemp, userBaiMintedTemp,  mintableBaiTemp , allowBalanceTemp] = [0,0,0,0]
        // await Promise.all([
        //   // baiContract.methods.balanceOf(account.address).call(),
        //   // comptrollerContract.methods.mintedBAIs(account.address).call(),
        //   // baiControllerContract.methods.getMintableBAI(account.address).call(),
        //   // baiContract.methods
        //   //   .allowance(account.address, getContractAddress('baiUnitroller'))
        //   //   .call(),
        // ]);
      if (!isMounted) {
        return;
      }
      
      const formattedAllowBalanceTemp = new BigNumber(allowBalanceTemp);
      setMintedAmount(new BigNumber(userBaiMintedTemp).div(1e18));
      setWalletAmount(new BigNumber(userBaiBalanceTemp).div(1e18));
      setEnabled(
        formattedAllowBalanceTemp.gt(0) &&
        formattedAllowBalanceTemp.gte(new BigNumber(userBaiMintedTemp)),
      );
      setMintableAmount(new BigNumber(mintableBaiTemp).div(1e18));
    };
    update();
    
    return () => {
      isMounted = false;
    };
  }, [fastRefresh, baiControllerContract, baiContract, comptrollerContract, account]);

  return (
    <BaiContext.Provider
      value={{
        userBaiMinted,
        userBaiBalance,
        userBaiEnabled,
        mintableBai,
      }}
    >
      {children}
    </BaiContext.Provider>
  );
};

export { BaiContext, BaiContextProvider };
