import BigNumber from 'bignumber.js';

import { BrnVault } from 'types/contracts';

export interface IGetBrnVaultRewardWeiPerBlockInput {
  brnVaultContract: BrnVault;
  tokenAddress: string;
}

export type GetBrnVaultRewardWeiPerBlockOutput = BigNumber;

const getBrnVaultRewardWeiPerBlock = async ({
  brnVaultContract,
  tokenAddress,
}: IGetBrnVaultRewardWeiPerBlockInput): Promise<GetBrnVaultRewardWeiPerBlockOutput> => {
  const res = await brnVaultContract.methods.rewardTokenAmountsPerBlock(tokenAddress).call();
  return new BigNumber(res);
};

export default getBrnVaultRewardWeiPerBlock;
