import type { TransactionReceipt } from 'web3-core/types';

import { BRERC_TOKENS } from 'constants/tokens';
import { Comptroller } from 'types/contracts';
import { checkForComptrollerTransactionError } from 'errors';

export interface IClaimBrnRewardInput {
  comptrollerContract: Comptroller;
  fromAccountAddress: string;
}

export type ClaimBrnRewardOutput = TransactionReceipt;

const claimBrnReward = async ({
  comptrollerContract,
  fromAccountAddress,
}: IClaimBrnRewardInput): Promise<ClaimBrnRewardOutput> => {
  // Fetch list of tokens for which user have a positive balance, since these
  // are the tokens susceptible to have generated BRN rewards
  const brTokens = Object.values(BRERC_TOKENS)
  .filter(brToken => brToken.address !== '' && brToken.id !== 'brn')
  .map(brToken => brToken.address);
  // @TODO [VEN-198] - use brainiac lens to fetch rewards by addresses once it is upgraded with this functionality
  // Send query to claim BRN reward
  let resp
  for (let brToken of brTokens) {
    resp = await comptrollerContract.methods['claimBrainiac(address,address[])'](
      fromAccountAddress,
      [brToken],
    ).send({
      from: fromAccountAddress,
    });
  }
  return checkForComptrollerTransactionError(resp);
};

export default claimBrnReward;
