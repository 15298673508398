import { IGetBrnVaultPoolInfoInput, IGetBrnVaultPoolInfoOutput } from './types';
import formatToPoolInfo from './formatToPoolInfo';

export * from './types';

const getBrnVaultPoolInfo = async ({
  brnVaultContract,
  tokenAddress,
  poolIndex,
}: IGetBrnVaultPoolInfoInput): Promise<IGetBrnVaultPoolInfoOutput> => {
  const res = await brnVaultContract.methods.poolInfos(tokenAddress, poolIndex).call();
  return formatToPoolInfo(res);
};

export default getBrnVaultPoolInfo;
