import { useQuery, QueryObserverOptions } from 'react-query';
import {
  getBaiVaultUserInfo,
  IGetBaiVaultUserInfoInput,
  IGetBaiVaultUserInfoOutput,
} from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useBaiVaultContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  IGetBaiVaultUserInfoOutput,
  Error,
  IGetBaiVaultUserInfoOutput,
  IGetBaiVaultUserInfoOutput,
  [FunctionKey.GET_BAI_VAULT_USER_INFO, string]
>;

const useGetBaiVaultUserInfo = (
  { accountAddress }: Omit<IGetBaiVaultUserInfoInput, 'baiVaultContract'>,
  options?: Options,
) => {
  const baiVaultContract = useBaiVaultContract();

  return useQuery(
    [FunctionKey.GET_BAI_VAULT_USER_INFO, accountAddress],
    () => getBaiVaultUserInfo({ baiVaultContract, accountAddress }),
    options,
  );
};

export default useGetBaiVaultUserInfo;
