import React from 'react';
import BigNumber from 'bignumber.js';

import { IBRErcToken } from 'types';
import { getToken } from 'utilities';
import { convertWeiToTokens } from 'utilities/common';
import { VTOKEN_DECIMALS } from 'config';
import { useGetMarkets, useGetBRTokenCash } from 'clients/api';

// Percentages returned by smart contracts use 18 decimals
const SMART_CONTRACT_PERCENTAGE_DECIMALS = 18;

const useGetMarketData = ({
  brTokenId,
  brTokenAddress,
}: {
  brTokenId: IBRErcToken['id'];
  brTokenAddress: IBRErcToken['address'];
}) => {
  const { data: brTokenCashWei } = useGetBRTokenCash({
    brTokenId,
  });

  const { data: getMarketData } = useGetMarkets();
  const assetMarket = (getMarketData?.markets || []).find(
    market => market.address.toLowerCase() === brTokenAddress.toLowerCase(),
  );


  return React.useMemo(() => {
    const totalBorrowBalanceCents = assetMarket && +assetMarket.totalBorrowsUsd * 100;
    const totalSupplyBalanceCents = assetMarket && +assetMarket.totalSupplyUsd * 100;
    const borrowApyPercentage = assetMarket?.borrowApy;
    const supplyApyPercentage = assetMarket?.supplyApy;
    const borrowDistributionApyPercentage = assetMarket && +assetMarket.borrowBrainiacApy;
    const supplyDistributionApyPercentage = assetMarket && +assetMarket.supplyBrainiacApy;
    const tokenPriceDollars = assetMarket && assetMarket.tokenPrice.toFixed(4);
    const liquidityCents = assetMarket && new BigNumber(assetMarket.liquidity).multipliedBy(100);
    const supplierCount = assetMarket?.supplierCount;
    const borrowerCount = assetMarket?.borrowerCount;
    const borrowCapCents = assetMarket && +assetMarket.borrowCaps * +assetMarket.tokenPrice * 100;
    const mintedTokens = assetMarket && new BigNumber(assetMarket.totalSupply2);
    const reserveFactorMantissa = assetMarket && new BigNumber(assetMarket.reserveFactor);

    const dailyInterestsCents =
      assetMarket &&
      convertWeiToTokens({
        valueWei: new BigNumber(assetMarket.supplierDailyBrainiac).plus(
          new BigNumber(assetMarket.borrowerDailyBrainiac),
        ),
        tokenId: 'brn',
      })
        // Convert BRN to dollars
        .multipliedBy(assetMarket.tokenPrice)
        // Convert to cents
        .multipliedBy(100)
        .toNumber();

    const reserveFactor =
      assetMarket &&
      new BigNumber(assetMarket.reserveFactor)
        .dividedBy(new BigNumber(10).pow(SMART_CONTRACT_PERCENTAGE_DECIMALS))
        // Convert to percentage
        .multipliedBy(100)
        .toNumber();

    const collateralFactor =
      assetMarket &&
      new BigNumber(assetMarket.collateralFactor)
        .dividedBy(new BigNumber(10).pow(SMART_CONTRACT_PERCENTAGE_DECIMALS))
        // Convert to percentage
        .multipliedBy(100)
        .toNumber();

    const reserveTokens =
      assetMarket &&
      convertWeiToTokens({
        valueWei: new BigNumber(assetMarket.totalReserves),
        tokenId: brTokenId,
      });

    const exchangeRateBRTokens =
      assetMarket &&
      new BigNumber(1).div(
        new BigNumber(assetMarket.exchangeRate).div(
          new BigNumber(10).pow(18 + getToken(brTokenId).decimals - VTOKEN_DECIMALS),
        ),
      );

    let currentUtilizationRate: number | undefined;
    if (brTokenCashWei && assetMarket && reserveTokens) {
      const brTokenCashTokens = convertWeiToTokens({
        valueWei: brTokenCashWei,
        tokenId: brTokenId,
      });

      currentUtilizationRate = new BigNumber(assetMarket.totalBorrows2)
        .div(brTokenCashTokens.plus(assetMarket.totalBorrows2).minus(reserveTokens))
        .multipliedBy(100)
        .dp(0)
        .toNumber();
    }

    return {
      totalBorrowBalanceCents,
      totalSupplyBalanceCents,
      borrowApyPercentage,
      supplyApyPercentage,
      borrowDistributionApyPercentage,
      supplyDistributionApyPercentage,
      tokenPriceDollars,
      liquidityCents,
      supplierCount,
      borrowerCount,
      borrowCapCents,
      mintedTokens,
      dailyInterestsCents,
      reserveFactor,
      collateralFactor,
      reserveTokens,
      exchangeRateBRTokens,
      currentUtilizationRate,
      reserveFactorMantissa,
    };
  }, [JSON.stringify(assetMarket), brTokenCashWei?.toFixed()]);
};

export default useGetMarketData;
