export interface IGetBRTokenBalancesAllInput {
  brainiacLensContract: $TSFixMe; // @TODO: use contract type (through Typechain?)
  account: string;
  brTokenAddresses: string[];
}

interface IGetBRTokenBalancesAllResponse extends Array<string> {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  brToken: string;
}

interface IGetBRTokenBalanceOutput {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  brToken: string;
}

export type IGetBRTokenBalancesAllOutput = IGetBRTokenBalanceOutput[];

const getBRTokenBalancesAll = async ({
  brainiacLensContract,
  brTokenAddresses,
  account,
}: IGetBRTokenBalancesAllInput): Promise<IGetBRTokenBalancesAllOutput> => {
  let response = await brainiacLensContract.methods
    .brTokenBalancesAll(brTokenAddresses, account?.toLowerCase())
    .call();

  // This is original returned as an array with these properties
  // but at some point the properties are getting lost
  response = response.map((item: IGetBRTokenBalancesAllResponse) => ({
    balanceOf: item.balanceOf,
    balanceOfUnderlying: item.balanceOfUnderlying,
    borrowBalanceCurrent: item.borrowBalanceCurrent,
    tokenAllowance: item.tokenAllowance,
    tokenBalance: item.tokenBalance,
    brToken: item.brToken,
  }));

  return response;
};

export default getBRTokenBalancesAll;
