import React from 'react';

import { Link } from 'react-router-dom';

import '../../assets/styles/homeComponents/Vote.scss';

const Vote = () => {
  return (
    <>
      <div className="vote">
        <div className="vote__container">
          <div className="vote__head">
            <div className="vote__heading">
              <h2>Vote</h2>
            </div>
          </div>

          <div className="vote__details-container">
            <div className="vote__token-distribution-container">
              <div className="vote__token-distribution-card-container">
                <div className="vote__token-distribution-card">
                  <div className="vote__token-distribution-head">
                    <h3>Adjust Token Distribution</h3>
                    <p>View details</p>
                  </div>

                  <div className="vote__token-distribution-vote-count-container">
                    <div className="vote__token-distribution-yes-count">
                      <h3>Yes</h3>
                      <h5>3189857 VOTES</h5>
                    </div>
                    <div className="vote__token-distribution-no-count">
                      <h3>No</h3>
                      <h5>881583 VOTES</h5>
                    </div>
                  </div>

                  <div className="vote__token-distribution-vote-button">
                    <Link to="/vote">
                      <button>VOTE</button>
                    </Link>
                  </div>
                </div>
                <div className="vote__card-cyan-square"></div>
                <div className="vote__card-black-square"></div>
                <div className="vote__card-vote-display-square">
                  <p>1</p>
                  <p>Vote</p>
                </div>
                <div className="vote__card-purple-square"></div>
                <div className="vote__purple-blur-spot"></div>
                <div className="vote__purple-blur-square"></div>
                <div className="vote__cyan-blur-spot"></div>
              </div>

              <div className="vote__info-container">
                <h2>Decentralised and Community Run</h2>
                <p>
                  Brainiac is a community project where users are able to vote on the development
                  decisions of the platform. <br /> <br />
                  This Decentralised Autonomous Organisation [DAO] structure puts the governance
                  firmly in the hands of the user.
                </p>
                <a
                  href="https://brainiacfinance.gitbook.io/brainiac-finance/governance/farming-brain-tokens"
                  target="_blank"
                >
                  <button>Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="launch-info-container">
          *Brainiac Finance smart contracts are not live at the moment and plan to be deployed in
          the next few weeks.
        </div>
      </div>
    </>
  );
};

export default Vote;
