/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useState } from 'react';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { getToken, getBRErcToken } from 'utilities';
import { BRTokenId, TokenId } from 'types';
import { useTranslation } from 'translation';
import {
  formatCentsToReadableValue,
  formatToReadablePercentage,
  formatTokensToReadableValue,
  formatCommaThousandsPeriodDecimal,
  shortenTokensWithSuffix,
  addToken,
} from 'utilities/common';
// import { ApyChart, IApyChartProps, InterestRateChart, IInterestRateChartProps, Icon, Token } from 'components';
import { ReactComponent as MetaMaskLogo } from 'assets/img/v2/wallets/metaMaskLogo.svg';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import Path from 'constants/path';
import { useGetBRTokenApySimulations } from 'clients/api';
import MarketInfo, { IMarketInfoProps } from './MarketInfo';
import useGetChartData from './useGetChartData';
import useGetMarketData from './useGetMarketData';
import { useStyles } from './styles';
import {
  Stack,
  Paper,
  Typography,
  Button,
  Box,
  MenuItem,
  Select,
  styled,
  IconButton,
} from '@mui/material';
import { IoIosAddCircle } from 'react-icons/io';
import { uid } from 'react-uid';
import { BRERC_TOKENS } from 'constants/tokens';
import { Icon } from 'components';
import { string } from 'yup';
export interface IMarketDetailsUiProps {
  brTokenId: BRTokenId;
  // supplyChartData: IApyChartProps['data'];
  // borrowChartData: IApyChartProps['data'];
  // interestRateChartData: IInterestRateChartProps['data'];
  totalBorrowBalanceCents?: number;
  totalSupplyBalanceCents?: number;
  borrowApyPercentage?: BigNumber;
  supplyApyPercentage?: BigNumber;
  borrowDistributionApyPercentage?: number;
  supplyDistributionApyPercentage?: number;
  tokenPriceDollars?: string;
  liquidityCents?: BigNumber;
  supplierCount?: number;
  borrowerCount?: number;
  borrowCapCents?: number;
  dailyInterestsCents?: number;
  reserveFactor?: number;
  collateralFactor?: number;
  mintedTokens?: BigNumber;
  reserveTokens?: BigNumber;
  exchangeRateBRTokens?: BigNumber;
  currentUtilizationRate?: number;
}
// const { Option } = Select;

export const MarketDetailsUi: React.FC<IMarketDetailsUiProps> = ({
  brTokenId,
  tokenPriceDollars,
  liquidityCents,
  supplierCount,
  borrowerCount,
  borrowCapCents,
  dailyInterestsCents,
  reserveTokens,
  reserveFactor,
  collateralFactor,
  mintedTokens,
  exchangeRateBRTokens,
  // supplyChartData,
  // borrowChartData,
  // interestRateChartData,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const token = getToken(brTokenId);
  const brToken = getBRErcToken(brTokenId);

  const tok = new BigNumber(tokenPriceDollars)
  const liq = new BigNumber(liquidityCents)

  const marketInfoStats: IMarketInfoProps['stats'] = React.useMemo(
    () => [
      {
        label: t('marketDetails.marketInfo.stats.priceLabel'),
        value:
          tokenPriceDollars === undefined
            ? PLACEHOLDER_KEY
            : `$${shortenTokensWithSuffix(tok)}`,
      },
      {
        label: t('marketDetails.marketInfo.stats.marketLiquidityLabel'),
        value:`$${shortenTokensWithSuffix(liq)}`,
      },
      // {
      //   label: t('marketDetails.marketInfo.stats.supplierCountLabel'),
      //   value: supplierCount ?? '-',
      // },
      // {
      //   label: t('marketDetails.marketInfo.stats.borrowerCountLabel'),
      //   value: borrowerCount ?? '-',
      // },
      {
        label: t('marketDetails.marketInfo.stats.borrowCapLabel'),
        value:
          borrowCapCents === 0
            ? t('marketDetails.marketInfo.stats.unlimitedBorrowCap')
            : formatCentsToReadableValue({
                value: borrowCapCents,
              }),
      },
      {
        label: t('marketDetails.marketInfo.stats.dailyInterestsLabel'),
        value: formatCentsToReadableValue({
          value: dailyInterestsCents,
        }),
      },
      {
        label: t('marketDetails.marketInfo.stats.reserveTokensLabel'),
        value: formatTokensToReadableValue({
          value: reserveTokens,
          minimizeDecimals: true,
          tokenId: brTokenId,
        }),
      },
      {
        label: t('marketDetails.marketInfo.stats.reserveFactorLabel'),
        value: formatToReadablePercentage(reserveFactor),
      },
      {
        label: t('marketDetails.marketInfo.stats.collateralFactorLabel'),
        value: formatToReadablePercentage(collateralFactor),
      },
      {
        label: t('marketDetails.marketInfo.stats.mintedTokensLabel', {
          brTokenSymbol: brToken.symbol,
        }),
        value: formatTokensToReadableValue({
          value: mintedTokens,
          minimizeDecimals: true,
          addSymbol: false,
          tokenId: brTokenId,
        }),
      },
      {
        label: t('marketDetails.marketInfo.stats.exchangeRateLabel'),
        value: exchangeRateBRTokens
          ? t('marketDetails.marketInfo.stats.exchangeRateValue', {
              tokenSymbol: token.symbol,
              brTokenSymbol: brToken.symbol,
              rate: exchangeRateBRTokens.dp(6).toFixed(),
            })
          : PLACEHOLDER_KEY,
      },
    ],
    [
      tokenPriceDollars,
      liquidityCents?.toFixed(),
      // supplierCount,
      // borrowerCount,
      borrowCapCents?.toFixed(),
      dailyInterestsCents?.toFixed(),
      reserveTokens?.toFixed(),
      brTokenId,
      reserveFactor?.toFixed(),
      collateralFactor?.toFixed(),
      mintedTokens?.toFixed(),
      exchangeRateBRTokens?.toFixed(),
    ],
  );

  // if (!interestRate!supplyChartData.length || !borrowChartData.length || ChartData.length) {
  //   return <LoadingSpinner />;
  // }

  // @TODO: handle fetching errors

  return <MarketInfo stats={marketInfoStats} testId="market-details-market-info" />;
};

export type MarketDetailsProps = RouteComponentProps<{ brTokenId: BRTokenId }>;

const MarketDetails: React.FC<MarketDetailsProps> = ({
  match: {
    params: { brTokenId },
  },
}) => {
  const brToken = getBRErcToken(brTokenId);

  // Redirect to market page if brTokenId passed through route params is invalid
  if (!brToken) {
    return <Redirect to={Path.MARKET} />;
  }

  const { reserveFactorMantissa, ...marketData } = useGetMarketData({
    brTokenId,
    brTokenAddress: brToken.address,
  });
  const styles = useStyles();

   

  return (
    <div css={styles.container}>
      <MarketDetailsUi
        brTokenId={brTokenId}
        {...marketData}
        // {...chartData}
        // interestRateChartData={interestRateChartData}
      />
    </div>
  );
};

const MarketSelection: React.FC = () => {
  const styles = useStyles();
  const [currentAsset, setCurrentAsset] = useState<BRTokenId>('pckb');
  const token = getToken(currentAsset);
  const brToken = getBRErcToken(currentAsset);

  const handleChangeAsset = event => {
    setCurrentAsset(event.target.value);
  };

  return (
    <Paper css={styles.container}>
      <Box css={styles.selectContainer}>
          <Select
            defaultValue="pckb"
            onChange={handleChangeAsset}
            size="small"
          >
            {Object.keys(BRERC_TOKENS).map(key => (
              <MenuItem
                value={getBRErcToken(key as BRTokenId).id}
                key={uid(key)}
                css={styles.tokenSelectionOption}
              >
                <Button
                  variant="text"
                  startIcon={<Icon name={key as BRTokenId} css={styles.tokenSelectionIcon} />}
                >
                  <Typography variant="h4">
                    {getToken(key as TokenId).symbol}
                  </Typography>
                </Button>
              </MenuItem>
            ))}
          </Select>

        <Box css={styles.addIconsContainer}>
          <Button
            variant="text"
            css={styles.addTokenButton}
            endIcon={<IoIosAddCircle css={styles.addTokenSign} />}
            onClick={() =>
              addToken({
                asset: currentAsset,
                decimal: 18,
                type: 'token',
              })
            }
          >
            <Typography variant="button">
              {token.symbol}
            </Typography>
          </Button>
          {/* Token Add Symbol */}

          <Button
            variant="text"
            css={styles.addTokenButton}
            endIcon={<IoIosAddCircle css={styles.addTokenSign} />}
            onClick={() =>
              addToken({
                asset: currentAsset,
                decimal: 8,
                type: 'brtoken',
              })
            }
          >
            <Typography variant="button">
              {brToken.symbol}
            </Typography>
          </Button>
          {/* BRToken Add Symbol */}

        </Box>
      </Box>

      <MarketDetails
        history={{}}
        location="/"
        match={{
          params: {
            brTokenId: currentAsset,
          },
          isExact: true,
          path: '/:brTokenId',
          url: '',
        }}
      />
    </Paper>
  );
};

export default MarketSelection;
