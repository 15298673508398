import { useQuery, QueryObserverOptions } from 'react-query';
import getBrnVaultRewardWeiPerBlock, {
  IGetBrnVaultRewardWeiPerBlockInput,
  GetBrnVaultRewardWeiPerBlockOutput,
} from 'clients/api/queries/getBrnVaultRewardWeiPerBlock';
import FunctionKey from 'constants/functionKey';
import { useBrnVaultProxyContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBrnVaultRewardWeiPerBlockOutput,
  Error,
  GetBrnVaultRewardWeiPerBlockOutput,
  GetBrnVaultRewardWeiPerBlockOutput,
  [FunctionKey.GET_BRN_VAULT_REWARD_WEI_PER_BLOCK, string]
>;

const useGetBrnVaultRewardWeiPerBlock = (
  { tokenAddress }: Omit<IGetBrnVaultRewardWeiPerBlockInput, 'brnVaultContract'>,
  options?: Options,
) => {
  const brnVaultContract = useBrnVaultProxyContract();

  return useQuery(
    [FunctionKey.GET_BRN_VAULT_REWARD_WEI_PER_BLOCK, tokenAddress],
    () => getBrnVaultRewardWeiPerBlock({ tokenAddress, brnVaultContract }),
    options,
  );
};

export default useGetBrnVaultRewardWeiPerBlock;
