import { useQuery, QueryObserverOptions } from 'react-query';

import getDailyBrnWei, {
  IGetDailyBrnWeiInput,
  IGetDailyBrnWeiOutput,
} from 'clients/api/queries/getDailyBrnWei';
import { useBrainiacLensContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  IGetDailyBrnWeiOutput,
  Error,
  IGetDailyBrnWeiOutput,
  IGetDailyBrnWeiOutput,
  [FunctionKey.GET_V_TOKEN_DAILY_BRN_WEI, string]
>;

const useGetDailyBrnWei = (
  { accountAddress }: Omit<IGetDailyBrnWeiInput, 'brainiacLensContract'>,
  options?: Options,
) => {
  const brainiacLensContract = useBrainiacLensContract();
  return useQuery(
    [FunctionKey.GET_V_TOKEN_DAILY_BRN_WEI, accountAddress],
    () => getDailyBrnWei({ accountAddress, brainiacLensContract }),
    options,
  );
};
export default useGetDailyBrnWei;
