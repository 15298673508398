import BigNumber from 'bignumber.js';
import { BrErc20, VBnbToken } from 'types/contracts';

export interface IGetBRTokenCashInput {
  brTokenContract: BrErc20 | VBnbToken;
}

export type GetBRTokenCashOutput = BigNumber;

const getBRTokenCash = async ({
  brTokenContract,
}: IGetBRTokenCashInput): Promise<GetBRTokenCashOutput> => {
  const res = await brTokenContract.methods.getCash().call();
  return new BigNumber(res);
};

export default getBRTokenCash;
