//@ts-nocheck
import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { useBaiUser } from 'hooks/useBaiUser';
import { Asset, Market } from 'types';
import { indexBy, convertTokensToWei  } from 'utilities/common';
import { calculateCollateralValue, getBRErcToken, getToken } from 'utilities';
import { BRERC_TOKENS, TOKENS } from 'constants/tokens';
import {
  useGetMarkets,
  useGetAssetsInAccount,
  useGetBRTokenBalancesAll,
  IGetBRTokenBalancesAllOutput,
} from 'clients/api';

export interface IData {
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  totalBrnDistributedWei: BigNumber;
  dailyBrainiacWei: BigNumber;
}

export interface UseGetUserMarketInfoOutput {
  isLoading: boolean;
  data: IData;
}

const brTokenAddresses: string[] = Object.values(BRERC_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [],
);

// TODO: decouple, this hook handles too many things (see https://app.clickup.com/t/2d4rfx6)
const useGetUserMarketInfo = ({
  accountAddress,
}: {
  accountAddress?: string;
}): UseGetUserMarketInfoOutput => {
  const { userBaiMinted } = useBaiUser();

  const {
    data: getMarketsData = {
      markets: [],
      dailyBrainiacWei: new BigNumber(0),
    },
    isLoading: isGetMarketsLoading,
  } = useGetMarkets({
    placeholderData: {
      markets: [],
      dailyBrainiacWei: new BigNumber(0),
    },
  });

  const marketsMap = useMemo(
    () =>
      indexBy(
        (item: Market) => item.underlyingSymbol.toLowerCase(), // index by symbol of underlying token
        getMarketsData.markets,
      ),
    [getMarketsData?.markets],
  );

  const { data: assetsInAccount = [], isLoading: isGetAssetsInAccountLoading } =
    useGetAssetsInAccount(
      { account: accountAddress || '' },
      { enabled: !!accountAddress, placeholderData: [] },
    );

  const { data: brTokenBalancesAccount = [], isLoading: isGetBRTokenBalancesAccountLoading } =
    useGetBRTokenBalancesAll(
      { account: accountAddress || '', brTokenAddresses },
      { enabled: !!accountAddress, placeholderData: [] },
    );

  const brTokenBalances = useMemo(
    () =>
      indexBy(
        (item: IGetBRTokenBalancesAllOutput[number]) => item.brToken.toLowerCase(), // index by brToken address
        brTokenBalancesAccount,
      ),
    [JSON.stringify(brTokenBalancesAccount)],
  );

  const isLoading =
    isGetMarketsLoading || isGetAssetsInAccountLoading || isGetBRTokenBalancesAccountLoading;

  const data = useMemo(() => {
    const {
      assets,
      userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
      totalBrnDistributedWei,
    } = Object.values(TOKENS).reduce(
      (acc, item, index) => {
        const { assets: assetAcc } = acc;

        const toDecimalAmount = (mantissa: string) =>
          new BigNumber(mantissa).shiftedBy(-item.decimals);


        // @ts-ignore
        const brErcToken = getBRErcToken(item.id);
        // if no corresponding brassets, skip
        if (!brErcToken) {
          return acc;
        }

        const market = marketsMap[item.id];
        const brtokenAddress = brErcToken.address.toLowerCase();
        const collateral = (assetsInAccount || [])
          .map((address: string) => address.toLowerCase())
          .includes(brtokenAddress);

        let walletBalance = new BigNumber(0);
        let supplyBalance = new BigNumber(0);
        let borrowBalance = new BigNumber(0);
        const percentOfLimit = '0';

        const wallet = brTokenBalances && brTokenBalances[brtokenAddress];
        if (accountAddress && wallet) {
          walletBalance = toDecimalAmount(wallet.tokenBalance);
          supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
          borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
        }


        const asset = {
          key: index,
          id: item.id,
          img: item.asset,
          // @ts-ignore
          brimg: item.brasset,
          symbol: market?.underlyingSymbol || item.id.toUpperCase(),
          decimals: item.decimals,
          tokenAddress: market?.underlyingAddress,
          brsymbol: market?.symbol,
          brtokenAddress,
          supplyApy: new BigNumber(market?.supplyApy || 0),
          borrowApy: new BigNumber(market?.borrowApy || 0),
          brnSupplyApy: new BigNumber(market?.supplyBrainiacApy || 0),
          brnBorrowApy: new BigNumber(market?.borrowBrainiacApy || 0),
          collateralFactor: new BigNumber(market?.collateralFactor || 0).div(1e18),
          tokenPrice: new BigNumber(market?.tokenPrice || 0),
          liquidity: new BigNumber(market?.liquidity || 0),
          borrowCaps: new BigNumber(market?.borrowCaps || 0),
          treasuryTotalBorrowsCents: new BigNumber(market?.totalBorrowsUsd || 0).times(100),
          treasuryTotalSupplyCents: new BigNumber(market?.totalSupplyUsd || 0).times(100),
          treasuryTotalSupply: new BigNumber(market?.totalSupply || 0),
          treasuryTotalBorrows: new BigNumber(market?.totalBorrows2 || 0),
          walletBalance,
          supplyBalance,
          borrowBalance,
          collateral,
          percentOfLimit,
          brnPerDay: new BigNumber(market?.supplierDailyBrainiac || 0)
            .plus(new BigNumber(market?.borrowerDailyBrainiac || 0))
            .div(new BigNumber(10).pow(18)),
        };

        // user totals
        const borrowBalanceCents = asset.borrowBalance.times(asset.tokenPrice).times(100);
        const supplyBalanceCents = asset.supplyBalance.times(asset.tokenPrice).times(100);
        acc.userTotalBorrowBalanceCents = acc.userTotalBorrowBalanceCents.plus(borrowBalanceCents);
        acc.userTotalSupplyBalanceCents = acc.userTotalSupplyBalanceCents.plus(supplyBalanceCents);

        acc.totalBrnDistributedWei = acc.totalBrnDistributedWei.plus(
          new BigNumber(market?.totalDistributed || 0).times(
            new BigNumber(10).pow(getToken('brn').decimals),
          ),
        );

        // Create borrow limit based on assets supplied as collateral
        if (asset.collateral) {
          acc.userTotalBorrowLimitCents = acc.userTotalBorrowLimitCents.plus(
            calculateCollateralValue({
                // @ts-ignore
              amountWei: convertTokensToWei ({ value: asset.supplyBalance, tokenId: asset.id }),
              tokenId: asset.id,
              tokenPriceTokens: asset.tokenPrice,
              collateralFactor: asset.collateralFactor,
            }).times(100),
          );
        }

        return { ...acc, assets: [...assetAcc, asset] };
      },
      {
        assets: [],
        userTotalBorrowBalanceCents: new BigNumber(0),
        userTotalBorrowLimitCents: new BigNumber(0),
        userTotalSupplyBalanceCents: new BigNumber(0),
        totalBrnDistributedWei: new BigNumber(0),
      },
    );

    let assetList = assets;

    const userTotalBorrowBalanceWithUserMintedBai = userTotalBorrowBalanceCents.plus(
      userBaiMinted.times(100),
    );

    // percent of limit
    assetList = assetList.map((item: Asset) => ({
      ...item,
      percentOfLimit: new BigNumber(userTotalBorrowLimitCents).isZero()
        ? '0'
        : item.borrowBalance
            .times(item.tokenPrice)
            .div(userTotalBorrowLimitCents)
            .times(100)
            .dp(0, 1)
            .toFixed(),
    }));

    return {
      assets: assetList,
      userTotalBorrowBalanceCents: userTotalBorrowBalanceWithUserMintedBai,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
      dailyBrainiacWei: getMarketsData.dailyBrainiacWei || new BigNumber(0),
      totalBrnDistributedWei,
    };
  }, [
    JSON.stringify(marketsMap),
    JSON.stringify(assetsInAccount),
    JSON.stringify(brTokenBalances),
    JSON.stringify(getMarketsData),
  ]);

  return {
    isLoading,
    data,
    // TODO: handle errors and retry scenarios
  };
};

export default useGetUserMarketInfo;
