import { MutationObserverOptions, useMutation } from 'react-query';

import { queryClient, mintNFT, IMintNFTInput, MintNFTOutput } from 'clients/api';
import FunctionKey from 'constants/functionKey';
import { useBaiUnitrollerContract, useBrainiacNFTContract } from 'clients/contracts/hooks';

type Options = MutationObserverOptions<
  MintNFTOutput,
  Error,
  Omit<IMintNFTInput, 'brainiacNFTContract'>
>;

const useMintNFT = (options?: Options) => {
  const brainiacNFTContract = useBrainiacNFTContract();

  return useMutation(
    FunctionKey.MINT_NFT,
    (params: Omit<IMintNFTInput, 'brainiacNFTContract'>) =>
      mintNFT({
        brainiacNFTContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        // Invalidate queries related to fetching the user minted BAI amount
        queryClient.invalidateQueries(FunctionKey.GET_MINTS_AVAILABLE);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useMintNFT;
