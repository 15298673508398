enum FunctionKey {
  // Queries
  GET_BAI_TREASURY_PERCENTAGE = 'GET_BAI_TREASURY_PERCENTAGE',
  GET_MARKETS = 'GET_MARKETS',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_ASSETS_IN_ACCOUNT = 'GET_ASSETS_IN_ACCOUNT',
  GET_BRAINIAC_ACCRUED = 'GET_BRAINIAC_ACCRUED',
  GET_BRAINIAC_BAI_STATE = 'GET_BRAINIAC_BAI_STATE',
  GET_MINTED_BAI = 'GET_MINTED_BAI',
  GET_BRN_REWARD = 'GET_BRN_REWARD',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_MINTABLE_BAI = 'GET_MINTABLE_BAI',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_BRN_WITHDRAWABLE_AMOUNT = 'GET_BRN_WITHDRAWABLE_AMOUNT',
  GET_BRAINIAC_BAI_VAULT_DAILY_RATE_WEI = 'GET_BRAINIAC_BAI_VAULT_DAILY_RATE_WEI',
  GET_BRAINIAC_BAI_VAULT_RATE = 'GET_BRAINIAC_BAI_VAULT_RATE',
  GET_V_TOKEN_CASH = 'GET_V_TOKEN_CASH',
  GET_V_TOKEN_BORROW_BALANCE = 'GET_V_TOKEN_BORROW_BALANCE',
  GET_V_TOKEN_BALANCE = 'GET_V_TOKEN_BALANCE',
  GET_V_TOKEN_BALANCES_ALL = 'GET_V_TOKEN_BALANCES_ALL',
  GET_V_TOKEN_INTEREST_RATE_MODEL = 'GET_V_TOKEN_INTEREST_RATE_MODEL',
  GET_V_TOKEN_DAILY_BRN_WEI = 'GET_V_TOKEN_DAILY_BRN_WEI',
  GET_V_TOKEN_APY_SIMULATIONS = 'GET_V_TOKEN_APY_SIMULATIONS',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_BRN_VAULT_REWARD_WEI_PER_BLOCK = 'GET_BRN_VAULT_REWARD_WEI_PER_BLOCK',
  GET_BRN_VAULT_TOTAL_ALLOCATION_POINTS = 'GET_BRN_VAULT_TOTAL_ALLOCATION_POINTS',
  GET_BRN_VAULT_POOL_INFOS = 'GET_BRN_VAULT_POOL_INFOS',
  GET_BRN_VAULT_PENDING_REWARD_WEI = 'GET_BRN_VAULT_PENDING_REWARD_WEI',
  GET_BRN_VAULT_USER_INFO = 'GET_BRN_VAULT_USER_INFO',
  GET_BRN_VAULT_POOLS_COUNT = 'GET_BRN_VAULT_POOLS_COUNT',
  GET_CURRENT_VOTES = 'GET_CURRENT_VOTES',
  GET_PENDING_BRN = 'GET_PENDING_BRN',
  GET_PROPOSALS = 'GET_PROPOSALS',
  GET_VOTE_RECEIPT = 'GET_VOTE_RECEIPT',
  GET_BAI_VAULT_USER_INFO = 'GET_BAI_VAULT_USER_INFO',
  GET_BAI_VAULT_PENDING_BRN = 'GET_BAI_VAULT_PENDING_BRN',
  GET_VOTE_DELEGATE_ADDRESS = 'GET_VOTE_DELEGATE_ADDRESS',
  SET_VOTE_DELEGATE = 'SET_VOTE_DELEGATE',
  GET_MINTS_AVAILABLE = 'GET_MINTS_AVAILABLE',

  // Mutations
  REQUEST_FAUCET_FUNDS = 'REQUEST_FAUCET_FUNDS',
  MINT_BAI = 'MINT_BAI',
  MINT_NFT = 'MINT_NFT',
  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  REPAY_BAI = 'REPAY_BAI',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  SUPPLY = 'SUPPLY',
  SUPPLY_BNB = 'SUPPLY_BNB',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  CLAIM_BRN_REWARD = 'CLAIM_BRN_REWARD',
  REPAY_NON_BNB_V_TOKEN = 'REPAY_NON_BNB_V_TOKEN',
  REPAY_BNB = 'REPAY_BNB',
  BORROW_V_TOKEN = 'BORROW_V_TOKEN',
  WITHDRAW_BRN = 'WITHDRAW_BRN',
}

export default FunctionKey;
