import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { Vault } from 'types';
import { DAYS_PER_YEAR } from 'constants/daysPerYear';
import { convertWeiToTokens } from 'utilities/common';
import {
  useGetBalanceOf,
  useGetBrainiacBaiVaultDailyRateWei,
  useGetMarkets,
  useGetBaiVaultUserInfo,
  useGetBaiVaultPendingBrnWei,
} from 'clients/api';
import { BRN_TOKEN_ID } from 'constants/brn';
import { BAI_TOKEN_ID, BAI_VAULT_ADDRESS } from './constants';

export interface UseGetBaiVaultOutput {
  isLoading: boolean;
  data: Vault | undefined;
}

const useGetBaiVault = ({ accountAddress }: { accountAddress?: string }): UseGetBaiVaultOutput => {
  const { data: totalBaiStakedWei, isLoading: isGetTotalBaiStakedWeiLoading } = useGetBalanceOf({
    accountAddress: BAI_VAULT_ADDRESS,
    tokenId:  'vrt',
  });

  const { data: baiVaultUserInfo, isLoading: isGetBaiVaultUserInfoLoading } =
    useGetBaiVaultUserInfo(
      {
        accountAddress: accountAddress || '',
      },
      {
        enabled: !!accountAddress,
      },
    );

  const { data: baiVaultPendingBrnWei, isLoading: isGetBaiVaultPendingBrnWeiLoading } =
    useGetBaiVaultPendingBrnWei(
      {
        accountAddress: accountAddress || '',
      },
      {
        enabled: !!accountAddress,
      },
    );

  const { data: brainiacBaiVaultDailyRateWei, isLoading: isGetBrainiacBaiVaultDailyRateWeiLoading } =
    useGetBrainiacBaiVaultDailyRateWei();

  const { data: getMarketsData, isLoading: isGetMarketsLoading } = useGetMarkets();
  const brnPriceDollars: BigNumber | undefined = useMemo(
    () => (getMarketsData?.markets || []).find(market => market.id === BRN_TOKEN_ID)?.tokenPrice,
    [JSON.stringify(getMarketsData?.markets)],
  );

  const data: Vault | undefined = useMemo(() => {
    if (!totalBaiStakedWei || !brainiacBaiVaultDailyRateWei || !brnPriceDollars) {
      return undefined;
    }

    const stakingAprPercentage = convertWeiToTokens({
      valueWei: brainiacBaiVaultDailyRateWei,
      tokenId: BRN_TOKEN_ID,
    })
      .multipliedBy(brnPriceDollars) // We assume 1 BAI = 1 dollar
      .multipliedBy(DAYS_PER_YEAR)
      .dividedBy(
        convertWeiToTokens({
          valueWei: totalBaiStakedWei,
          tokenId: 'vrt',
        }),
      )
      .multipliedBy(100)
      .toNumber();

    return {
      rewardTokenId: BRN_TOKEN_ID,
      stakedTokenId: 'vrt',
      dailyEmissionWei: brainiacBaiVaultDailyRateWei,
      totalStakedWei: totalBaiStakedWei,
      stakingAprPercentage,
      userStakedWei: baiVaultUserInfo?.stakedBaiWei,
      userPendingRewardWei: baiVaultPendingBrnWei,
    };
  }, [
    totalBaiStakedWei?.toFixed(),
    brainiacBaiVaultDailyRateWei?.toFixed(),
    brnPriceDollars?.toFixed(),
    JSON.stringify(baiVaultUserInfo),
    baiVaultPendingBrnWei?.toFixed(),
  ]);

  const isLoading =
    isGetTotalBaiStakedWeiLoading ||
    isGetBrainiacBaiVaultDailyRateWeiLoading ||
    isGetMarketsLoading ||
    isGetBaiVaultUserInfoLoading ||
    isGetBaiVaultPendingBrnWeiLoading;

  return {
    data,
    isLoading,
  };
};

export default useGetBaiVault;
