import { useQuery, QueryObserverOptions } from 'react-query';
import getBrnVaultTotalAllocationPoints, {
  IGetBrnVaultTotalAllocPointsInput,
  GetBrnVaultTotalAllocPointsOutput,
} from 'clients/api/queries/getBrnVaultTotalAllocationPoints';
import FunctionKey from 'constants/functionKey';
import { useBrnVaultProxyContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBrnVaultTotalAllocPointsOutput,
  Error,
  GetBrnVaultTotalAllocPointsOutput,
  GetBrnVaultTotalAllocPointsOutput,
  [FunctionKey.GET_BRN_VAULT_TOTAL_ALLOCATION_POINTS, string]
>;

const useGetBrnVaultTotalAllocationPoints = (
  { tokenAddress }: Omit<IGetBrnVaultTotalAllocPointsInput, 'brnVaultContract'>,
  options?: Options,
) => {
  const brnVaultContract = useBrnVaultProxyContract();

  return useQuery(
    [FunctionKey.GET_BRN_VAULT_TOTAL_ALLOCATION_POINTS, tokenAddress],
    () => getBrnVaultTotalAllocationPoints({ tokenAddress, brnVaultContract }),
    options,
  );
};

export default useGetBrnVaultTotalAllocationPoints;
