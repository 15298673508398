import sample from 'lodash/sample';
import globalData from 'globalData';

export enum ChainId {
  'MAINNET' = 56,
  'TESTNET' = 97,
  'GODWOKENTESTNET' = 71401,
  'GODWOKENMAINNET' = 71402,
  'HARMONY' = 1666600000,
}

export const CHAIN_ID: ChainId = globalData.chainId
  ? parseInt(String(globalData.chainId), 10)
  : ChainId.MAINNET;

export const isOnTestnet = CHAIN_ID === ChainId.TESTNET || CHAIN_ID === ChainId.GODWOKENTESTNET;

const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
  [ChainId.GODWOKENTESTNET]: 'https://v1.betanet.gwscan.com',
  [ChainId.GODWOKENMAINNET]: 'https://gwscan.com',
  [ChainId.HARMONY]: 'https://explorer.harmony.one',
};

const API_ENDPOINT_URLS = {
  [ChainId.MAINNET]: 'https://api.brainiac.io/api',
  [ChainId.TESTNET]: 'https://testnetapi.brainiac.io/api',
  [ChainId.GODWOKENTESTNET]: 'https://api.brainiac.fi',
  [ChainId.GODWOKENMAINNET]:
    process.env.NODE_ENV === 'production' ? 'https://api.brainiac.finance' : 'https://api.brainiac.finance',
  [ChainId.HARMONY]: 'https://api.brainiac.io/api', // TODO: Update for Harmony
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [ChainId.MAINNET]: [
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed.binance.org',
  ],
  [ChainId.TESTNET]: ['https://speedy-nodes-nyc.moralis.io/6c1fe2e962cdccfe0e93dcb3/bsc/testnet'],
  [ChainId.GODWOKENTESTNET]: [
    'https://godwoken-testnet-v1.ckbapp.dev',
    'https://godwoken-testnet-v1.ckbapp.dev',
    'https://godwoken-testnet-v1.ckbapp.dev',
  ],
  [ChainId.GODWOKENMAINNET]: [
    'https://v1.mainnet.godwoken.io/rpc',
    'https://v1.mainnet.godwoken.io/rpc',
  ],
  [ChainId.HARMONY]: [
    'https://rpc.ankr.com/harmony',
    'https://a.api.s0.t.hmny.io',
    'https://api.s0.t.hmny.io',
    'https://api.harmony.one',
    'https://harmony-mainnet.chainstacklabs.com',
    'https://harmony-0-rpc.gateway.pokt.network',
  ],
};

export const RPC_URL = sample(RPC_URLS[CHAIN_ID]) as string;

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[CHAIN_ID];

export const API_ENDPOINT_URL = API_ENDPOINT_URLS[CHAIN_ID];

export const LS_KEY_CONNECTED_CONNECTOR = 'connected-connector';

export const VTOKEN_DECIMALS = 8;

// Note: this is a temporary fix. Once we start refactoring this part we should
// probably fetch the treasury address using the Comptroller contract
const TREASURY_ADDRESSES = {
  [ChainId.MAINNET]: '0xF322942f644A996A617BD29c16bd7d231d9F35E9',
  // When querying comptroller.treasuryAddress() we get an empty address back,
  // so for now I've let it as it is
  [ChainId.TESTNET]: '0x0000000000000000000000000000000000000000',
  [ChainId.GODWOKENTESTNET]: '0xcBdE600c47F0F81d30b5EFe96d52D816899AEA15',
  [ChainId.GODWOKENMAINNET]: '0x57d7567E9c09CC531C1c93E0a5111E904fEB0A4D',
  [ChainId.HARMONY]: '0x0000000000000000000000000000000000000000', // TODO: Update for Harmony
};

export const TREASURY_ADDRESS = TREASURY_ADDRESSES[CHAIN_ID];
export const ETHERSCAN_BRN_CONTRACT_ADDRESS = '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63';
export const BRAINIAC_MEDIUM_URL = globalData.socialLink.MEDIUM;
export const BRAINIAC_DISCORD_URL = globalData.socialLink.DISCORD;
export const BRAINIAC_TWITTER_URL = globalData.socialLink.TWITTER;
export const BRAINIAC_GITHUB_URL = globalData.socialLink.GITHUB;

// TODO: update
export const BRAINIAC_TERMS_OF_SERVICE_URL = 'https://www.swipe.io/terms';

export const SAFE_BORROW_LIMIT_PERCENTAGE = 80;
