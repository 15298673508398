import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core/types';

import { checkForTokenTransactionError } from 'errors';
import { BRTokenId } from 'types';
import { BRTokenContract } from 'clients/contracts/types';

export interface IBorrowBRTokenInput {
  brTokenContract: BRTokenContract<BRTokenId>;
  fromAccountAddress: string;
  amountWei: BigNumber;
}

export type BorrowBRTokenOutput = TransactionReceipt;

const borrowBRToken = async ({
  brTokenContract,
  fromAccountAddress,
  amountWei,
}: IBorrowBRTokenInput): Promise<BorrowBRTokenOutput> => {
  const resp = await brTokenContract.methods
    .borrow(amountWei.toFixed())
    .send({ from: fromAccountAddress });

  return checkForTokenTransactionError(resp);
};

export default borrowBRToken;
