import BigNumber from 'bignumber.js';
import { useQuery, QueryObserverOptions } from 'react-query';

import { InterestModel } from 'types/contracts';
import { useWeb3 } from 'clients/web3';
import { getInterestModelContract } from 'clients/contracts/getters';
import getBRTokenApySimulations, {
  GetBRTokenApySimulationsOutput,
} from 'clients/api/queries/getBRTokenApySimulations';
import useGetBRTokenInterestRateModel from 'clients/api/queries/useGetBRTokenInterestRateModel';
import { BRTokenId } from 'types';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetBRTokenApySimulationsOutput,
  Error,
  GetBRTokenApySimulationsOutput,
  GetBRTokenApySimulationsOutput,
  [FunctionKey.GET_V_TOKEN_APY_SIMULATIONS, BRTokenId]
>;

const useGetBRTokenApySimulations = (
  { brTokenId, reserveFactorMantissa }: { brTokenId: BRTokenId; reserveFactorMantissa?: BigNumber },
  options?: Options,
) => {
  const web3 = useWeb3();
  const { data: interestRateModelContractAddress } = useGetBRTokenInterestRateModel({ brTokenId });
  const interestModelContract = interestRateModelContractAddress
    ? getInterestModelContract(interestRateModelContractAddress, web3)
    : undefined;

  return useQuery(
    [FunctionKey.GET_V_TOKEN_APY_SIMULATIONS, brTokenId],
    () =>
      getBRTokenApySimulations({
        interestModelContract: interestModelContract || ({} as InterestModel),
        reserveFactorMantissa: reserveFactorMantissa || new BigNumber(0),
      }),
    {
      ...options,
      enabled:
        (options?.enabled === undefined || options?.enabled) &&
        interestRateModelContractAddress !== undefined &&
        reserveFactorMantissa !== undefined,
    },
  );
};

export default useGetBRTokenApySimulations;
