import { useQueries, UseQueryOptions, UseQueryResult } from 'react-query';

import FunctionKey from 'constants/functionKey';
import { useBrnVaultProxyContract } from 'clients/contracts/hooks';
import {
  getBrnVaultPoolInfo,
  IGetBrnVaultPoolInfoOutput,
  getBrnVaultPendingRewardWei,
  GetBrnVaultPendingRewardWeiOutput,
  getBrnVaultUserInfo,
  IGetBrnVaultUserInfoOutput,
} from 'clients/api';
import { BRN_TOKEN_ADDRESS } from 'constants/brn';

export interface IUseGetBrnVaultPoolsInput {
  poolsCount: number;
  accountAddress?: string;
}

export type UseGetBrnVaultPoolsOutput = UseQueryResult<
  IGetBrnVaultPoolInfoOutput | GetBrnVaultPendingRewardWeiOutput | IGetBrnVaultUserInfoOutput
>[];

const useGetBrnVaultPools = ({
  accountAddress,
  poolsCount,
}: IUseGetBrnVaultPoolsInput): UseGetBrnVaultPoolsOutput => {
  const brnVaultContract = useBrnVaultProxyContract();

  const poolQueries: UseQueryOptions<
    IGetBrnVaultPoolInfoOutput | GetBrnVaultPendingRewardWeiOutput | IGetBrnVaultUserInfoOutput
  >[] = [];

  // Fetch pool infos
  for (let poolIndex = 0; poolIndex < poolsCount; poolIndex++) {
    poolQueries.push({
      queryFn: () =>
        getBrnVaultPoolInfo({
          brnVaultContract,
          tokenAddress: BRN_TOKEN_ADDRESS,
          poolIndex,
        }),
      queryKey: [FunctionKey.GET_BRN_VAULT_POOL_INFOS, BRN_TOKEN_ADDRESS, poolIndex],
    });

    poolQueries.push({
      queryFn: () =>
        getBrnVaultPendingRewardWei({
          brnVaultContract,
          tokenAddress: BRN_TOKEN_ADDRESS,
          poolIndex,
          accountAddress: accountAddress || '',
        }),
      queryKey: [
        FunctionKey.GET_BRN_VAULT_PENDING_REWARD_WEI,
        accountAddress,
        BRN_TOKEN_ADDRESS,
        poolIndex,
      ],
      enabled: !!accountAddress,
    });

    poolQueries.push({
      queryFn: () =>
        getBrnVaultUserInfo({
          brnVaultContract,
          tokenAddress: BRN_TOKEN_ADDRESS,
          poolIndex,
          accountAddress: accountAddress || '',
        }),
      queryKey: [FunctionKey.GET_BRN_VAULT_USER_INFO, accountAddress, BRN_TOKEN_ADDRESS, poolIndex],
      enabled: !!accountAddress,
    });
  }

  return useQueries(poolQueries);
};

export default useGetBrnVaultPools;
