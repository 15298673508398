import BigNumber from 'bignumber.js';
import { BrainiacLens } from 'types/contracts';
import { getContractAddress } from 'utilities';
import { BRERC_TOKENS } from 'constants/tokens';

export interface IGetDailyBrnWeiInput {
  brainiacLensContract: BrainiacLens;
  accountAddress: string;
}

export type IGetDailyBrnWeiOutput = BigNumber;

const comptrollerAddress = getContractAddress('comptroller');
const getDailyBrnWei = async ({
  brainiacLensContract,
  accountAddress,
}: IGetDailyBrnWeiInput): Promise<IGetDailyBrnWeiOutput> => {
  const brTokens = Object.values(BRERC_TOKENS)
    .filter(brToken => brToken.address !== '')
    .map(brToken => brToken.address);

  const chunkSize = 1;
  let dailyReward = new BigNumber(0);

  for (let i = 0; i < brTokens.length; i += chunkSize) {
    const chunk = brTokens.slice(i, i + chunkSize);
    const reward = await brainiacLensContract.methods
      .getDailyBRN(accountAddress, comptrollerAddress, chunk)
      .call();
      
    dailyReward = dailyReward.plus(reward);
  }

  return new BigNumber(dailyReward);
};

export default getDailyBrnWei;
