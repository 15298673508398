/** @jsxImportSource @emotion/react */
import React from 'react';
import Header from './Header';
import MarketTable from './MarketTable';
import { Paper } from '@mui/material';



const MarketUi: React.FC = () => (
  <Paper>
    <Header />
    <MarketTable />
  </Paper>
  
);

const Market: React.FC = () => <MarketUi />;

export default Market;
