import { useQuery, QueryObserverOptions } from 'react-query';
import getBrnVaultPoolsCount, {
  GetBrnVaultPoolsCountOutput,
} from 'clients/api/queries/getBrnVaultPoolsCount';
import FunctionKey from 'constants/functionKey';
import { useBrnVaultProxyContract } from 'clients/contracts/hooks';

type Options = QueryObserverOptions<
  GetBrnVaultPoolsCountOutput,
  Error,
  GetBrnVaultPoolsCountOutput,
  GetBrnVaultPoolsCountOutput,
  FunctionKey.GET_BRN_VAULT_POOLS_COUNT
>;

const useGetBrnVaultPoolsCount = (options?: Options) => {
  const brnVaultContract = useBrnVaultProxyContract();

  return useQuery(
    FunctionKey.GET_BRN_VAULT_POOLS_COUNT,
    () => getBrnVaultPoolsCount({ brnVaultContract }),
    options,
  );
};

export default useGetBrnVaultPoolsCount;
