import * as React from 'react';
import { SVGProps } from 'react';

const SvgFarm = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="33 33 89.07 89.07" {...props}>
  <title>Asset 3</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <polygon fill="none" stroke="white" strokeWidth={3} strokeMiterlimit={10}
        points="43.88 56.11 70.17 45.38 104.69 54.61 118.13 73.7 119.37 91.51 109.03 98.8 108.82 106.53 95.79 111.89 87.52 104.38 74.9 97.3 61.87 99.02 51.95 92.37 54.01 86.79 41.6 85.29 36.64 65.98 43.88 56.11" />
      <polygon fill="none" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} points="54.01 86.79 36.64 65.98 43.88 56.11 65.66 70.27 54.01 86.79" />
      <polyline fill="none" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} points="70.17 45.38 71.18 62.26 65.66 70.27 109.03 98.8 104.69 55.41" />
      <line fill="none" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} x1="74.9" y1="97.3" x2="104.69" y2="54.61" />
      <polyline fill="none" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} points="71.18 62.26 117.46 73.78 87.35 104.28 74.9 97.3 71.51 62.34" />
      <polyline fill="none" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} points="87.35 104.28 91.45 117.4 86.35 117.4 74.9 97.62" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="44.2" cy="56.01" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="41.54" cy="85.11" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="53.95" cy="86.48" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="65.66" cy="70.27" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="71.18" cy="62.26" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="104.69" cy="55.02" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="74.9" cy="97.3" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="109.03" cy="98.8" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="108.82" cy="106.53" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="95.81" cy="111.89" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="87.6" cy="104.28" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="119.37" cy="91.38" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="118.04" cy="73.78" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="70.17" cy="45.38" rx="1.33" ry="1.38" />
      <ellipse fill="currentColor" stroke="currentColor" strokeWidth={3} strokeMiterlimit={10} cx="36.82" cy="66.04" rx="1.33" ry="1.38" />
    </g>
  </g>
</svg>
);

export default SvgFarm;

