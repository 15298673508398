import BigNumber from 'bignumber.js';
import type { TransactionReceipt } from 'web3-core/types';

import MAX_UINT256 from 'constants/maxUint256';
import { BRTokenId } from 'types';
import { BRTokenContract } from 'clients/contracts/types';
import { checkForTokenTransactionError } from 'errors';

export interface IRepayNonBnbBRTokenInput {
  brTokenContract: BRTokenContract<BRTokenId>;
  fromAccountAddress: string;
  amountWei: BigNumber;
  isRepayingFullLoan?: boolean;
}

export type RepayNonBnbBRTokenOutput = TransactionReceipt;

const repayNonBnbBRToken = async ({
  brTokenContract,
  fromAccountAddress,
  amountWei,
  isRepayingFullLoan = false,
}: IRepayNonBnbBRTokenInput): Promise<RepayNonBnbBRTokenOutput> => {
  const resp = await brTokenContract.methods
    .repayBorrow(isRepayingFullLoan ? MAX_UINT256.toFixed() : amountWei.toFixed())
    .send({ from: fromAccountAddress });
  return checkForTokenTransactionError(resp);
};

export default repayNonBnbBRToken;
