export interface IGetBRTokenBalanceOfInput {
  tokenContract: $TSFixMe; // @TODO: use contract type once defined (Typechain?)
  account: string;
}

export type GetBRTokenBalanceOfOutput = string;

const getBRTokenBalanceOf = async ({
  tokenContract,
  account,
}: IGetBRTokenBalanceOfInput): Promise<GetBRTokenBalanceOfOutput> =>
  tokenContract.methods.balanceOf(account).call();
export default getBRTokenBalanceOf;
