import React from 'react';

import { Link } from 'react-router-dom';

import '../../assets/styles/homeComponents/ListedAssets.scss';

// importing images
import usdc from '../../assets/homeImages/usdc-logo.png';
// import usdt from '../../assets/homeImages/usdt-logo.png'
import eth from '../../assets/homeImages/eth-logo.png';
// import wnear from '../../assets/homeImages/wnear-logo.png'
// import wbtc from '../../assets/homeImages/wbtc-logo.png'
// import stnear from '../../assets/homeImages/stnear-logo.png'
// import aurora from '../../assets/homeImages/aurora-logo.png'
import ckb from '../../assets/homeImages/ckb.png';

const ListedAssets = () => {
  return (
    <>
      <div className="listed-assets">
        <div className="listed-assets__container">
          <div className="listed-assets__head">
            <h1>Listed Assets</h1>
          </div>

          <div className="listed-assets__card-container">
            <div className="listed-assets__box">
              {/* usdc Card   */}
              <div className="listed-assets__each-card">
                <div className="listed-assets__card-head">
                  <div className="listed-assets__cards-logo">
                    <img src={usdc} alt="" />
                  </div>
                  <div className="listed-assets__card-title ">
                    <h4>usdc</h4>
                  </div>
                </div>

                <div className="listed-assets__cards-details">
                  <div className="listed-assets__cards-total-deposit">
                    <h5>Total Deposited</h5>
                    <h4>$0*</h4>
                  </div>
                  <div className="listed-assets__cards-deposited-apy">
                    <h5>Deposited APY</h5>
                    <h4>0%*</h4>
                  </div>
                </div>
              </div>

              {/* usdt Card   */}
              {/* <div className="listed-assets__each-card">
                                <div className="listed-assets__card-head">
                                    <div className="listed-assets__cards-logo">
                                        <img src={usdt} alt="" />
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>usdt</h4>
                                    </div>
                                </div>

                                <div className="listed-assets__cards-details">
                                    <div className="listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$406,017,947</h4>
                                    </div>
                                    <div className="listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>7.97%</h4>
                                    </div>
                                </div>
                            </div> */}

              {/* ETH Card   */}
              <div className="listed-assets__each-card">
                <div className="listed-assets__card-head">
                  <div className="listed-assets__cards-logo">
                    <img src={eth} alt="" />
                  </div>
                  <div className="listed-assets__card-title ">
                    <h4>ETH</h4>
                  </div>
                </div>

                <div className="listed-assets__cards-details">
                  <div className=" listed-assets__cards-total-deposit">
                    <h5>Total Deposited</h5>
                    <h4>$0*</h4>
                  </div>
                  <div className=" listed-assets__cards-deposited-apy">
                    <h5>Deposited APY</h5>
                    <h4>0%*</h4>
                  </div>
                </div>
              </div>

              {/* WNEAR Card   */}
              {/* <div className="listed-assets__each-card">
                                <div className=" listed-assets__card-head">
                                    <div className=" listed-assets__wnear-img listed-assets__cards-logo">
                                        <img src={wnear} alt="" />
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>WNEAR</h4>
                                    </div>
                                </div>

                                <div className=" listed-assets__cards-details">
                                    <div className=" listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$406,017,947</h4>
                                    </div>
                                    <div className=" listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>7.97%</h4>
                                    </div>
                                </div>
                            </div> */}

              {/* WBTC Card  */}
              {/* <div className="listed-assets__each-card">
                                <div className="listed-assets__card-head">
                                    <div className="l listed-assets__cards-logo">
                                        <img src={wbtc} alt="" />
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>WBTC</h4>
                                    </div>
                                </div>

                                <div className="l listed-assets__cards-details">
                                    <div className=" listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$0*</h4>
                                    </div>
                                    <div className=" listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>0%*</h4>
                                    </div>
                                </div>
                            </div> */}

              {/* STNEAR Card  */}
              {/* <div className="listed-assets__each-card">
                                <div className=" listed-assets__card-head">
                                    <div className=" listed-assets__cards-logo">
                                        <img src={stnear} alt="" />
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>STNEAR</h4>
                                    </div>
                                </div>

                                <div className=" listed-assets__cards-details">
                                    <div className=" listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$406,017,947</h4>
                                    </div>
                                    <div className=" listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>7.97%</h4>
                                    </div>
                                </div>
                            </div> */}

              {/*  TRI Card  */}
              {/* <div className="listed-assets__each-card">
                                <div className=" listed-assets__card-head">
                                    <div className=" listed-assets__cards-logo">
                                        <img src="" alt="" />
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>TRI</h4>
                                    </div>
                                </div>

                                <div className=" listed-assets__cards-details">
                                    <div className=" listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$406,017,947</h4>
                                    </div>
                                    <div className="listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>7.97%</h4>
                                    </div>
                                </div>
                            </div> */}

              {/* AURORA Card  */}
              {/* <div className="listed-assets__each-card">
                                <div className=" listed-assets__card-head">
                                    <div className=" listed-assets__cards-logo">
                                        <div className="listed-assets__aurora-image-cropper">
                                            <img src={aurora} alt="" />
                                        </div>
                                    </div>
                                    <div className="listed-assets__card-title ">
                                        <h4>AURORA</h4>
                                    </div>
                                </div>

                                <div className=" listed-assets__cards-details">
                                    <div className=" listed-assets__cards-total-deposit">
                                        <h5>Total Deposited</h5>
                                        <h4>$406,017,947</h4>
                                    </div>
                                    <div className=" listed-assets__cards-deposited-apy">
                                        <h5>Deposited APY</h5>
                                        <h4>7.97%</h4>
                                    </div>
                                </div>
                            </div> */}

              {/* CKB Card  */}
              <div className="listed-assets__each-card">
                <div className="listed-assets__card-head">
                  <div className="listed-assets__cards-logo">
                    <img src={ckb} alt="" />
                  </div>
                  <div className="listed-assets__card-title ">
                    <h4>CKB</h4>
                  </div>
                </div>

                <div className="listed-assets__cards-details">
                  <div className="listed-assets__cards-total-deposit">
                    <h5>Total Deposited</h5>
                    <h4>$0*</h4>
                  </div>
                  <div className="listed-assets__cards-deposited-apy">
                    <h5>Deposited APY</h5>
                    <h4>0%*</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="listed-assets__bottom-para">
            <h2>Deposit now and multiply your assets today!</h2>
          </div>

          <div className="listed-assets__start-earning-btn-box">
            {/* <Link to="/dashboard"><button>Start Earning</button></Link> */}
            <Link to="/dashboard">
              <button>Launch App</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListedAssets;
