//@ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import BigNumber from 'bignumber.js';

import { TREASURY_ADDRESS } from 'config';
import { useWeb3 } from 'clients/web3';
import { Asset, Market } from 'types';
import { BRERC_TOKENS, TOKENS } from 'constants/tokens';
import { getBRErcToken, getToken, calculateCollateralValue } from 'utilities';
import { fetchMarkets } from 'utilities/api';
import { indexBy, notNull, convertTokensToWei  } from 'utilities/common';
import useRefresh from 'hooks/useRefresh';
import { useBaiUser } from 'hooks/useBaiUser';
import { useComptrollerContract, useBrainiacLensContract } from 'clients/contracts/hooks';
import { AuthContext } from './AuthContext';

const MarketContext = React.createContext({
  markets: [] as $TSFixMe[],
  dailyBrainiac: 0,
  treasuryTotalUSDBalance: new BigNumber(0),
  userMarketInfo: [] as Array<Asset>,
  userTotalBorrowLimit: new BigNumber(0),
  userTotalBorrowBalance: new BigNumber(0),
  userBrnBalance: new BigNumber(0),
});

// This context provide a way for all the components to share the market data, thus avoid
// duplicated requests

const MarketContextProvider = ({ children }: $TSFixMe) => {
  const [markets, setMarkets] = useState<$TSFixMe[]>([]);
  const [dailyBrainiac, setDailyBrainiac] = useState(0);
  const [userMarketInfo, setUserMarketInfo] = useState<Array<Asset>>([]);
  const [userTotalBorrowLimit, setUserTotalBorrowLimit] = useState(new BigNumber(0));
  const [userTotalBorrowBalance, setUserTotalBorrowBalance] = useState(new BigNumber(0));
  const [userBrnBalance, setUserBrnBalance] = useState(new BigNumber(0));
  const [treasuryTotalUSDBalance, setTreasuryTotalUSDBalance] = useState(new BigNumber(0));
  const comptrollerContract = useComptrollerContract();
  const lens = useBrainiacLensContract();
  const { account } = useContext(AuthContext);
  const web3 = useWeb3();
  const { userBaiMinted } = useBaiUser();

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    let isMounted = true;
    const getMarkets = async () => {
      const res = await fetchMarkets();
      if (!res.data || !res.data.status) {
        return;
      }

      const Vtokens = Object.values(BRERC_TOKENS).filter(item => item.address != "")
     
      const data = Vtokens
        .map(item => {
          if (res && res.data && res.data.data) {
            return res.data.data.markets.find(
              (market: Market) => market.address.toLowerCase() === item.address.toLowerCase(),
            );
          }
          return undefined;
        })
        .filter(item => !!item);

      if (!isMounted) {
        return;
      }

      setMarkets(data);
      setDailyBrainiac(res.data.data.dailyBrainiac);
    };
    getMarkets();
    return () => {
      isMounted = false;
    };
  }, [fastRefresh]);

  useEffect(() => {
    let isMounted = true;

    const getBrnBalance = (balances: $TSFixMe) => {
      const vbrn = getBRErcToken('brn').address.toLowerCase();
      const brnDecimals = getToken('brn').decimals;
      return new BigNumber(balances[vbrn].tokenBalance).shiftedBy(-brnDecimals);
    };

    const updateMarketUserInfo = async () => {
      if (!markets) {
        return;
      }

      try {
        let brnBalance = new BigNumber(0);
        const assetsIn = account
          ? await comptrollerContract.methods.getAssetsIn(account.address).call()
          : [];

        const brTokenAddresses = Object.values(BRERC_TOKENS)
          .filter(item => item.address)
          .map(item => item.address);

       

        let balances = {};
        if (account) {
          balances = indexBy(
            (item: $TSFixMe) => item.brToken.toLowerCase(), // index by brToken address
            await lens.methods.brTokenBalancesAll(brTokenAddresses, account.address).call(),
          );
          brnBalance = getBrnBalance(balances);
        } 
      

        // Fetch treasury balances
        const treasuryBalances = indexBy(
          (item: $TSFixMe) => item.brToken.toLowerCase(), // index by brToken address
          await lens.methods.brTokenBalancesAll(brTokenAddresses, TREASURY_ADDRESS).call(),
        );   

        const marketsMap = indexBy(
          (item: $TSFixMe) => item.underlyingSymbol.toLowerCase(),
          markets,
        );

        const assetAndNullList = Object.values(TOKENS).map((item, index) => {
          const toDecimalAmount = (mantissa: string) =>
            new BigNumber(mantissa).shiftedBy(-item.decimals);

          // if no corresponding brassets, skip

          if (!getBRErcToken(item.id)) {
            return null;
          }

          let market = marketsMap[item.symbol.toLowerCase()];
          if (!market) {
            market = {};
          }

          const brtokenAddress = getBRErcToken(item.id).address.toLowerCase();

          const collateral = assetsIn
            .map((address: $TSFixMe) => address.toLowerCase())
            .includes(brtokenAddress);

          const treasuryBalance = toDecimalAmount(0);
          // toDecimalAmount(
          //   (treasuryBalances[brtokenAddress] as any).tokenBalance,
          // );

          let walletBalance = new BigNumber(0);
          let supplyBalance = new BigNumber(0);
          let borrowBalance = new BigNumber(0);
          const percentOfLimit = '0';

          if (account) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const wallet = balances[brtokenAddress];
            if(!wallet){
              walletBalance = toDecimalAmount(0);
              supplyBalance = toDecimalAmount(0);
              borrowBalance = toDecimalAmount(0);
              return null;
          }
            walletBalance = toDecimalAmount(wallet.tokenBalance);
            supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
            borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
          }

          return {
            key: index,
            id: item.id,
            img: item.asset,
            brimg: item.brasset,
            symbol: market.underlyingSymbol || '',
            decimals: item.decimals,
            tokenAddress: market.underlyingAddress,
            brsymbol: market.symbol,
            brtokenAddress,
            supplyApy: new BigNumber(market.supplyApy || 0),
            borrowApy: new BigNumber(0).minus(market.borrowApy || 0),
            brnSupplyApy: new BigNumber(market.supplyBrainiacApy || 0),
            brnBorrowApy: new BigNumber(market.borrowBrainiacApy || 0),
            collateralFactor: new BigNumber(market.collateralFactor || 0).div(1e18),
            tokenPrice: new BigNumber(market.tokenPrice || 0),
            liquidity: new BigNumber(market.liquidity || 0),
            borrowCaps: new BigNumber(market.borrowCaps || 0),
            treasuryTotalBorrowsCents: new BigNumber(market.totalBorrowsUsd).times(100),
            treasuryTotalSupplyCents: new BigNumber(market.totalSupplyUsd).times(100),
            treasuryTotalSupply: new BigNumber(market.totalSupply),
            treasuryTotalBorrows: new BigNumber(market.totalBorrows2),
            treasuryBalance,
            walletBalance,
            supplyBalance,
            borrowBalance,
            collateral,
            percentOfLimit,
            brnPerDay: new BigNumber(market.supplierDailyBrainiac)
              .plus(new BigNumber(market.borrowerDailyBrainiac))
              .div(new BigNumber(10).pow(getToken('brn').decimals)),
          };
        });

        let assetList = assetAndNullList.filter(notNull);

        const totalBorrowBalance = assetList
          .reduce((acc, asset) => {
            const borrowBalanceUSD = asset.borrowBalance.times(asset.tokenPrice);
            return acc.plus(borrowBalanceUSD);
          }, new BigNumber(0))
          .plus(userBaiMinted);

        const totalBorrowLimit = assetList.reduce((acc, asset) => {
          if (asset.collateral) {
            return acc.plus(
              calculateCollateralValue({
                amountWei: convertTokensToWei ({ value: asset.supplyBalance, tokenId: asset.id }),
                tokenId: asset.id,
                tokenPriceTokens: asset.tokenPrice,
                collateralFactor: asset.collateralFactor,
              }),
            );
          }
          return acc;
        }, new BigNumber(0));

        // percent of limit
        assetList = assetList.map((item: Asset) => ({
          ...item,
          percentOfLimit: new BigNumber(totalBorrowLimit).isZero()
            ? '0'
            : item.borrowBalance
                .times(item.tokenPrice)
                .div(totalBorrowLimit)
                .times(100)
                .dp(0, 1)
                .toString(10),
        }));

        if (!isMounted) {
          return;
        }

        // Calculate total treasury balance in USD
        const updatedTreasuryTotalUSDBalance = assetList.reduce((accumulator, asset) => {
          const treasuryUSDBalance = asset.treasuryBalance.multipliedBy(asset.tokenPrice);
          return accumulator.plus(treasuryUSDBalance);
        }, new BigNumber(0));

        setTreasuryTotalUSDBalance(updatedTreasuryTotalUSDBalance);
        setUserMarketInfo(assetList);
        setUserTotalBorrowLimit(totalBorrowLimit);
        setUserTotalBorrowBalance(totalBorrowBalance);
        setUserBrnBalance(brnBalance);
      } catch (error) {
        console.log('error when get market data', error);
      }
    };
    updateMarketUserInfo();
    return () => {
      isMounted = false;
    };
  }, [markets, account, web3, fastRefresh]);

  return (
    <MarketContext.Provider
      value={{
        markets,
        dailyBrainiac,
        treasuryTotalUSDBalance,
        userMarketInfo,
        userTotalBorrowLimit,
        userTotalBorrowBalance,
        userBrnBalance,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketContextProvider };
