import { IGetBrnVaultUserInfoInput, IGetBrnVaultUserInfoOutput } from './types';
import formatToUserInfo from './formatToUserInfo';

export * from './types';

const getBrnVaultUserInfo = async ({
  brnVaultContract,
  tokenAddress,
  poolIndex,
  accountAddress,
}: IGetBrnVaultUserInfoInput): Promise<IGetBrnVaultUserInfoOutput> => {
  const res = await brnVaultContract.methods
    .getUserInfo(tokenAddress, poolIndex, accountAddress)
    .call();

  return formatToUserInfo(res);
};

export default getBrnVaultUserInfo;
