import { BrErc20, VBnbToken } from 'types/contracts';

export interface IGetBRTokenInterestRateModelInput {
  brTokenContract: BrErc20 | VBnbToken;
}

export type GetBRTokenInterestRateModelOutput = string;

const getBRTokenInterestRateModel = async ({
  brTokenContract,
}: IGetBRTokenInterestRateModelInput): Promise<GetBRTokenInterestRateModelOutput> =>
  brTokenContract.methods.interestRateModel().call();

export default getBRTokenInterestRateModel;
