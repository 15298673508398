import { BrnVault } from 'types/contracts';

export interface IGetBrnVaultTotalAllocPointsInput {
  brnVaultContract: BrnVault;
  tokenAddress: string;
}

export type GetBrnVaultTotalAllocPointsOutput = number;

const getBrnVaultTotalAllocationPoints = async ({
  brnVaultContract,
  tokenAddress,
}: IGetBrnVaultTotalAllocPointsInput): Promise<GetBrnVaultTotalAllocPointsOutput> => {
  const res = await brnVaultContract.methods.totalAllocPoints(tokenAddress).call();
  return +res;
};

export default getBrnVaultTotalAllocationPoints;
