import sxp from 'assets/img/tokens/sxp.png';
import usdc from 'assets/img/tokens/usdc.png';
import usdt from 'assets/img/tokens/usdt.png';
import busd from 'assets/img/tokens/busd.png';
import bnb from 'assets/img/tokens/bnb.png';
import brn from 'assets/img/tokens/brain.png';
import btc from 'assets/img/tokens/btc.png';
import eth from 'assets/img/tokens/eth.png';
import ltc from 'assets/img/tokens/ltc.png';
import xrp from 'assets/img/tokens/xrp.png';
import ckdai from 'assets/img/tokens/dai.png';
import ada from 'assets/img/tokens/ada.png';
import doge from 'assets/img/tokens/doge.png';
import matic from 'assets/img/tokens/matic.png';
import cake from 'assets/img/tokens/cake.png';
import aave from 'assets/img/tokens/aave.png';
import tusd from 'assets/img/tokens/tusd.png';
import trx from 'assets/img/tokens/trx.png';
import ust from 'assets/img/tokens/ust.png';
import luna from 'assets/img/tokens/luna.png';
import bai from 'assets/img/tokens/bai.svg';
import ckb from 'assets/img/tokens/ckb.svg';
import vrt from 'assets/img/tokens/vrt.svg';
import brsxp from 'assets/img/tokens/vsxp.png';
import brusdc from 'assets/img/tokens/vusdc.png';
import brusdt from 'assets/img/tokens/vusdt.png';
import brbusd from 'assets/img/tokens/vbusd.png';
import brxrp from 'assets/img/tokens/vxrp.png';
import brbnb from 'assets/img/tokens/vbnb.png';
import brbrn from 'assets/img/tokens/vbrn.png';
import brbtc from 'assets/img/tokens/vbtc.png';
import breth from 'assets/img/tokens/veth.png';
import brltc from 'assets/img/tokens/vltc.png';
import brdai from 'assets/img/tokens/vdai.png';
import brada from 'assets/img/tokens/vada.png';
import brdoge from 'assets/img/tokens/vdoge.png';
import brmatic from 'assets/img/tokens/vmatic.png';
import brcake from 'assets/img/tokens/vcake.png';
import braave from 'assets/img/tokens/vaave.png';
import brtusd from 'assets/img/tokens/vtusd.png';
import brtrx from 'assets/img/tokens/vtrx.png';
import brust from 'assets/img/tokens/vust.png';
import brluna from 'assets/img/tokens/vluna.png';
import { CHAIN_ID } from 'config';
import TOKEN_ADDRESSES from './contracts/addresses/tokens.json';
import BRERC_TOKEN_ADDRESSES from './contracts/addresses/brErcTokens.json';

export const TOKENS = {
  brainckblp: {
    id: 'brainckblp',
    symbol: 'BRN-CKB-LP',
    decimals: 18,
    address: TOKEN_ADDRESSES.brainckblp[CHAIN_ID],
    asset: sxp,
    brasset: brsxp,
  },
  sxp: {
    id: 'sxp',
    symbol: 'SXP',
    decimals: 18,
    address: TOKEN_ADDRESSES.sxp[CHAIN_ID],
    asset: sxp,
    brasset: brsxp,
  },
  usdc: {
    id: 'usdc',
    symbol: 'USDC|eth',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdc[CHAIN_ID],
    asset: usdc,
    brasset: brusdc,
  },
  usdt: {
    id: 'usdt',
    symbol: 'USDT',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdt[CHAIN_ID],
    asset: usdt,
    brasset: brusdt,
  },
  busd: {
    id: 'busd',
    symbol: 'BUSD',
    decimals: 18,
    address: TOKEN_ADDRESSES.busd[CHAIN_ID],
    asset: busd,
    brasset: busd,
  },
  ckdai: {
    id: 'ckdai',
    symbol: 'DAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.ckdai[CHAIN_ID],
    asset: ckdai,
    brasset: brdai,
  },
  bnb: {
    id: 'bnb',
    symbol: 'BNB|bsc',
    decimals: 18,
    address: TOKEN_ADDRESSES.bnb[CHAIN_ID],
    asset: bnb,
    brasset: brbnb,
  },
  brn: {
    id: 'brn',
    symbol: 'BRN',
    decimals: 18,
    address: TOKEN_ADDRESSES.brn[CHAIN_ID],
    asset: brn,
    brasset: brbrn,
  },
  btcb: {
    id: 'btcb',
    symbol: 'BTCB',
    decimals: 18,
    address: TOKEN_ADDRESSES.btcb[CHAIN_ID],
    asset: btc,
    brasset: brbtc,
  },
  eth: {
    id: 'eth',
    symbol: 'ETH|eth',
    decimals: 18,
    address: TOKEN_ADDRESSES.eth[CHAIN_ID],
    asset: eth,
    brasset: breth,
  },
  ltc: {
    id: 'ltc',
    symbol: 'LTC',
    decimals: 18,
    address: TOKEN_ADDRESSES.ltc[CHAIN_ID],
    asset: ltc,
    brasset: brltc,
  },
  xrp: {
    id: 'xrp',
    symbol: 'XRP',
    decimals: 18,
    address: TOKEN_ADDRESSES.xrp[CHAIN_ID],
    asset: xrp,
    brasset: brxrp,
  },
  ada: {
    id: 'ada',
    symbol: 'ADA',
    decimals: 18,
    address: TOKEN_ADDRESSES.ada[CHAIN_ID],
    asset: ada,
    brasset: brada,
  },
  doge: {
    id: 'doge',
    symbol: 'DOGE',
    decimals: 8,
    address: TOKEN_ADDRESSES.doge[CHAIN_ID],
    asset: doge,
    brasset: brdoge,
  },
  matic: {
    id: 'matic',
    symbol: 'MATIC',
    decimals: 18,
    address: TOKEN_ADDRESSES.matic[CHAIN_ID],
    asset: matic,
    brasset: brmatic,
  },
  cake: {
    id: 'cake',
    symbol: 'CAKE',
    decimals: 18,
    address: TOKEN_ADDRESSES.cake[CHAIN_ID],
    asset: cake,
    brasset: brcake,
  },
  aave: {
    id: 'aave',
    symbol: 'AAVE',
    decimals: 18,
    address: TOKEN_ADDRESSES.aave[CHAIN_ID],
    asset: aave,
    brasset: braave,
  },
  tusd: {
    id: 'tusd',
    symbol: 'TUSD',
    decimals: 18,
    address: TOKEN_ADDRESSES.tusd[CHAIN_ID],
    asset: tusd,
    brasset: brtusd,
  },
  trx: {
    id: 'trx',
    symbol: 'TRX',
    decimals: 18,
    address: TOKEN_ADDRESSES.trx[CHAIN_ID],
    asset: trx,
    brasset: brtrx,
  },
  ust: {
    id: 'ust',
    symbol: 'UST',
    decimals: 18,
    address: TOKEN_ADDRESSES.ust[CHAIN_ID],
    asset: ust,
    brasset: brust,
  },
  luna: {
    id: 'luna',
    symbol: 'LUNA',
    decimals: 6,
    address: TOKEN_ADDRESSES.luna[CHAIN_ID],
    asset: luna,
    brasset: brluna,
  },
  bai: {
    id: 'bai',
    symbol: 'BAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.bai[CHAIN_ID],
    asset: bai,
  },
  vrt: {
    id: 'vrt',
    symbol: 'LP-TOKEN',
    decimals: 18,
    address: TOKEN_ADDRESSES.vrt[CHAIN_ID],
    asset: vrt,
  },
  pckb: {
    id: 'pckb',
    symbol: 'CKB',
    decimals: 18,
    address: TOKEN_ADDRESSES.pckb[CHAIN_ID],
    asset: ckb,
  },
};

export const BRERC_TOKEN_DECIMALS = 8;

export const BRERC_TOKENS = {
      pckb: {
        id: 'pckb',
        symbol: 'brCKB',
        address: BRERC_TOKEN_ADDRESSES.pckb[CHAIN_ID],
        decimals: BRERC_TOKEN_DECIMALS,
      },
      usdc: {
        id: 'usdc',
        symbol: 'brUSDC',
        address: BRERC_TOKEN_ADDRESSES.usdc[CHAIN_ID],
        decimals: BRERC_TOKEN_DECIMALS,
      },
      // usdt: {
      //   id: 'usdt',
      //   symbol: 'brUSDT',
      //   address: BRERC_TOKEN_ADDRESSES.usdt[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // ckdai: {
      //   id: 'ckdai',
      //   symbol: 'bDAI',
      //   address: BRERC_TOKEN_ADDRESSES.ckdai[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // bnb: {
      //   id: 'bnb',
      //   symbol: 'brBNB',
      //   address: BRERC_TOKEN_ADDRESSES.bnb[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      brn: {
        id: 'brn',
        symbol: 'brBRN',
        address: BRERC_TOKEN_ADDRESSES.brn[CHAIN_ID],
        decimals: BRERC_TOKEN_DECIMALS,
      },
      // btcb: {
      //   id: 'btcb',
      //   symbol: 'brBTC',
      //   address: BRERC_TOKEN_ADDRESSES.btcb[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      eth: {
        id: 'eth',
        symbol: 'brETH',
        address: BRERC_TOKEN_ADDRESSES.eth[CHAIN_ID],
        decimals: BRERC_TOKEN_DECIMALS,
      },
      // ltc: {
      //   id: 'ltc',
      //   symbol: 'vLTC',
      //   address: BRERC_TOKEN_ADDRESSES.ltc[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // xrp: {
      //   id: 'xrp',
      //   symbol: 'vXRP',
      //   address: BRERC_TOKEN_ADDRESSES.xrp[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // ada: {
      //   id: 'ada',
      //   symbol: 'vADA',
      //   address: BRERC_TOKEN_ADDRESSES.ada[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // doge: {
      //   id: 'doge',
      //   symbol: 'vDOGE',
      //   address: BRERC_TOKEN_ADDRESSES.doge[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // matic: {
      //   id: 'matic',
      //   symbol: 'vMATIC',
      //   address: BRERC_TOKEN_ADDRESSES.matic[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // cake: {
      //   id: 'cake',
      //   symbol: 'vCAKE',
      //   address: BRERC_TOKEN_ADDRESSES.cake[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // aave: {
      //   id: 'aave',
      //   symbol: 'vAAVE',
      //   address: BRERC_TOKEN_ADDRESSES.aave[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // tusd: {
      //   id: 'tusd',
      //   symbol: 'vTUSD',
      //   address: BRERC_TOKEN_ADDRESSES.tusd[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // trx: {
      //   id: 'trx',
      //   symbol: 'vTRX',
      //   address: BRERC_TOKEN_ADDRESSES.trx[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // ust: {
      //   id: 'ust',
      //   symbol: 'vUST',
      //   address: BRERC_TOKEN_ADDRESSES.ust[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
      // luna: {
      //   id: 'luna',
      //   symbol: 'vLUNA',
      //   address: BRERC_TOKEN_ADDRESSES.luna[CHAIN_ID],
      //   decimals: BRERC_TOKEN_DECIMALS,
      // },
    }

