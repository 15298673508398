import React from 'react'

import '../../assets/styles/homeComponents/AccessLiquidity.scss'

// importing images 
import Map from '../../assets/homeImages/world-map.png'

const AccessLiquidity = () => {
    return (
        <>
            <div className="access-liquidity">
                <div className="access-liquidity__container">
                    <div className="access-liquidity__image">
                        <img src={Map} alt="" />
                    </div>

                    <div className="access-liquidity__content">
                        <h3>Access Liquidity from Nervos on-demand on any asset from anywhere</h3>
                        <br />
                        <p>With the decentralized nature of Nervos, users and developers can borrow and lend through Brainiac Finance smart contracts</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AccessLiquidity