import React from 'react'

import '../../assets/styles/homeComponents/Hero.scss'

// importing images 
import Blob from '../../assets/homeImages/hero-blob.png'
import Godwoken from '../../assets/homeImages/godwoken-logo.jpg'
import Background from '../../assets/homeImages/hero-background.png'
import Path from 'constants/path'

const Hero = () => {
    const launchApp = ()=>{
        window.location = Path.DASHBOARD;
    }
    return (
        <>
            <div className="hero">
                <div className="hero__container">
                    <img className='hero__background' src={Background} alt="" />
                    <div className="hero__info">
                        <div className="hero__intro-head">
                            <h1>Smart Money Markets on Nervos</h1>
                            <h3>Earn interest by lending, borrowing and staking digital assets. Faster. Cheaper. Safer</h3>
                            {/* <button className='hero__launch-btn' onClick={launchApp}>Launch App</button> */}
                        </div>

                        <div className="hero__image">
                            <img src={Blob} alt="" />
                        </div>
                    </div>
                    <div className="hero__powered-by">
                        <div className="hero__bottom-para">
                            Join the world of permissionless DeFi by leveraging your assets in isolated markets on Brainiac Finance, where the rules are set by you and the community.

                        </div>
                        <div className="hero__powered-by-logo">
                            <img src={Godwoken} alt="logo" />

                        </div>

                        <div className="hero__powered-by-heading">
                            <h1>Powered by Godwoken on Nervos</h1>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Hero