import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {

    itemList: css`
      margin: 0;
      margin-top:${theme.spacing(10)}
      padding: 0;
    `,
    icon:css`
    width: ${theme.shape.iconSize.xxLarge}px;
    height: ${theme.shape.iconSize.xxLarge}px;
    `,
    item: css`
      list-style: none;
      padding: ${theme.spacing(1, 0)};
      :not(:last-of-type) {
      }
    `,
    value: css`
      font-weight: ${theme.typography.body2.fontWeight};
    `,
  };
};
