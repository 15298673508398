// Vesting vaults have a vesting time for users to withdraw their rewards after unstaking
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import BigNumber from 'bignumber.js';

import { getToken } from 'utilities';
import { Icon, IconName } from 'components';
import { formatCommaThousandsPeriodDecimal, format } from 'utilities/common';
import { TokenId } from 'types';
import VaultCardContent from './CardContent';
import { VaultCardWrapper } from '../styles';

interface VaultCardProps {
  poolId: BigNumber;
  brainVaultContract: any;
  stakedToken: TokenId;
  rewardToken: TokenId;
  userStakedAmount: BigNumber;
  pendingReward: BigNumber;
  lockPeriodSecond: BigNumber;
  apr: BigNumber;
  totalStaked: BigNumber;
  dailyEmission: BigNumber;
  penalty: BigNumber;
}
function formatTimeToLockPeriodString(seconds: $TSFixMe) {
  let remaining = 0;
  const weeks = Math.floor(seconds / (7 * 86400));
  remaining = seconds - (weeks * 7 * 86400);
  const days = Math.floor(remaining / 86400);
  remaining -=  days * 86400;
  const hours = Math.floor(remaining / 3600);
  remaining -= hours * 3600;
  const minutes = Math.floor(remaining / 60);
  remaining -= minutes * 60;
  const second = remaining;

  return `${weeks ? `${weeks} weeks ` : ''}${days ? `${days} days ` : ''}${hours ? `${hours} hours ` : ''}${
    minutes ? `${minutes} minutes ` : ''
  }${second ? `${second} seconds` : ''}`;
}

function VaultCard({
  poolId,
  brainVaultContract,
  stakedToken,
  rewardToken,
  userStakedAmount,
  pendingReward,
  lockPeriodSecond,
  apr,
  totalStaked,
  dailyEmission,
  penalty,
}: VaultCardProps) {
  const stakedTokenDecimal = new BigNumber(10).pow(getToken(stakedToken).decimals);
  const rewardTokenDecimal = new BigNumber(10).pow(getToken(rewardToken).decimals);
  const stakedTokenSymbol = getToken(stakedToken).symbol;
  const rewardTokenSymbol = getToken(rewardToken).symbol;
  const [expanded, setExpanded] = useState(false);
  return (
    <VaultCardWrapper>
      <div className={`header-container ${expanded ? '' : 'fold'}`}>
        <Row>
          <Col className="col-item" lg={{ span: 2 }} xs={{ span: 4 }}>
            {' '}
            <Icon size="70%" name={stakedToken.toLowerCase() as IconName} />
          </Col>
          <Col className="top-info" lg={{ span: 20 }} xs={{ span: 22 }}>
            {lockPeriodSecond.toNumber() === 10
              ? `You can stake ${stakedTokenSymbol} to receive rewards in BRN. Your staked
            ${stakedTokenSymbol} is unlocked to withdraw.`
              : `You can stake and lock ${stakedTokenSymbol} to receive rewards in BRN. Your
            ${stakedTokenSymbol} will be locked for ${formatTimeToLockPeriodString(
                  lockPeriodSecond,
                )}.
            After the lockup period, rewards still accrue normally, and you may unstake without
            penalty at any time.`}
          </Col>
        </Row>
      </div>
      <div className={`header-container ${expanded ? '' : 'fold'}`}>
        <Row className="header">
          <Col className="col-item" lg={{ span: 2 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">Stake</div>
            <div className="content">
              <Icon name={stakedToken.toLowerCase() as IconName} />
              <span>{stakedTokenSymbol}</span>
            </div>
          </Col>
          <Col className="col-item" lg={{ span: 2 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">Earn</div>
            <div className="content">
              <Icon name={rewardToken.toLowerCase() as IconName} />
              <span>BRN</span>
            </div>
          </Col>
          <Col className="col-item" lg={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">Available Rewards</div>
            <div className="content">
              {formatCommaThousandsPeriodDecimal(pendingReward.div(rewardTokenDecimal).toFixed(4))}
            </div>
          </Col>
          <Col className="col-item" lg={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">{stakedTokenSymbol} Staking APR</div>
            <div className="content">{format(apr.multipliedBy(100), 6)}%</div>
          </Col>
          <Col className="col-item" lg={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">Total {stakedTokenSymbol} Staked</div>
            <div className="content">{format(totalStaked.div(stakedTokenDecimal), 4)}</div>
          </Col>
          <Col className="col-item" lg={{ span: 4 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">BRN Daily Emission</div>
            <div className="content">{format(dailyEmission.div(rewardTokenDecimal), 4)} BRN</div>
          </Col>
          <Col className="col-item" lg={{ span: 3 }} md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="title">Lock</div>
            <div className="content"> {lockPeriodSecond.toString() === "10" ? "5 minutes" : `${formatTimeToLockPeriodString(lockPeriodSecond)}`}</div>
          </Col>
          <Col
            className="col-item expand-icon-wrapper"
            lg={{ span: 1 }}
            xs={{ span: 24 }}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon className="expand-icon" name="arrowDown" />
          </Col>
        </Row>
      </div>
      <div>
        {expanded && (
          <VaultCardContent
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; poolId: any; stakedToke... Remove this comment to see the full error message
            className="content-container"
            poolId={poolId}
            brainVaultContract={brainVaultContract}
            stakedToken={stakedToken}
            rewardToken={rewardToken}
            userStakedAmount={userStakedAmount}
            pendingReward={pendingReward}
            lockPeriodSecond={lockPeriodSecond}
          />
        )}
      </div>
    </VaultCardWrapper>
  );
}

export default VaultCard;
