import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    headerRoot: css`
      margin-bottom: ${theme.spacing(8)};
      padding: ${theme.spacing(4)} 0 ${theme.spacing(4)} ${theme.spacing(6)};

      ${theme.breakpoints.down('xxl')} {
        padding: 0;
        background-color: transparent;
        margin-bottom: ${theme.spacing(6)};
      }
    `,
    title: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};

      ${theme.breakpoints.down('xl')} {
        font-size: 0.75rem;
      }
    `,
    text: css`
    display: block;
    margin-bottom: ${theme.spacing(1)};
    font-size: 1.8rem;
    color: white !important;

    ${theme.breakpoints.down('xl')} {
      font-size: 0.9rem;
    }
  `,
  faqelement : css`
    margin : 2rem;
    padding : 1000px;
  `,
  };
};
