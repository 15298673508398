import { useQuery, QueryObserverOptions } from 'react-query';

import getBRTokenBalancesAll, {
  IGetBRTokenBalancesAllInput,
  IGetBRTokenBalancesAllOutput,
} from 'clients/api/queries/getBRTokenBalancesAll';
import { useBrainiacLensContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  IGetBRTokenBalancesAllOutput,
  Error,
  IGetBRTokenBalancesAllOutput,
  IGetBRTokenBalancesAllOutput,
  [FunctionKey.GET_V_TOKEN_BALANCES_ALL, string]
>;

const useGetBRTokenBalancesAll = (
  { account, brTokenAddresses }: Omit<IGetBRTokenBalancesAllInput, 'brainiacLensContract'>,
  options?: Options,
) => {
  const brainiacLensContract = useBrainiacLensContract();
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCES_ALL, account],
    () => getBRTokenBalancesAll({ brainiacLensContract, account, brTokenAddresses }),
    options,
  );
};

export default useGetBRTokenBalancesAll;
