// @ts-nocheck
import { TokenId } from 'types';
import { getContractAddress, getBRErcToken } from 'utilities';

const getTokenSpenderAddress = (tokenId: TokenId) => {
  if (tokenId === 'bai') {
    return getContractAddress('baiUnitroller');
  }

  if (tokenId === 'vrt') {
    return getContractAddress('vrtConverterProxy');
  }

  return getBRErcToken(tokenId).address;
};

export default getTokenSpenderAddress;
