import * as React from 'react';
import { SVGProps } from 'react';

const SvgCkb = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
  <g clipPath="url(#clip0_106_10)">
  <path d="M5 6V8.39895V10.7979V13.2021H7.51659V10.7979V8.39895V6H5Z" fill="#424242"/>
  <path d="M17.4834 10.7979V13.2021V15.601V18H20V15.601V13.2021V10.7979H17.4834Z" fill="#424242"/>
  <path d="M12.4447 6V8.39895V10.6977L14.9613 13.0967V10.7979V8.39895L12.4447 6Z" fill="#424242"/>
  <path d="M10.0387 10.9033V13.2021V15.601L12.5553 18V15.601V13.3023L10.0387 10.9033Z" fill="#424242"/>
  <path d="M12.4447 6H14.9613L20 10.7979H17.4834L12.4447 6Z" fill="black"/>
  <path d="M5 13.2021H7.51659L12.5553 18H10.0387L5 13.2021Z" fill="black"/>
  <path d="M14.9613 8.39895V10.7979V13.065L7.5166 6V8.39895V8.54657V10.7979V13.2021L10.0387 15.6011V13.2021V10.935L17.4834 18V15.6011V15.4534V13.2021V10.7979L14.9613 8.39895Z" fill="#3CC68A"/>
  </g>
  <defs>
  <clipPath id="clip0_106_10">
  <rect width="15" height="12" fill="white" transform="translate(5 6)"/>
  </clipPath>
  </defs>
  </svg>
);

export default SvgCkb;
