import { TOKENS } from 'constants/tokens';
import { checkForTokenTransactionError } from 'errors';
export type AssetTicker =
  | 'usdc'
  | 'usdt'
  | 'ckdai'
  | 'brn'
  | 'eth';

export type RequestFaucetFundsInput = {
  faucetContract : any;
  address: string;
  account: string;
  asset: AssetTicker;
};

export type RequestFaucetFundsOutput = void;

const requestFaucetFundsInput = async ({
  faucetContract,
  address,
  account,
  asset,
}: RequestFaucetFundsInput,
): Promise<RequestFaucetFundsOutput> => {

  const tokenAddress = Object.values(TOKENS).find(token => asset === token.id) ;
  const response = await faucetContract.methods.claimTestnetFaucet(tokenAddress.address,address).send({ from: account });
};

export default requestFaucetFundsInput;
