import BigNumber from 'bignumber.js';

import { BrnVault } from 'types/contracts';

export interface GetBrnVaultPendingRewardWeiInput {
  brnVaultContract: BrnVault;
  tokenAddress: string;
  poolIndex: number;
  accountAddress: string;
}

export type GetBrnVaultPendingRewardWeiOutput = BigNumber;

const getBrnVaultPendingRewardWei = async ({
  brnVaultContract,
  tokenAddress,
  poolIndex,
  accountAddress,
}: GetBrnVaultPendingRewardWeiInput): Promise<GetBrnVaultPendingRewardWeiOutput> => {
  const res = await brnVaultContract.methods
    .pendingReward(tokenAddress, poolIndex, accountAddress)
    .call();
  return new BigNumber(res);
};

export default getBrnVaultPendingRewardWei;
