import BigNumber from 'bignumber.js';

import { BrnVault } from 'types/contracts';
import { IGetBrnVaultUserInfoOutput } from './types';

const formatToUserInfo = ({
  amount,
  pendingWithdrawals,
  rewardDebt,
}: Awaited<
  ReturnType<ReturnType<BrnVault['methods']['getUserInfo']>['call']>
>): IGetBrnVaultUserInfoOutput => ({
  stakedAmountWei: new BigNumber(amount),
  pendingWithdrawalsTotalAmountWei: new BigNumber(pendingWithdrawals),
  rewardDebtAmountWei: new BigNumber(rewardDebt),
});

export default formatToUserInfo;
