/** @jsxImportSource @emotion/react */
import React from 'react';

import { useTranslation } from 'translation';
import { NFTContext } from 'context/NFTContext';
import { truncateAddress } from 'utilities/truncateAddress';
import { SecondaryButton, IButtonProps } from 'components';
import {ReactComponent as MetaMaskLogo} from "assets/img/v2/wallets/metaMaskLogo.svg"


export interface IMintNftButton extends IButtonProps {
  accountAddress?: string;
}

export const MintNftButtonUi: React.FC<IMintNftButton> = ({ accountAddress, ...otherProps }) => {
  const { t } = useTranslation();

  return (
    <SecondaryButton  {...otherProps}>
      Mint
    </SecondaryButton>
  );
};

export const MintNftButton: React.FC<IButtonProps> = props => {
  const { account, openNFTModal } = React.useContext(NFTContext);
  return (
    <MintNftButtonUi
      accountAddress={account}
      onClick={openNFTModal}
      variant='primary'
      {...props}
    />
  );
};

export default MintNftButton;
