import BigNumber from 'bignumber.js';
import { getContractAddress } from 'utilities';
import { Comptroller, BrainiacLens } from 'types/contracts';
import { BRERC_TOKENS } from 'constants/tokens';

export interface IGetBrnRewardInput {
  lensContract: BrainiacLens;
  comptrollerContract: Comptroller;
  accountAddress: string;
}

export type GetBrnRewardOutput = BigNumber;

const getBrnReward = async ({
  lensContract,
  comptrollerContract,
  accountAddress,
}: IGetBrnRewardInput): Promise<GetBrnRewardOutput> => {
  
  const brTokens = Object.values(BRERC_TOKENS)
    .filter(brToken => brToken.address !== '')
    .map(brToken => brToken.address);
 
  const chunkSize = 3;
  let pendingReward = new BigNumber(0);

  for (let i = 0; i < brTokens.length; i += chunkSize) {
    const chunk = brTokens.slice(i, i + chunkSize);
    const reward = await lensContract.methods
      .pendingBrainiac(accountAddress, getContractAddress('comptroller'), chunk)
      .call();

      pendingReward =  pendingReward.plus(reward);
   

  }

  const rewardAccrued = await comptrollerContract.methods.brainiacAccrued(accountAddress).call()
 
  const totalReward = new BigNumber(pendingReward).plus(rewardAccrued)

  return totalReward;
};

export default getBrnReward;
