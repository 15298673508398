import * as React from 'react';
import { SVGProps } from 'react';

const SvgBtcb = ({ id, ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.7 14.42c-1.335 5.356-6.762 8.617-12.12 7.28C4.224 20.366.963 14.94 2.3 9.583 3.635 4.224 9.06.964 14.418 2.299c5.358 1.336 8.618 6.763 7.283 12.12Z"
      fill={`url(#${id})`}
    />
    <path
      d="M16.4 10.587c.198-1.33-.815-2.046-2.2-2.523l.45-1.802-1.098-.274-.438 1.755a45.96 45.96 0 0 0-.879-.207l.44-1.766-1.096-.274-.45 1.802a36.607 36.607 0 0 1-.7-.165l.001-.005-1.513-.378-.292 1.172s.814.186.797.198c.444.11.524.405.511.638l-1.231 4.938c-.055.135-.193.337-.503.26.01.016-.798-.199-.798-.199l-.545 1.256 1.428.356c.266.067.526.136.783.202l-.454 1.823 1.096.274.45-1.804c.299.081.59.156.874.227l-.448 1.795 1.097.274.454-1.82c1.871.354 3.278.211 3.87-1.481.478-1.363-.023-2.149-1.008-2.661.717-.165 1.257-.637 1.401-1.61Zm-2.508 3.515c-.339 1.363-2.633.626-3.377.442l.602-2.416c.744.186 3.13.553 2.775 1.974Zm.34-3.535c-.31 1.24-2.22.61-2.839.456l.546-2.19c.62.153 2.615.442 2.293 1.734Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id={id} x1={2} y1={2} x2={2} y2={22} gradientUnits="userSpaceOnUse">
        <stop stopColor="#F9AA4B" />
        <stop offset={1} stopColor="#F7931A" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgBtcb;
