import React from 'react'

// importing  components 
import Navbar from '../Navbar/Navbar'
import Lend from 'homeComponents/Lend/Lend'
import Borrow from 'homeComponents/Borrow/Borrow'
import ListedAssets from 'homeComponents/ListedAssets/ListedAssets'
import Vote from 'homeComponents/Vote/Vote'
import Footer from 'homeComponents/Footer/Footer'
import Hero from 'homeComponents/Hero/Hero'
import AccessLiquidity from 'homeComponents/AccessLiquidity/AccessLiquidity'
const Home = () => {
    return (
        <>
            <Navbar />
            <Hero />
            <Lend />
            <Borrow />
            <ListedAssets />
            <Vote />
            <AccessLiquidity />
            <Footer />
        </>
    )
}

export default Home