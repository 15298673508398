import { useMemo } from 'react';
import { Vault } from 'types';
import useGetVestingVaults from './useGetVestingVaults';
import useGetBaiVault from './useGetBaiVault';

export interface UseGetVaultsOutput {
  isLoading: boolean;
  data: Vault[];
}

const useGetVaults = ({ accountAddress }: { accountAddress?: string }): UseGetVaultsOutput => {
  const { data: vestingVaults, isLoading: isGetVestingVaultsLoading } = useGetVestingVaults({
    accountAddress,
  });

  const { data: baiVault, isLoading: isBaiVaultLoading } = useGetBaiVault({
    accountAddress,
  });

  // TODO: fetch VRT vault

  const data: Vault[] = useMemo(() => {
    const allVaults = [...vestingVaults];

    if (baiVault) {
      allVaults.push(baiVault);
    }

    // TODO: add VRT vault

    return allVaults;
  }, [JSON.stringify(vestingVaults), baiVault]);

  const isLoading = isGetVestingVaultsLoading || isBaiVaultLoading;

  return {
    data,
    isLoading,
  };
};

export default useGetVaults;
