import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      flex: 1;
      ${theme.breakpoints.up('xl')} {
        margin-left: ${theme.spacing(8)};
      }
      ${theme.breakpoints.down('xl')} {
        display: block;
        margin-bottom: ${theme.spacing(8)};
      }
      padding-top: ${theme.spacing(4)};
    `,
    selectContainer: css`

      display: flex;
      justify-content: space-between;
      align-items; center;
      padding: 0;
    `,

    addTokenButtonLabel: css`
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    `,
    smallIcon: css`
      ${theme.breakpoints.up('sm')} {
        display: none;
      }
    `,
    addIconsContainer: css`
      display: flex;
      justify-contents: space-between;
      align-items: center;
      overflow: auto;
    `,
    tokenSelectionOption: css`
      display: flex;
      flex-direction: horizontal;
      justify-content: center;

      background-color: ${theme.palette.background.default};
    `,
    tokenSelectionIcon: css`
      width: ${theme.shape.iconSize.xLarge}px;
      height: ${theme.shape.iconSize.xLarge}px;
    `,
    addTokenButton: css`
      background-color: ${theme.palette.background.paper};
      margin: 0px ${theme.spacing(2)};
    `,
    addTokenSign: css`
      color: ${theme.palette.interactive.success};
    `,

    column: css`
      :not(:first-of-type) {
        margin-left: ${theme.spacing(4)};
      }

      :not(:last-of-type) {
        margin-right: ${theme.spacing(4)};
      }

      ${theme.breakpoints.down('xl')} {
        :not(:first-of-type) {
          margin-left: 0;
        }

        :not(:last-of-type) {
          margin-right: 0;
        }
      }
    `,
    statsColumn: css`
      width: 100%;
    `,
    legendColors: {
      supplyApy: theme.palette.interactive.success,
      borrowApy: theme.palette.interactive.error,
      utilizationRate: theme.palette.interactive.primary,
    },
    apyChart: css`
      margin-right: ${theme.spacing(-2.5)};
    `,
  };
};
