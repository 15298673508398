//@ts-nocheck

// https://github.com/BrainiacProtocol/brainiac-protocol-interface/commit/f829bf4850b1d000f294f8feb21e78dfaf7ff763

/** @jsxImportSource @emotion/react */
import { BigNumber } from 'bignumber.js';
import React from 'react';
import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'config';
import {
  calculateNetApy,
  calculateDailyEarningsCents,
  calculateYearlyEarningsForAssets,
} from 'utilities';
import { useDailyBrnWei } from 'hooks/useDailyBrnWei';
import { Asset } from 'types';
import MyAccountUiV2, { IMyAccountUiProps } from './MyAccountUiV2';

interface IMyAccountProps {
  className?: string;
  isBrnEnabled: boolean;
  setIsBrnEnabled: (value: boolean) => void;
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
}

const MyAccount: React.FC<IMyAccountProps> = ({
  className,
  assets,
  isBrnEnabled,
  setIsBrnEnabled,
  userTotalBorrowLimitCents,
  userTotalBorrowBalanceCents,
  userTotalSupplyBalanceCents,
}) => {

  const { dailyBrnDistributionInterestsCents } = useDailyBrnWei(); 
  const calculations: Pick<
  IMyAccountUiProps,
  'netApyPercentage' | 'dailyEarningsCents' | 'supplyBalanceCents' | 'borrowLimitCents'
> = React.useMemo(() => {
  const yearlyEarningsCents =
    dailyBrnDistributionInterestsCents &&
    calculateYearlyEarningsForAssets({
      assets,
      isBrnEnabled,
      dailyBrnDistributionInterestsCents,
    });

  const netApyPercentage =
    userTotalSupplyBalanceCents &&
    yearlyEarningsCents &&
    calculateNetApy({
      supplyBalanceCents: userTotalSupplyBalanceCents,
      yearlyEarningsCents,
    });


  const dailyEarningsCents =
    yearlyEarningsCents && +calculateDailyEarningsCents(yearlyEarningsCents).toFixed(0);
  return {
    netApyPercentage,
    dailyEarningsCents,
    supplyBalanceCents: userTotalSupplyBalanceCents?.toNumber(),
    borrowLimitCents: userTotalBorrowLimitCents.toNumber(),
  };
}, [JSON.stringify(assets), isBrnEnabled, dailyBrnDistributionInterestsCents]);


  return (
    <MyAccountUiV2
      className={className}
      safeBorrowLimitPercentage={SAFE_BORROW_LIMIT_PERCENTAGE}
      isBrnEnabled={isBrnEnabled}
      onBrnToggle={setIsBrnEnabled}
      borrowBalanceCents={+userTotalBorrowBalanceCents.toFixed()}
      {...calculations}
    />
  );
};

export default MyAccount;
