import React from 'react';
import { Paper, Grid, Stack, Typography, Box } from '@mui/material';
// import Image from 'assets/img/v2/nft.gif';
import { useStyles } from '../styles';
import ConnectButton from 'components/v2/Layout/ConnectButton';
import { PrimaryButton } from 'components';
import { MintNftButton } from '../MintNftButton';
import { AuthContext } from 'context/AuthContext';
import { NFTContext } from 'context/NFTContext';
import Share from './Share';
//@ts-ignore
import Image from './nft.gif';
import { saveAs } from 'file-saver';

export const NFTMintSection = () => {
  const styles = useStyles();
  const { account } = React.useContext(AuthContext);
  const { usersNFT } = React.useContext(NFTContext);
  const baseUrl = 'https://brainiac.infura-ipfs.io/ipfs/';
  const downloadImage = () => {
    saveAs(`${baseUrl}${usersNFT[0].tokenURI}`, 'brainiacNFT.jpg') // Put your image url here.
  }
  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item>
        {usersNFT.length > 0 ? (
          <Typography variant="h5" component="h5" sx={{ color: '#03c04a', fontSize: '1.5rem' }}>
            Congratulations for being an early supporter !
          </Typography>
        ) : null }
      </Grid>
       <Grid item  xs={12}>
       {usersNFT.length > 0 ? (
          usersNFT.map((nft) => (
            <Typography variant="h1" component="h1" sx={{ color: 'white', fontSize: '2.5rem' , textAlign :'center'}}>
            #{nft.tokenID}
            </Typography>
          ))
        ) : null}
      </Grid>
      <Grid
        item
        m="auto"
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}
      >
        {/* <Box
          component="img"
          sx={{
            maxHeight: { xs: 400, md: 400 },
            maxWidth: { xs: 400, md: 400 },
            margin: 'auto',
            padding : 'auto'
          }}
          m="auto"
          alt="nft"
          // src={Image}
          css={styles.cards}
          src="https://cloudflare-ipfs.com/ipfs/QmWqXVTSFV8SzzYFft319J6keupMEsSHqTstHUMr1v1RYW"
        ></Box> */}
        {usersNFT.length > 0 ? (
          usersNFT.map(nft => (
            <img
              key={nft.tokenID}
              src={baseUrl + nft.tokenURI}
              alt="brainiac NFT #"
              style={{ maxHeight: 400, maxWidth: 400 }}
            />
          ))
        ) : (
          // <img src={Image} alt="brainiac NFT" style={{ maxHeight: 400, maxWidth: 400 }} />
          <img src={Image} alt="brainiac NFT" style={{ maxHeight: 400, maxWidth: 400 }} />
        )}
      </Grid>
      <Grid item>
        {usersNFT.length > 0 ? (
          <Typography variant="h5" component="h5" sx={{ color: '#03c04a', fontSize: '2rem', fontWeight : '600' }}>
            NFT MINTED!
          </Typography>
        ) : null }
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>{account ? usersNFT.length == 0 ? <MintNftButton /> : null : <ConnectButton />}</Grid>
      <Grid item>
      {usersNFT.length > 0 ? (
          <Share/>
        ) : null }
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
      {usersNFT.length > 0 ? (
         <PrimaryButton variant='primary' onClick={downloadImage} >Download</PrimaryButton>
        ) : null }
      </Grid>
    </Grid>
  );
};
