/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Typography } from '@mui/material';
import {
  useGetUserMarketInfo,
  useGetBrainiacBaiVaultDailyRateWei,
  useGetBalanceOf,
} from 'clients/api';
import { DAYS_PER_YEAR } from 'constants/daysPerYear';
import { Token, Table, TableProps } from 'components';
import { AuthContext } from 'context/AuthContext';
import { useTranslation } from 'translation';
import { Asset } from 'types';
import { getContractAddress } from 'utilities';
import {
  formatToReadablePercentage,
  formatTokensToReadableValue,
  convertWeiToTokens,
} from 'utilities/common';
import { useStyles } from '../styles';

type TableAsset = Pick<Asset, 'id' | 'symbol'> & {
  brnPerDay: Asset['brnPerDay'] | undefined;
  brnSupplyApy: Asset['brnSupplyApy'] | undefined;
  brnBorrowApy: Asset['brnBorrowApy'] | undefined;
};

interface IBrnTableProps {
  assets: TableAsset[];
}

const BrnTableUi: React.FC<IBrnTableProps> = ({ assets }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const columns = useMemo(
    () => [
      { key: 'asset', label: t('brn.columns.asset'), orderable: false, align: 'left' },
      { key: 'brnPerDay', label: t('brn.columns.brnPerDay'), orderable: true, align: 'right' },
      {
        key: 'supplyBrnApy',
        label: t('brn.columns.supplyBrnApy'),
        orderable: true,
        align: 'right',
      },
      {
        key: 'borrowBrnApy',
        label: t('brn.columns.borrowBrnApy'),
        orderable: true,
        align: 'right',
      },
    ],
    [],
  );

  // Format assets to rows
  const rows: TableProps['data'] = assets.map(asset => [
    {
      key: 'asset',
      render: () => <Token tokenId={asset.id} />,
      value: asset.id,
      align: 'left',
    },
    {
      key: 'brnPerDay',
      render: () => (
        <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
          {formatTokensToReadableValue({
            value: asset.brnPerDay,
            tokenId: 'brn',
            minimizeDecimals: true,
          })}
        </Typography>
      ),
      value: asset.brnPerDay?.toFixed() || 0,
      align: 'right',
    },
    {
      key: 'supplyBrnApy',
      render: () => (
        <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
          {formatToReadablePercentage(asset.brnSupplyApy)}
        </Typography>
      ),
      value: asset.brnSupplyApy?.toFixed() || 0,
      align: 'right',
    },
    {
      key: 'borrowBrnApy',
      render: () => (
        <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
          {formatToReadablePercentage(asset.brnBorrowApy)}
        </Typography>
      ),
      value: asset.brnBorrowApy?.toFixed() || 0,
      align: 'right',
    },
  ]);

  return (
    <Table
      columns={columns}
      data={rows}
      initialOrder={{
        orderBy: 'brnPerDay',
        orderDirection: 'desc',
      }}
      rowKeyIndex={0}
      tableCss={styles.table}
      cardsCss={styles.cards}
      css={styles.cardContentGrid}
    />
  );
};

const BrnTable: React.FC = () => {
  const { account } = useContext(AuthContext);
  // TODO: handle loading state (see https://app.clickup.com/t/2d4rcee)
  const {
    data: { assets },
  } = useGetUserMarketInfo({
    accountAddress: account?.address,
  });

  const { data: brainiacBaiVaultDailyRateWei } = useGetBrainiacBaiVaultDailyRateWei();

  // const { data: vaultBaiStakedWei } = useGetBalanceOf({
  //   tokenId: 'bai',
  //   accountAddress: getContractAddress('baiVault'),
  // });
  const vaultBaiStakedWei = new BigNumber(0);

  const assetsWithBai = useMemo(() => {
    const allAssets: TableAsset[] = [...assets];
    const brnAsset = assets.find(asset => asset.id === 'brn');

    if (brainiacBaiVaultDailyRateWei && vaultBaiStakedWei && brnAsset) {
      const brainiacBaiVaultDailyRateTokens = convertWeiToTokens({
        valueWei: brainiacBaiVaultDailyRateWei,
        tokenId: 'brn',
      });

      const vaultBaiStakedTokens = convertWeiToTokens({
        valueWei: vaultBaiStakedWei,
        tokenId: 'vrt',
      });

      // const baiApy = brainiacBaiVaultDailyRateTokens
      //   .times(brnAsset.tokenPrice)
      //   .times(DAYS_PER_YEAR)
      //   .times(100)
      //   .div(vaultBaiStakedTokens);

      // allAssets.unshift({
      //   id: 'vrt',
      //   symbol: 'token',
      //   brnPerDay: brainiacBaiVaultDailyRateTokens,
      //   brnSupplyApy: baiApy,
      //   brnBorrowApy: undefined,
      // });
    }

    return allAssets;
  }, [JSON.stringify(assets), brainiacBaiVaultDailyRateWei?.toFixed(), vaultBaiStakedWei?.toFixed()]);

  const assetWithoutBrn =  assetsWithBai.filter((obj) => obj.id !== 'brn');

  return <BrnTableUi assets={assetWithoutBrn} />;
};

export default BrnTable;
