import { useQuery, QueryObserverOptions } from 'react-query';

import getBRTokenBalanceOf, {
  GetBRTokenBalanceOfOutput,
  IGetBRTokenBalanceOfInput,
} from 'clients/api/queries/getBRTokenBalanceOf';
import { useBRTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { BRTokenId } from 'types';

type Options = QueryObserverOptions<
  GetBRTokenBalanceOfOutput,
  Error,
  GetBRTokenBalanceOfOutput,
  GetBRTokenBalanceOfOutput,
  [FunctionKey.GET_V_TOKEN_BALANCE, string, BRTokenId]
>;

const useGetBRTokenBalanceOf = (
  { account, brTokenId }: Omit<IGetBRTokenBalanceOfInput, 'tokenContract'> & { brTokenId: BRTokenId },
  options?: Options,
) => {
  const tokenContract = useBRTokenContract(brTokenId as BRTokenId);
  return useQuery(
    [FunctionKey.GET_V_TOKEN_BALANCE, account, brTokenId],
    () => getBRTokenBalanceOf({ tokenContract, account }),
    options,
  );
};

export default useGetBRTokenBalanceOf;
