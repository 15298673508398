// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { connectAccount } from 'core';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { useWeb3 } from 'clients/web3';
import useRefresh from 'hooks/useRefresh';
import { useBrainVaultContract, useBRNCKBPoolContract } from 'clients/contracts/hooks';
import { getToken } from 'utilities';
import { TOKENS } from 'constants/tokens';
import GeneralVaultPoolCard from 'components/Vault/VestingVault/Card';
import BaiPoolCard from 'components/Vault/BasicVault/BaiCard';
import VrtPoolCard from 'components/Vault/BasicVault/VrtCard';
import { getTokenContractByAddress } from 'clients/contracts/getters';
import { IPool, TokenId } from 'types';
import { State } from 'core/modules/initialState';
import { AuthContext } from 'context/AuthContext';
import { Row, Col } from 'antd';
import getStakedTokenPrice from './getStakedTokenPrice';

const VaultWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 24px;
`;


function Vault() {
  const isMountedRef = React.useRef(false);
  const [poolInfos, setPoolInfos] = useState<IPool[]>([]);
  const [loading, setLoading] = useState(true);
  const { account } = useContext(AuthContext);
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();
  const brainVaultContract = useBrainVaultContract('0x8a01b508d8bF08eE5583743C9E1C8Ec45C22E303');
  const brainVaultContract2 = useBrainVaultContract('0x4bdEb91c2DA38F60bc03aB469B095300656FeAa1');
  const brainCkbLPVaultContract = useBrainVaultContract(
    '0x355880E6b49931A7B8B950B3dc032eF19670B780',
  );
  const brainCkbLPVaultContract3 = useBrainVaultContract(
    '0x1781c95EB104238DA6dfC66E2005b3Afc36BcFf9',
  );
  const brainCkbLPVaultContract2 = useBrainVaultContract(
    '0x38254D980745027d0dB39f06f83c40BE37F03404',
  );
  const liquidityPool = useBRNCKBPoolContract('0xaa0f41e50dbfd8247fb397b1fffea1fea9f4e6d4');

  const brainVaults  = [
    {
      id : "1",
      contract : brainCkbLPVaultContract,
      address : '0x355880E6b49931A7B8B950B3dc032eF19670B780',
      stakedToken : 'brainckblp',
      rewardToken : 'brn',
      isLP : true,
    },
    {
      id : "2",
      contract : brainCkbLPVaultContract2,
      address :  '0x38254D980745027d0dB39f06f83c40BE37F03404',
      stakedToken : 'brainckblp',
      rewardToken : 'brn',
      isLP : true,
    },
    {
      id : "3",
      contract : brainCkbLPVaultContract3,
      address :  '0x1781c95EB104238DA6dfC66E2005b3Afc36BcFf9',
      stakedToken : 'brainckblp',
      rewardToken : 'brn',
      isLP : true,
    },
    {
      id : "4",
      contract : brainVaultContract2,
      address :  '0x4bdEb91c2DA38F60bc03aB469B095300656FeAa1',
      stakedToken : 'brn',
      rewardToken : 'brn',
      isLP : false,
    },
    {
      id : "5",
      contract : brainVaultContract,
      address :  '0x8a01b508d8bF08eE5583743C9E1C8Ec45C22E303',
      stakedToken : 'brn',
      rewardToken : 'brn',
      isLP : false,
    },
  ];

  // fetch BRN vault pools info
  useEffect(() => {
    isMountedRef.current = true;

    const fetchPools = async () => {
      const patchedPoolInfos = [];

      const [ brnPrice , lpTokenPrice  ] = await getStakedTokenPrice(liquidityPool);
      // console.log(brnPrice.toString())
      // console.log(lpTokenPrice.toString())

      if (account) {
        // Brain Vault //

            for(let brainVault of brainVaults ){

              const vaultContract =  brainVault.contract;
              const stakedTokenAmount = await vaultContract.methods.getLockedBalance(account?.address).call();
              const pendingReward = await vaultContract.methods.getClaimableRewards(account?.address, 0).call();
              const rewardSpeed = await vaultContract.methods.rewardSpeeds(0).call();
              const totalSupply = await vaultContract.methods.totalSupplies().call();
              const lockPeriod = await vaultContract.methods.lockDuration().call();
              const secondsPerDay = 86400;
              const dailyEmission = new BigNumber(rewardSpeed).multipliedBy(secondsPerDay);
              const penalty = await vaultContract.methods.earlyRedeemPenaltyMantissa().call();
              const denom = new BigNumber(dailyEmission).multipliedBy(brnPrice);
              const num = new BigNumber(totalSupply).multipliedBy(brainVault.isLP ? lpTokenPrice : brnPrice)
              const apr = new BigNumber(denom).div(num).multipliedBy(365);

                  const poolx = {
                    poolId: new BigNumber(brainVault.id + "23"),
                    brainVaultContract: vaultContract,
                    stakedToken: brainVault.stakedToken,
                    rewardToken: brainVault.rewardToken,
                    pendingReward: new BigNumber(pendingReward),
                    userStakedAmount: new BigNumber(stakedTokenAmount),
                    lockPeriodSecond: new BigNumber(lockPeriod).minus(0),
                    apr: apr,
                    totalStaked: new BigNumber(totalSupply),
                    dailyEmission: new BigNumber(dailyEmission),
                    penalty: new BigNumber(penalty).div(1e16),
                  };

                  patchedPoolInfos.push(poolx);
          }

      } else {

          for(let brainVault of brainVaults ){

            const vaultContract =  brainVault.contract;
              const rewardSpeed = await vaultContract.methods.rewardSpeeds(0).call();
              const totalSupply = await vaultContract.methods.totalSupplies().call();
              const lockPeriod = await vaultContract.methods.lockDuration().call();
              const secondsPerDay = 86400;
              const dailyEmission = new BigNumber(rewardSpeed).multipliedBy(secondsPerDay);
              const penalty = await vaultContract.methods.earlyRedeemPenaltyMantissa().call();
              const denom = new BigNumber(dailyEmission).multipliedBy(brnPrice);
              const num = new BigNumber(totalSupply).multipliedBy(brainVault.isLP ? lpTokenPrice : brnPrice)
              const apr = new BigNumber(denom).div(num).multipliedBy(365);

              const pool = {
                  poolId: new BigNumber(brainVault.id),
                  brainVaultContract: vaultContract,
                  stakedToken: brainVault.stakedToken,
                  rewardToken: brainVault.rewardToken,
                  pendingReward: new BigNumber(0),
                  userStakedAmount: new BigNumber(0),
                  lockPeriodSecond: new BigNumber(lockPeriod).minus(0),
                  apr: apr,
                  totalStaked: new BigNumber(totalSupply),
                  dailyEmission: new BigNumber(dailyEmission),
                  penalty: new BigNumber(penalty).div(1e16),
                };

            patchedPoolInfos.push(pool);

          }
      }

      setLoading(false);

      if (isMountedRef.current) {
        setPoolInfos(patchedPoolInfos);
      }
    };

    fetchPools();

    return () => {
      isMountedRef.current = false;
    };
  }, [fastRefresh, account]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <VaultWrapper>
          {/* <BaiPoolCard /> */}
          {poolInfos.map(poolInfo => (
            <GeneralVaultPoolCard
              key={poolInfo.poolId.toString()}
              poolId={poolInfo.poolId}
              brainVaultContract={poolInfo.brainVaultContract}
              stakedToken={poolInfo.stakedToken}
              rewardToken={poolInfo.rewardToken}
              userStakedAmount={poolInfo.userStakedAmount}
              pendingReward={poolInfo.pendingReward}
              lockPeriodSecond={poolInfo.lockPeriodSecond}
              apr={poolInfo.apr}
              totalStaked={poolInfo.totalStaked}
              dailyEmission={poolInfo.dailyEmission}
              penalty={poolInfo.penalty}
            />
          ))}
           <Row>
             <Col className="bottom-info" lg={{ span: 20 }} xs={{ span: 22 }}>
             NOTE - Unlocking early has 40% penalty which is permanently burned for reducing BRN supply.
             </Col>
           </Row>
        </VaultWrapper>
      )}
    </>
  );
}

const mapStateToProps = ({ account }: State) => ({
  settings: account.setting,
});

export default connectAccount(mapStateToProps)(withRouter(Vault));
