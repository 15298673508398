import * as React from 'react';
import { SVGProps } from 'react';

const SvgDai = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2c5.523 0 10 4.478 10 10 0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10C2 6.478 6.478 2 12 2Z"
      fill="#F5AC37"
    />
    <path
      d="M12.369 12.706h3.8c.08 0 .119 0 .125-.106a7.275 7.275 0 0 0 0-1.162c0-.076-.038-.107-.12-.107H8.613c-.093 0-.118.031-.118.12v1.111c0 .144 0 .144.15.144h3.725Zm3.5-2.675a.122.122 0 0 0 0-.087 2.468 2.468 0 0 0-.226-.394 3.188 3.188 0 0 0-.462-.581c-.083-.106-.18-.201-.288-.282a4.493 4.493 0 0 0-1.875-.95 4.66 4.66 0 0 0-1.062-.112H8.599c-.093 0-.106.037-.106.119v2.218c0 .094 0 .12.119.12h7.212s.063-.014.075-.05h-.03Zm0 3.975a1.459 1.459 0 0 0-.32 0H8.62c-.094 0-.125 0-.125.125v2.17c0 .1 0 .124.125.124h3.2a1.6 1.6 0 0 0 .456-.03 4.762 4.762 0 0 0 1.356-.3c.159-.056.312-.127.456-.213h.044a4.06 4.06 0 0 0 1.744-1.756s.043-.094-.006-.119ZM7.238 17.55v-3.457c0-.08 0-.093-.1-.093H5.78c-.075 0-.106 0-.106-.1v-1.187h1.45c.081 0 .113 0 .113-.106v-1.175c0-.075 0-.094-.1-.094H5.78c-.075 0-.106 0-.106-.1v-1.1c0-.069 0-.087.1-.087H7.12c.094 0 .119 0 .119-.119V6.563c0-.1 0-.125.125-.125h4.687c.34.014.678.051 1.013.113.688.127 1.35.373 1.956.725.401.236.77.521 1.1.85.247.257.47.535.668.831.197.3.36.621.489.956.015.088.099.146.186.132h1.119c.143 0 .143 0 .15.137v1.025c0 .1-.038.126-.138.126h-.863c-.087 0-.112 0-.106.112.035.38.035.763 0 1.144 0 .106 0 .118.12.118h.986c.044.057 0 .113 0 .17.007.072.007.145 0 .218v.756c0 .106-.03.138-.125.138h-1.181a.156.156 0 0 0-.181.119 4.999 4.999 0 0 1-1.313 1.912 7.51 7.51 0 0 1-.669.538c-.25.144-.493.294-.75.412a6.753 6.753 0 0 1-1.475.469 7.686 7.686 0 0 1-1.463.119H7.236v-.007l.002-.001Z"
      fill="#FEFEFD"
    />
  </svg>
);

export default SvgDai;
