import React, { useContext } from 'react';
import { Input, Form, Dropdown, Menu } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ClickParam } from 'antd/lib/menu';

import { PrimaryButton } from 'components';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { Row, Column } from 'components/Basic/Style';
import { RequestFaucetFundsInput } from 'clients/api';
import { AssetTicker } from 'clients/api/mutations/requestFaucetFunds';
import { generateBscScanUrl } from 'utilities';
import * as Styles from './styles';
import { useFaucetContract } from 'clients/contracts/hooks';
import { AuthContext } from 'context/AuthContext';
export interface IFaucetProps extends FormComponentProps {
  isRequestFaucetFundsLoading: boolean;
  requestFaucetFunds: (params: RequestFaucetFundsInput) => void;
}

const Faucet: React.FC<IFaucetProps> = ({
  requestFaucetFunds,
  isRequestFaucetFundsLoading,
  form,
}) => {
  const { getFieldDecorator } = form;
  const faucetContract = useFaucetContract();
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);
  const handleMenuClick = (symbol: AssetTicker) => {
    form.validateFields((err: $TSFixMe, values: $TSFixMe) => {
      if (!err) {
        requestFaucetFunds({
          faucetContract: faucetContract,
          address: values.address,
          account: accountAddress,
          asset: symbol,
        });
      }
    });
  };

  return (
    <div className="flex just-center align-center">
      <Styles.FaucetWrapper className="flex flex-column align-center just-center">
        <p className="header">Brainiac NERVOS Chain Faucet</p>
        <Form className="forgot-pwd-form">
          <Form.Item>
            {getFieldDecorator('address', {
              rules: [
                {
                  required: true,
                  message: 'Address is required!',
                },
              ],
            })(<Input placeholder="Input your GOWOKEN L2 address..." />)}
          </Form.Item>
          {isRequestFaucetFundsLoading ? (
            <div className="flex flex-column">
              <LoadingSpinner size={60} />
            </div>
          ) : (
            <>
              <Row>
                <Column xs="6" sm="6">
                  <Styles.ButtonWrapper>
                    <PrimaryButton onClick={() => handleMenuClick('brn')} className="button">
                      Give Me BRN
                    </PrimaryButton>
                  </Styles.ButtonWrapper>
                </Column>
                <Column xs="6" sm="6">
                  <Styles.ButtonWrapper>
                    <PrimaryButton onClick={() => handleMenuClick('ckdai')} className="button">
                      Give Me DAI
                    </PrimaryButton>
                  </Styles.ButtonWrapper>
                </Column>
                <Column xs="6" sm="6">
                  <Styles.ButtonWrapper>
                    <PrimaryButton onClick={() => handleMenuClick('usdt')} className="button">
                      Give Me USDT
                    </PrimaryButton>
                  </Styles.ButtonWrapper>
                </Column>
                <Column xs="6" sm="6">
                  <Styles.ButtonWrapper>
                    <PrimaryButton onClick={() => handleMenuClick('usdc')} className="button">
                      Give Me USDC
                    </PrimaryButton>
                  </Styles.ButtonWrapper>
                </Column>
              </Row>
            </>
          )}
        </Form>
        <div className="flex flex-column align-center just-center bottom">
          <p className="title">How does this work?</p>
          <p className="description">
            <a href={generateBscScanUrl('brn', 'token')} target="_blank" rel="noreferrer">
              BRN
            </a>
            {', '}
            <a href={generateBscScanUrl('usdc')} target="_blank" rel="noreferrer">
              USDC
            </a>
            {', '}
            <a href={generateBscScanUrl('usdt', 'token')} target="_blank" rel="noreferrer">
              USDT
            </a>
            {' are issued as ERC20 token.'}
          </p>
          <p className="description">
            Click to get detail about{' '}
            <a
              href="https://github.com/binance-chain/BEPs/blob/master/ERC20.md"
              target="_blank"
              rel="noreferrer"
            >
              ERC20
            </a>
          </p>
        </div>
      </Styles.FaucetWrapper>
    </div>
  );
};

export default Form.create<IFaucetProps>({ name: 'faucet-form' })(Faucet);
