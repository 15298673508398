import { VrtToken, BrnToken, Erc20, BaiToken } from 'types/contracts';
import BigNumber from 'bignumber.js';
export interface IGetAllowanceInput {
  tokenContract: VrtToken | BrnToken | Erc20 | BaiToken;
  accountAddress: string;
  spenderAddress: string;
}

export type GetAllowanceOutput = BigNumber;

const getBrainiacBaiState = async ({
  tokenContract,
  accountAddress,
  spenderAddress,
}: IGetAllowanceInput): Promise<GetAllowanceOutput> =>{
  const res = await tokenContract.methods.allowance(accountAddress, spenderAddress).call();
  return new BigNumber(res);

}
  

export default getBrainiacBaiState;
