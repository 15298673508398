import BigNumber from 'bignumber.js';
import { BaiVault } from 'types/contracts';

export interface IGetBaiVaultPendingBrnWeiInput {
  baiVaultContract: BaiVault;
  accountAddress: string;
}

export type GetBaiVaultPendingBrnWeiOutput = BigNumber;

const getBaiVaultPendingBrnWei = async ({
  baiVaultContract,
  accountAddress,
}: IGetBaiVaultPendingBrnWeiInput): Promise<GetBaiVaultPendingBrnWeiOutput> => {
  const res = await baiVaultContract.methods.pendingBRN(accountAddress).call();

  return new BigNumber(res);
};

export default getBaiVaultPendingBrnWei;
