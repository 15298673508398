import { useQuery, QueryObserverOptions } from 'react-query';
import { useComptrollerContract, useBrainiacLensContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import getBrnReward, { GetBrnRewardOutput } from './getBrnReward';

type Options = QueryObserverOptions<
  GetBrnRewardOutput,
  Error,
  GetBrnRewardOutput,
  GetBrnRewardOutput,
  [FunctionKey.GET_BRN_REWARD, string]
>;

const useGetBrnReward = (accountAddress: string | undefined, options?: Options) => {
  const lensContract = useBrainiacLensContract();
  const comptrollerContract = useComptrollerContract();

  return useQuery(
    [FunctionKey.GET_BRN_REWARD, accountAddress],
    () =>
      getBrnReward({
        lensContract,
        comptrollerContract,
        accountAddress: accountAddress || '',
      }),
    {
      enabled:
        (options?.enabled === undefined || options?.enabled) &&
        // Check user have connected their wallet
        accountAddress !== undefined,
    },
  );
};

export default useGetBrnReward;
