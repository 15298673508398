import BigNumber from 'bignumber.js';

import { Comptroller } from 'types/contracts';

export interface IGetHypotheticalAccountLiquidityInput {
  comptrollerContract: Comptroller;
  accountAddress: string;
  brTokenAddress: string;
  brTokenBalanceOfWei: BigNumber;
  brTokenBorrowAmountWei?: BigNumber;
}

export type GetHypotheticalAccountLiquidityOutput = { 0: string; 1: string; 2: string };

const getHypotheticalAccountLiquidity = ({
  comptrollerContract,
  accountAddress,
  brTokenAddress,
  brTokenBalanceOfWei,
  brTokenBorrowAmountWei = new BigNumber(0),
}: IGetHypotheticalAccountLiquidityInput): Promise<GetHypotheticalAccountLiquidityOutput> =>
  comptrollerContract.methods
    .getHypotheticalAccountLiquidity(
      accountAddress.toLowerCase(),
      brTokenAddress,
      brTokenBalanceOfWei.toFixed(),
      brTokenBorrowAmountWei.toFixed(),
    )
    .call();

export default getHypotheticalAccountLiquidity;
