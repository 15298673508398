import React from 'react';
import noop from 'noop-ts';
import copyToClipboard from 'copy-to-clipboard';
import { getUsersNFT, useGetMintsAvailable } from 'clients/api';
import { useState, useEffect, useContext } from 'react';
import { useAuth } from 'clients/web3';
import { toast } from 'components/v2/Toast';
import { MintNFTModal } from 'components';
import { useBrainiacNFTContract } from 'clients/contracts';
import useRefresh from 'hooks/useRefresh';
import { INFT } from 'clients/api';

export interface IAccount {
  address: string;
}

export interface INFTContextValue {
  openNFTModal: () => void;
  closeNFTModal: () => void;
  account?: string;
  usersNFT?: INFT[];
  mintsAvailable: string;
}

export const NFTContext = React.createContext<INFTContextValue>({
  openNFTModal: noop,
  closeNFTModal: noop,
  usersNFT: [] as $TSFixMe[],
  mintsAvailable: '0',
});

export const NFTContextProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [isNFTModalOpen, setIsNFTModalOpen] = React.useState(false);
  const [mintsAvailable, setMintsAvailable] = React.useState('0');
  const [nftImageURL, setNftImageURL] = React.useState('');
  const [usersNFT, setUsersNFT] = React.useState([]);
  const { fastRefresh } = useRefresh();
  const { accountAddress } = useAuth();
  const brainiacNFTContract = useBrainiacNFTContract();

  const openNFTModal = () => setIsNFTModalOpen(true);
  const closeNFTModal = () => setIsNFTModalOpen(false);

  const account = accountAddress ? accountAddress : undefined;

  useEffect(() => {
    let isMounted = true;
    const update = async () => {
      if (!account) {
        return;
      }
      const mintsAvailable = await brainiacNFTContract.methods.mintsAvailable(account).call();
      const usersNFT = await getUsersNFT({ brainiacNFTContract, accountAddress });
      const last = usersNFT.length - 1;
      const tokenURI = usersNFT[last]?.tokenURI;
      const tokenID = usersNFT[last]?.tokenID;
      if (tokenURI) {
        try {
          const token = await fetch(`https://brainiac.infura-ipfs.io/ipfs/${tokenURI}`);
          const data = await token.json();
          setNftImageURL(data.image.slice(7));
        } catch (err) {
          console.log(err);
        }
      }

      if (!isMounted) {
        return;
      }
      const myNFTs = [];
      setMintsAvailable(mintsAvailable);
      if (nftImageURL !== '' && tokenID !== 0) {
        const myToken = {
          tokenID: tokenID,
          tokenURI: nftImageURL,
        };
        myNFTs.push(myToken);
        setUsersNFT(myNFTs);
      }
    };
    update();

    return () => {
      isMounted = false;
    };
  }, [fastRefresh, brainiacNFTContract, account]);

  return (
    <NFTContext.Provider
      value={{
        account,
        openNFTModal,
        closeNFTModal,
        usersNFT,
        mintsAvailable,
      }}
    >
      <MintNFTModal
        isOpen={isNFTModalOpen}
        onClose={closeNFTModal}
        account={account}
        mintsAvailable={mintsAvailable}
      />

      {children}
    </NFTContext.Provider>
  );
};
